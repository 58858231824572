import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 3px 21px rgba(0, 0, 0, 0.12)",
    borderRadius: "19px",
    opacity: 1,
  },
  maincontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

function AuthLayout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.maincontainer}>
      <div className={classes.container}>{children}</div>
    </div>
  );
}

export default AuthLayout;
