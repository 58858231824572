import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

export const fetchEvseOwnersAPI = async (params) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.get('/evse-owner', {params});
      return response.data;
    } catch (error) {
      console.error('Error fetching evse stations:', error);
      throw error;
    }
  };

export const addEvseOwnersAPI = async (payload) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.post('/user/register-user', payload);
      return response.data;
    } catch (error) {
      console.error('Error adding evse stations:', error);
      throw error;
    }
  };

  export const editEvseOwnersAPI = async (selectedOwnerId, queryParams) => {
    ensureAuthToken();
    console.log(selectedOwnerId, queryParams);
    try {
    const response = await axiosInstance.patch(`/user/${selectedOwnerId}`, queryParams);
      return response.data;
    } catch (error) {
      console.error('Error adding evse stations:', error);
      throw error;
    }
  };

  export const deleteEvseOwnersAPI = async (selectedOwnerId) => {
    ensureAuthToken();
    console.log(selectedOwnerId);
    try {
    const response = await axiosInstance.delete(`/user/${selectedOwnerId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting evse stations:', error);
      throw error;
    }
  };

