import React, { useState, useEffect } from "react";
import {
  AppBar, Toolbar, Dialog, DialogTitle, DialogContent,
  Badge,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  Divider,
  Button,
  CircularProgress
} from "@mui/material";
import { Margin, Menu } from "@mui/icons-material";
import ProfileMenu from "../atoms/profileMenu";
import { useNavigate, useLocation } from "react-router";
import QuickStartGuide from "../quick-start/quickStart";
import { Close } from '@mui/icons-material';
import { WhoAmI } from "../../redux/apis/user";
import { useSelector } from "react-redux";
import Pusher from "pusher-js";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getNotifications, markAsRead, markAllAsRead, clearAll, clearById } from "../../redux/apis/notifications";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';
import { NOTIFICATION_OPTIONS } from "../../utils/constant";
import { message } from "antd";


const MainHeader = ({ toggleSidebar, isMobile }) => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openQuickStart, setOpenQuickStart] = useState(false);
  const userRole = JSON.parse(localStorage.getItem(`userDetails`) || sessionStorage.getItem(`userDetails`))?.category;


  const handleClickOpen = () => {
    setOpenQuickStart(true);
  };

  const handleClose = () => {
    setOpenQuickStart(false);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.clear();
    localStorage.clear();
    localStorage.removeItem("userDetails");
    sessionStorage.removeItem("userDetails");
    localStorage.removeItem("cpoDetails");
    sessionStorage.removeItem("cpoDetails");
    sessionStorage.removeItem("eMspDetails");
    localStorage.removeItem("eMspDetails");

    const currentUserId = localStorage.getItem("currentUser");
    if (currentUserId) {
      localStorage.removeItem(`user_${currentUserId}`);
      localStorage.removeItem(`token_${currentUserId}`);
      localStorage.removeItem(`rememberMe_${currentUserId}`);
      localStorage.removeItem("currentUser");
    }

    navigate("/login");
  };

  const [userData, setuserData] = useState({
    file: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    category: "",
  });
  // Directly initialize userData and permissions using WhoAmI response
  useEffect(() => {
    WhoAmI()
      .then((response) => {
        const userDetails = response || {};

        setuserData({
          file: userDetails?.user?.profilePicture || "",
          firstName: userDetails?.user.firstName || "",
          lastName: userDetails?.user.lastName || "",
          email: userDetails?.user.email || "",
          phoneNumber: userDetails?.user.phoneNumber || "N/A",
          category: userDetails?.userRole || "",
        });

      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, []); // Dependency on myProfilePicture


  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotifications(currentPage); // Adjust with your API call
      setNotifications((prev) => [
        ...prev,
        ...response.list.map(item => ({
          id: item._id,
          type: item.type,
          data: item.data,
          read: item.isReadForCpo,
          createdAt: item.createdAt,
        }))
      ]);
      setTotalPages(response.totalPages);
      userRole === "CPO Admin" ?  setUnreadCount(response.list.filter((notif) => !notif.isReadForCpo).length) : setUnreadCount(response.list.filter((notif) => !notif.isRead).length);
     
    } catch (error) {
      console.error("Failed to fetch notifications", error);
    } finally {
      setLoading(false);
    }
  };

  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || 'cpo';

  const HeadingMapper = () => {
    switch (location?.pathname) {
      case "/home":
        return "Home";
      case "/chargers":
        return "Chargers";
      case "/evse-stations":
        return "EVSE Stations";
      case "/device-management/monitor":
        return "Monitor";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/device-management/ota-updates":
        return "OTA Updates";
      case "/site-host-operator":
        return "Site Host Operators";
      case "/cpo":
        return "CPO";
      case "/evse-owners":
        return "EVSE Owners";
      case "/analytics":
        return "Analytics";
      case "/settings":
        return "Settings";
      case `/csms/${cpoEndpoint}/home`:
        return "Home";
      case `/csms/${cpoEndpoint}/evse-stations`:
        return "EVSE Stations";
      case `/csms/${cpoEndpoint}/chargers`:
        return "Chargers";
      case `/csms/${cpoEndpoint}/customers`:
        return "Customers";
      case `/csms/${cpoEndpoint}/sessions`:
        return "Sessions";
      case `/csms/${cpoEndpoint}/analytics`:
        return "Analytics";
      case `/csms/${cpoEndpoint}/integration`:
        return "Integrations";
      case `/csms/${cpoEndpoint}/settings`:
        return "Settings";

    }
  };



  //Notifications logic
  const open = Boolean(anchorEl);

  useEffect(() => {

    fetchNotifications();
  }, [currentPage]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotifications = () => {
    setAnchorEl(null);
  };

  const handleMarkAsRead = async (id) => {
    try {
      await markAsRead(id); // Adjust with your API call
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.id === id ? { ...notif, read: true } : notif
        )
      );
      setUnreadCount(unreadCount - 1);
    }
    catch (error) {
      message.error(error?.response?.data?.message || "Failed to mark as read");
    }
  };

  const handleClearById = async (id) => {
    try {
      await clearById(id); // Adjust with your API call
      setNotifications((prev) => prev.filter((notif) => notif.id !== id));
    } catch (error) {
      message.error(error?.response?.data?.message || "Failed to clear");
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await markAllAsRead(); // Adjust with your API call
      setNotifications((prev) => prev.map((notif) => ({ ...notif, read: true })));
      setUnreadCount(0);
    }
    catch (error) {
      message.error(error?.response?.data?.message || "Failed to mark all as read");
    }
  };

  const handleClearAll = async () => {
    try {
      await clearAll(); // Adjust with your API call
      setNotifications([]);
      setUnreadCount(0);
    }
    catch (error) {
      message.error(error?.response?.data?.message || "Failed to clear");
    }
  };

  const loadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  //Pusher for notifications
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });


    const channel = pusher.subscribe('pusher-node-app');

    const events = [
      'Charger Registered',
      'Charger Activated',
      'CPO Registered',
      'CPO Activated',
      'EVSE Station Created',
      'Charger Assigned to CPO',
      'Charger Assigned to EVSE'
    ];

    events.forEach(event => {
      channel.bind(event, () => {
        fetchNotifications();
      });
    });

    return () => {
      channel.unbind_all();
      pusher.unsubscribe('pusher-node-app');
    };
  }, []);

  return (
    <>
      <AppBar position="static" sx={{ background: "#fff", color: "#000000", boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.1)", opacity: 1 }}>
        <Toolbar sx={{ overflowX: "hidden" }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleSidebar}
            >
              <Menu />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            fontSize={"19px"}
          >
            {HeadingMapper()}
          </Typography>
          <IconButton color="inherit" onClick={handleOpen} sx={{ position: "relative", marginRight: "20px" }}>
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <ProfileMenu
            name={userData.firstName + " " + userData.lastName}
            onLogout={handleLogout}
            onMyAccountClick={() => {
              { userData.category === "CPO Admin" ? navigate(`/csms/${cpoEndpoint}/settings`) : navigate("/settings") }
            }}
            role={(userData.category === "Super Admin") && "Super Admin" || (userData.category === "CPO Admin") && "CPO Admin" || (userData.category === "factory_manager") && "Factory Manager"}


          />
        </Toolbar>

      </AppBar>

      {/* QuickStartGuide Modal */}
      <Dialog open={openQuickStart} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Quick Start Guide</DialogTitle>
        <IconButton onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }} aria-label="close" color="primary" size="large">
          <Close />
        </IconButton>
        <DialogContent>
          <QuickStartGuide onClose={handleClose} openQuickStartModal={handleClickOpen} />
        </DialogContent>
      </Dialog>

      {/* Notifications */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseNotifications}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ width: 300, maxHeight: 500, overflowY: "auto" }}>
          <Typography variant="h6" align="center" sx={{ p: 2 }}>
            Notifications
          </Typography>
          <Divider />
          <List>
            {notifications.length > 0 ? (
              notifications.map((notif) => (
                <ListItem
                  key={notif.id}
                  selected={!notif.read}
                >
                  <ListItemText
                    primary={NOTIFICATION_OPTIONS[notif.type] || 'Unknown Notification'}
                    secondary={new Date(notif.createdAt).toLocaleString()}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="mark as read"
                      onClick={() => handleMarkAsRead(notif.id)}
                    >
                      <DoneAllIcon color={notif.read ? "disabled" : "primary"} />
                    </IconButton>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleClearById(notif.id)}
                    >
                      <CloseIcon color="error" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            ) : (
              <Typography variant="body2" align="center" sx={{ p: 2 }}>
                No notifications
              </Typography>
            )}
          </List>
          {loading && (
            <CircularProgress
              size={24}
              sx={{ display: "block", margin: "10px auto" }}
            />
          )}
          {currentPage < totalPages && !loading && (
            <Button onClick={loadMore} fullWidth>
              Load More
            </Button>
          )}
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-around", padding: 8 }}>
            <Button variant="text" onClick={handleMarkAllAsRead}>
              Mark All as Read
            </Button>
            <Button variant="text" color="error" onClick={handleClearAll}>
              Clear All
            </Button>
          </div>
        </div>
      </Popover>

    </>
  );
};

export default MainHeader;
