import * as React from "react";
import Box from "@mui/material/Box";
import { Tab, Tabs, Typography } from "@mui/material";
import TrafficGraph from "./trafficGraph";
import LoadGraph from "./loadGraph";
import ConnectionsGraph from "./connectionsGraph";
import { Card } from "../atoms/card";

function RealTimeGraph() {
  const [value, setValue] = React.useState("load");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card  xs={12} md={12}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="white"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value="load" label="Load" sx={{ textTransform: "capitalize" }} />
        <Tab
          value="traffic"
          label="Traffic"
          sx={{ textTransform: "capitalize" }}
        />
        <Tab
          value="connections"
          label="Connections"
          sx={{ textTransform: "capitalize" }}
        />
      </Tabs>
      <Typography component={"div"}>
        {value === "traffic" && <TrafficGraph />}
        {value === "load" && <LoadGraph />}
        {value === "connections" && <ConnectionsGraph />}
      </Typography>
    </Card>
  );
}

export default RealTimeGraph;
