import React, { useState, useEffect, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import ModalComponent from "../../components/atoms/ModalComponent";
import Barcode from "react-barcode";
import { Print } from "@mui/icons-material";
import { fetchSessionsAPI, fetchTransactionsAPI, fetchOcppLogsAPI } from "../../redux/apis/chargers";
import { useReactToPrint } from "react-to-print";
import { QRCodeSVG } from 'qrcode.react'; // Import QR code component
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import './ChargerDetailsComponent.css'; 
import TextField from "@mui/material/TextField";
import axios from "axios";
import {activateChargerAPI} from "../../redux/apis/chargers";
import { message } from "antd";
import Pusher from "pusher-js";
import { fetchChargerList } from "../../redux/apis/chargers";

const tableStyles = {
  minWidth: 650,
  textTransform: "capitalize",
  "& td, & th": {
    border: "1px solid #4C4E641F !important",
  },
};

const ChargerDetailsContainer = {
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  marginBottom: "1rem",
};

const ChargerInfo = {
  flex: 1,
  minWidth: "200px",
};

const pageStyle = `
@page {
  size: 200mm 200mm; /* Adjust the size as needed */
  margin: 0; /* Remove margins */
}

@media all {
  .pageBreak {
    display: none;
  }
}
@media print {
  .pageBreak {
    page-break-before: always;
  }

  /* Center the content for print */
  .pageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
}
`;

const ChargerDetailsComponent = ({ selectedCharger, open, handleClose, fetchChargers }) => {

  const [chargers, setChargers] = useState([]);
  const [activateChargerForm, setActivateChargerForm] = useState({
    serialNumber: "",
    activationCode: "",
    lat: "",
    lng: ""
  })
 const [isActivateChargerModalOpen, setIsActivateChargerModalOpen] = useState(false);
 const [recentStatus, setRecentStatus] = useState("");

  const barcodeRef = useRef(null); // Reference to the barcode content
  const qrCodeRef = useRef(null); // Reference to the QR code content

  
  const formatSerialNumber = (serialNumber) => {
    const formattedSerialNumber = `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
    return formattedSerialNumber;
  }

  
  const handleActivateCharger = async () => {
    try{
      activateChargerForm.lat = selectedCharger.evseStationLat;
      activateChargerForm.lng = selectedCharger.evseStationLng;
      const response = await activateChargerAPI(activateChargerForm);
    if (response.status === "activated") {
      setIsActivateChargerModalOpen(false);
      message.success("Charger activated successfully");
      handleClose();
      fetchChargers();
    }
    } catch (error) {
      message.error("Failed to activate charger");
    }
  }
  
  const openActivateModal = () => {
    setActivateChargerForm({ ...activateChargerForm, serialNumber: selectedCharger.serialNumber });
    setIsActivateChargerModalOpen(true);
  }

  const handleCloseActivateChargerModal = () => {
    setIsActivateChargerModalOpen(false);
  }

  const isChargerActive = ["registered"].includes(selectedCharger?.status);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY, {
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    });

    // Subscribe to the channel
    const channel = pusher.subscribe('pusher-node-app');

    channel.bind('charger-updated', (data) => {
      // Fetch chargers data again when the event is triggered
      updateSelectedCharger(data);
    });
    // Clean up on unmount
    return () => {
      channel.unbind('charger-updated');
      pusher.unsubscribe('pusher-node-app');
    };
  }, []);

  const updateSelectedCharger = (data) => {
    selectedCharger.activationCode = data.activationCode;
  };

  return (
    <>
    <ModalComponent open={open} handleClose={handleClose} headerText={"Charger Details"} showSubmitButton={false}>
      {selectedCharger && (
        <div>
          <div style={ChargerDetailsContainer}>
            <div style={ChargerInfo}>
              <Typography variant="body1">Serial Number: {formatSerialNumber(selectedCharger.serialNumber)}</Typography>
              <Typography variant="body1">Charge Box Id: {selectedCharger.chargeBoxId}</Typography>
              <Typography variant="body1">Model Name: {selectedCharger.chargerModel || "Prime"}</Typography>
              <Typography variant="body1">EVSE Station: {selectedCharger.evseStationAddress}</Typography>
              <Typography variant="body1">Activated: {!isChargerActive ? "Yes" : "No"}</Typography>
                {isChargerActive && (
                  <>
                    <Typography variant="body1">Activation Code: {selectedCharger.activationCode}</Typography>
                  </>
                )}
            </div>
            
            {/* Barcode Section */}
            <div>
              <div
                ref={barcodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="barcodeContainer">
                  <Barcode
                    value={selectedCharger.serialNumber}
                    className="barcodeSvg"
                  />
                </div>
              </div>
              <Typography sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              </Typography>
            </div>
            {/* QR Code Section */}
            <div>
              <div
                ref={qrCodeRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div className="qrCodeContainer">
                  <QRCodeSVG
                    value={selectedCharger.serialNumber}
                    size={128}
                    level="H"
                    includeMargin={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
     <ModalComponent
            open={isActivateChargerModalOpen}
            handleClose={handleCloseActivateChargerModal}
            headerText={"Activate Charger"}
            submitButtonLabel={"Activate"}
            handleSubmit={handleActivateCharger}
          >
            <div>
              <TextField
                label="Serial Number"
                value={activateChargerForm.serialNumber}
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Activation Code"
                value={activateChargerForm.activationCode}
                onChange={(e) => setActivateChargerForm({ ...activateChargerForm, activationCode: e.target.value })}
                fullWidth
                margin="normal"
                variant="outlined"
                required
              />
            </div>
          </ModalComponent>
    </>
  );
};

export default ChargerDetailsComponent;
