import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import { fetchChargerList } from "../../redux/apis/chargers";
import { setEvse, setCharger } from "../../redux/slices/filter/filterSlice";

function OtaFilterComponent() {
  const dispatch = useDispatch();
  const selectedEvseFromRedux = useSelector((state) => state.filter.evse);
  const selectedChargerFromRedux = useSelector((state) => state.filter.charger);

  const [evseStationList, setEvseStationList] = useState([]);
  const [chargerList, setChargerList] = useState([]);

  // Fetch EVSE stations on mount
  useEffect(() => {
    const fetchEvseStationsData = async () => {
      try {
        const response = await fetchEvseStationsAPI({ limit: 500 });
        if (response) setEvseStationList(response.list);
      } catch (error) {
        console.error("Error fetching EVSE stations:", error);
      }
    };
    fetchEvseStationsData();
  }, []);

  // Fetch chargers when an EVSE station is selected
  useEffect(() => {
    const fetchChargersData = async () => {
      if (selectedEvseFromRedux) {
        try {
          const response = await fetchChargerList({
            filter: { EvseStationId: selectedEvseFromRedux.id },
          });
          setChargerList(response.list);
        } catch (error) {
          console.error("Error fetching chargers:", error);
        }
      }
    };
    fetchChargersData();
  }, [selectedEvseFromRedux]);

  // Handle clearing the selections
  const handleClearClick = () => {
    dispatch(setEvse(''));
    dispatch(setCharger(''));
    setChargerList([]);
  };

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <FormControl fullWidth size="small" variant="outlined" sx={{ backgroundColor: '#fff', width: '200px' }}>
        <InputLabel>Evse Station</InputLabel>
        <Select
          value={selectedEvseFromRedux || ''}
          onChange={(e) => dispatch(setEvse(e.target.value))}
        >
          <MenuItem value="">None</MenuItem>
          {evseStationList.map((station) => (
            <MenuItem key={station.id} value={station}>
              {station.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth size="small" variant="outlined" disabled={!selectedEvseFromRedux} sx={{ backgroundColor: '#fff', width: '200px' }}>
        <InputLabel>Charger</InputLabel>
        <Select
          value={selectedChargerFromRedux || ''}
          onChange={(e) => dispatch(setCharger(e.target.value))}
        >
          <MenuItem value="">None</MenuItem>
          {chargerList.map((charger) => (
            <MenuItem key={charger.id} value={charger}>
              {charger.serialNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton onClick={handleClearClick}>
        <ClearIcon />
      </IconButton>
    </div>
  );
}

export default OtaFilterComponent;
