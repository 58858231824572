import { useEffect, useState } from "react";
import { CircularProgress, Typography, FormControl, Grid, TextField, Button, InputLabel, Select, MenuItem } from "@mui/material";
import { message } from "antd";
import { BarChart } from "@mui/x-charts/BarChart";
import { Autocomplete } from "@mui/material";
import { fetchAnalyticsData } from "../../redux/apis/analytics";
import { fetchChargerList } from "../../redux/apis/chargers";
import { fetchEvseStationsAPI } from "../../redux/apis/evseStations";
import UploadIcon from "../../components/icons/uploadIcon";
import { TIME_PERIOD_OPTIONS } from "../../utils/constant";
import * as XLSX from 'xlsx';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { fetchCpoAdmins } from "../../redux/apis/cpo";
import Popper from "@mui/material/Popper";


export default function MongodbChartExample() {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [analyticsOption, setAnalyticsOption] = useState("4");
  const [chargerList, setChargerList] = useState([]);
  const [evseStations, setEvseStations] = useState([]);
  const [chargerOption, setChargerOption] = useState("");
  const [evseOption, setEvseOption] = useState("");
  const [downloadFormat, setDownloadFormat] = useState("xlsx");
  const [cpoAdmins, setCpoAdmins] = useState([]);
  const [cpoOption, setCpoOption] = useState("");

  const userDetails = JSON.parse(localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails'));

  const getAnalyticsData = async () => {
    try {
      const response = await fetchAnalyticsData({ duration: `${analyticsOption}`, chargerId: `${chargerOption}`, evseStationId: `${evseOption}` });
      setAnalyticsData(response || []);
    } catch (error) {
      message.error(error.response?.data?.message || "An error occurred");
      setAnalyticsData([]);
    }
  };

  const getChargerList = async () => {
    try {
      const response = await fetchChargerList({
        filter: evseOption ? { EvseStationId: evseOption } : ""
      });
      setChargerList(response.list || []);
    } catch (error) {
      message.error(error.response?.data?.message || "An error occurred");
      setChargerList([]);
    }
  };

  const getEvseStations = async () => {
    try {
      const response = await fetchEvseStationsAPI({
        filter: cpoOption ? { cpoId: cpoOption } : "",
        limit: 10000
      });
      setEvseStations(response.list || []);
    } catch (error) {
      message.error(error.response?.data?.message || "An error occurred");
      setEvseStations([]);
    }
  };

  const getCpoList = async () => {
    try {
      const response = await fetchCpoAdmins({
        limit: 10000
      });
      setCpoAdmins(response.list || []);
    }
    catch (error) {
      message.error(error.response?.data?.message || "An error occurred");
    }
  }



  useEffect(() => {
    getAnalyticsData();
    getChargerList();
    getEvseStations();
    if (userDetails?.category === "Super Admin") {
      getCpoList();
    }
  }, [analyticsOption, chargerOption, evseOption, userDetails?.category]);


  useEffect(() => {
    getEvseStations()
  }, [cpoOption]);

  const months = analyticsData.length ? analyticsData.map(item => item.month) : [];
  const energyConsumed = analyticsData.length ? analyticsData.map(item => parseFloat(item.energyConsumed)) : [];

  const clearFilter = () => {
    setChargerOption("");
    setEvseOption("");
    setCpoOption("");
  }

  const exportData = async () => {
    const filename = "analytics_data";

    if (downloadFormat === "xlsx") {
      const ws = XLSX.utils.json_to_sheet(analyticsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "AnalyticsData");
      XLSX.writeFile(wb, `${filename}.xlsx`);
    } else if (downloadFormat === "csv") {
      const ws = XLSX.utils.json_to_sheet(analyticsData);
      const csv = XLSX.utils.sheet_to_csv(ws);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", `${filename}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (downloadFormat === "pdf") {
      const doc = new jsPDF();
      const chartElement = document.getElementById("bar-chart");

      if (chartElement) {
        const canvas = await html2canvas(chartElement);
        const imgData = canvas.toDataURL("image/png");
        doc.addImage(imgData, "PNG", 10, 10, 180, 100);
        doc.save(`${filename}.pdf`);
      }
    }
  };

  const CustomPopper = (props) => (
    <Popper
      {...props}
      style={{
        zIndex: 1300,       // Keep the dropdown above other elements
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
        borderRadius: "4px",       // Rounded corners
      }}
    />
  );

  return (
    <>
      <Typography alignItems={"center"} justifyContent={"center"}>
        <Grid container spacing={2} sx={{display: "flex", alignItems: "center"}} >
          <Grid item xs={12} sm="auto">
            <Typography component="h1" fontWeight="500" fontSize="1.25rem" marginBlock="1rem" marginTop={"auto"}>
              Energy Consumed
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <FormControl fullWidth>
              <InputLabel>Time Period</InputLabel>
              <Select
                value={analyticsOption}
                onChange={(event) => setAnalyticsOption(event.target.value)}
                label="Time Period"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "7px", // Minimal padding for Select
                  },
                }}
              >
                {TIME_PERIOD_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md="auto">
            <FormControl fullWidth sx={{ minWidth: 80 }}>
              <InputLabel>Format</InputLabel>
              <Select
                value={downloadFormat}
                onChange={(event) => setDownloadFormat(event.target.value)}
                label="Format"
                sx={{
                  "& .MuiSelect-select": {
                    padding: "7px", // Minimal padding for Select
                  },
                }}
              >
                <MenuItem value="xlsx">XLSX</MenuItem>
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Typography onClick={exportData} sx={{ cursor: "pointer" }}>
              <UploadIcon />
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button onClick={clearFilter} variant="outlined" sx={{ cursor: "pointer" }}>
              Clear Filter
            </Button>
          </Grid>
        </Grid>
      </Typography>
      <Typography sx={{ mb: 2, mt: 1, width: "100%" }} justifyContent={"space-between"}>
        <Grid container spacing={2} alignItems="center">
          {userDetails?.category === "Super Admin" && (
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                options={cpoAdmins}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                onChange={(event, newValue) => setCpoOption(newValue?.cpoId || "")}
                renderInput={(params) => <TextField {...params} label="CPO" />}
                PopperComponent={CustomPopper}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0px", // Adjust padding to a minimal value
                  },
                  "& .MuiInputLabel-root": {
                    top: "-25%", // Adjust label position as needed
                  },
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={evseStations}
              getOptionLabel={(option) => option.name || ""}
              onChange={(event, newValue) => setEvseOption(newValue?.id || "")}
              renderInput={(params) => <TextField {...params} label="EVSE" />}
              PopperComponent={CustomPopper}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px", // Adjust padding to a minimal value
                },
                "& .MuiInputLabel-root": {
                  top: "-25%", // Adjust label position as needed
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              options={chargerList}
              getOptionLabel={(option) => option.chargeBoxId || ""}
              onChange={(event, newValue) => setChargerOption(newValue?.id || "")}
              renderInput={(params) => <TextField {...params} label="Charger" />}
              PopperComponent={CustomPopper}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0px", // Adjust padding to a minimal value
                },
                "& .MuiInputLabel-root": {
                  top: "-25%", // Adjust label position as needed
                },
              }}
            />
          </Grid>
        </Grid>
      </Typography>

      {months.length > 0 && energyConsumed.length > 0 ? (
        <div id="bar-chart">
          <BarChart
            series={[{ data: energyConsumed }]}
            height={400}
            xAxis={[
              {
                data: months,
                scaleType: "band",
              },
            ]}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
            colors={["#073D81"]}
          />
        </div>
      ) : (
        <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Typography>
      )}
    </>
  );
}
