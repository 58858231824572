import React from "react";

function EsevIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 21.019 23.354"
    >
      <path
        id="recent_patient_FILL1_wght400_GRAD0_opsz24"
        d="M160-781.316v-3.27a3.421,3.421,0,0,1,.511-1.825,3.405,3.405,0,0,1,1.357-1.27,17.338,17.338,0,0,1,3.678-1.357,16.064,16.064,0,0,1,3.8-.452q.584,0,1.168.044t1.168.131v8Zm9.342-9.342a4.5,4.5,0,0,1-3.3-1.372,4.5,4.5,0,0,1-1.372-3.3,4.5,4.5,0,0,1,1.372-3.3,4.5,4.5,0,0,1,3.3-1.372,4.5,4.5,0,0,1,3.3,1.372,4.5,4.5,0,0,1,1.372,3.3,4.5,4.5,0,0,1-1.372,3.3A4.5,4.5,0,0,1,169.342-790.658Zm7.006,14.013v-5.839h-2.335v-7.006h7.006l-2.335,4.671h2.335Z"
        transform="translate(-160 800)"
        fill="#fff"
      />
    </svg>
  );
}

export default EsevIcon;
