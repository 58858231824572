import React from "react";

function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.35"
      height="21.5"
      viewBox="0 0 19.35 21.5"
    >
      <g id="noun-export-402777" transform="translate(-39.05 -39.558)">
        <path
          id="Path_4779"
          data-name="Path 4779"
          d="M54.8,62.906H51.584v1.906H54.8c.955,0,1.7.488,1.7.908v8.453c0,.42-.741.908-1.7.908H42.653c-.955,0-1.7-.488-1.7-.908V65.721c0-.42.741-.908,1.7-.908h3.212V62.906H42.653c-2.02,0-3.6,1.236-3.6,2.814v8.453c0,1.578,1.583,2.814,3.6,2.814H54.8c2.02,0,3.6-1.236,3.6-2.814V65.721C58.4,64.142,56.817,62.906,54.8,62.906Z"
          transform="translate(0 -15.93)"
          fill="#9999a5"
        />
        <path
          id="Path_4780"
          data-name="Path 4780"
          d="M58.575,44.309a.95.95,0,0,0,.674-.279l1.193-1.193v9.7a.953.953,0,1,0,1.906,0V42.772l1.258,1.258a.953.953,0,0,0,1.348-1.348l-2.846-2.846a.949.949,0,0,0-.674-.279h-.015a.949.949,0,0,0-.674.279L57.9,42.682a.953.953,0,0,0,.674,1.627Z"
          transform="translate(-12.671)"
          fill="#9999a5"
        />
      </g>
    </svg>
  );
}

export default UploadIcon;
