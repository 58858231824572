import React from "react";

function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 20.903 20.799"
    >
      <path
        id="settings_FILL1_wght400_GRAD0_opsz24"
        d="M85.592-859.2l-.416-3.328a3.957,3.957,0,0,1-.637-.312q-.3-.182-.585-.39l-3.094,1.3L78-866.87l2.678-2.028a2.479,2.479,0,0,1-.026-.351v-.7a2.484,2.484,0,0,1,.026-.351L78-872.33l2.86-4.94,3.094,1.3a7.089,7.089,0,0,1,.6-.39,5.254,5.254,0,0,1,.624-.312L85.592-880h5.72l.416,3.328a3.957,3.957,0,0,1,.637.312q.3.182.585.39l3.094-1.3,2.86,4.94L96.225-870.3a2.484,2.484,0,0,1,.026.351v.7a1.274,1.274,0,0,1-.052.351l2.678,2.028-2.86,4.94-3.068-1.3a7.115,7.115,0,0,1-.6.39,5.254,5.254,0,0,1-.624.312l-.416,3.328Zm2.912-6.76a3.507,3.507,0,0,0,2.574-1.066,3.507,3.507,0,0,0,1.066-2.574,3.508,3.508,0,0,0-1.066-2.574A3.507,3.507,0,0,0,88.5-873.24a3.5,3.5,0,0,0-2.587,1.066,3.53,3.53,0,0,0-1.053,2.574,3.529,3.529,0,0,0,1.053,2.574A3.5,3.5,0,0,0,88.5-865.96Z"
        transform="translate(-78 880)"
        fill="#fff"
      />
    </svg>
  );
}

export default SettingsIcon;
