import React from "react";
import { Typography, Grid, TextField } from "@mui/material";
import { Card } from "../atoms/card";
import Select from "react-select";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../../utils/constant";
import ButtonComponent from "../atoms/button/button";

function Customerquery() {
  return (
    <div>
      <Typography
        component={"h1"}
        color={"secondary.main"}
        fontSize={"22px"}
        fontWeight={"600"}
      >
        For any queries fill this form
      </Typography>

      <Card xs={12} md={12} padding={"1rem"}>
        <Typography fontSize={"20px"} fontWeight={"500"}>
          Customer Query Form
        </Typography>
        <Grid my={"1rem"}>
          <Typography>Enter Customer ID</Typography>
          <Typography>
            Choose any of the following options for the customer ID and enter
            the selected information
          </Typography>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            xs={8}
            gap={2}
          >
            <Typography marginRight={"8px"} width={"50%"}>
              {" "}
              <Select
                className="select-component"
                classNamePrefix="select"
                isSearchable={true}
                name="color"
                options={SITE_HOST_OPTIONS}
                placeholder="Customer ID"
              />
            </Typography>
            <Typography width={"50%"}>
              <TextField
                margin="normal"
                fullWidth
                sx={{ marginBottom: "20px" }}
                id="email"
                // label="Your Email"
                name="email"
                autoComplete="email"
                placeholder="Enter Customer ID"
                autoFocus
                hiddenLabel
                InputProps={{
                  sx: {
                    borderRadius: "14px",
                    fontSize: "14px",
                    height: "38px",
                    width: "100%",
                  },
                }}
                InputLabelProps={{ sx: { fontSize: "14px" } }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid my={"1rem"}>
          <Typography>Select Query Type</Typography>
          <Typography>
            Choose any of the following options for the Query Type and enter the
            selected information
          </Typography>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            xs={8}
            gap={2}
          >
            <Typography marginRight={"8px"} width={"50%"}>
              {" "}
              <Select
                className="select-component"
                classNamePrefix="select"
                isSearchable={true}
                name="color"
                options={SITE_HOST_OPTIONS}
                placeholder="Query Type"
              />
            </Typography>
            <Typography width={"50%"}>
              <TextField
                margin="normal"
                fullWidth
                sx={{ marginBottom: "20px" }}
                id="email"
                // label="Your Email"
                name="email"
                autoComplete="email"
                placeholder="Enter Customer ID"
                autoFocus
                hiddenLabel
                InputProps={{
                  sx: {
                    borderRadius: "14px",
                    fontSize: "14px",
                    height: "38px",
                    width: "100%",
                  },
                }}
                InputLabelProps={{ sx: { fontSize: "14px" } }}
              />
            </Typography>
          </Grid>
          <Grid xs={8} textAlign={"end"}>
            <ButtonComponent label={"Submit"} />
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Customerquery;
