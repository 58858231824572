import React from "react";

function DeviceManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 23.179 19.851"
    >
      <path
        id="manage_accounts_FILL1_wght400_GRAD0_opsz24"
        d="M96.348-780.149,96-781.9q-.35-.146-.657-.307a5.091,5.091,0,0,1-.628-.394l-1.693.525-1.168-1.985,1.343-1.168a5.392,5.392,0,0,1-.058-.759,5.392,5.392,0,0,1,.058-.759l-1.343-1.168L93.02-789.9l1.693.525a5.091,5.091,0,0,1,.628-.394q.307-.16.657-.307l.35-1.752h2.335l.35,1.752a5.9,5.9,0,0,1,.657.321,4.618,4.618,0,0,1,.628.438l1.693-.584,1.168,2.043-1.343,1.168a4.434,4.434,0,0,1,.058.73,4.434,4.434,0,0,1-.058.73l1.343,1.168-1.168,1.985-1.693-.525a5.092,5.092,0,0,1-.628.394q-.307.161-.657.307l-.35,1.752ZM80-781.316v-3.27a3.52,3.52,0,0,1,.5-1.81,3.236,3.236,0,0,1,1.372-1.284,18.285,18.285,0,0,1,3.357-1.284,15.172,15.172,0,0,1,4.116-.525h.409a1.1,1.1,0,0,1,.35.058,9.315,9.315,0,0,0-.7,4.175,7.99,7.99,0,0,0,1.4,3.941Zm17.516-2.335a2.249,2.249,0,0,0,1.649-.686,2.249,2.249,0,0,0,.686-1.649,2.249,2.249,0,0,0-.686-1.649,2.249,2.249,0,0,0-1.649-.686,2.249,2.249,0,0,0-1.649.686,2.249,2.249,0,0,0-.686,1.649,2.249,2.249,0,0,0,.686,1.649A2.249,2.249,0,0,0,97.516-783.652Zm-8.174-7.006a4.5,4.5,0,0,1-3.3-1.372,4.5,4.5,0,0,1-1.372-3.3,4.5,4.5,0,0,1,1.372-3.3,4.5,4.5,0,0,1,3.3-1.372,4.5,4.5,0,0,1,3.3,1.372,4.5,4.5,0,0,1,1.372,3.3,4.5,4.5,0,0,1-1.372,3.3A4.5,4.5,0,0,1,89.342-790.658Z"
        transform="translate(-80 800)"
        fill="#fff"
      />
    </svg>
  );
}

export default DeviceManagementIcon;
