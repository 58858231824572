import React from "react";

function BusyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.483"
      height="23.168"
      viewBox="0 0 46.96 29.768"
    >
      <g id="Layer_5" data-name="Layer 5" transform="translate(-2.5 -7.5)">
        <path
          id="Subtraction_1"
          data-name="Subtraction 1"
          d="M44.111,29.768H30.357a2.852,2.852,0,0,1-2.849-2.849V10.856H25.471a4.022,4.022,0,0,0-3.823,2.759l-.361,1.166.383.241a6.253,6.253,0,0,1,2.94,5.295V23.48a2.852,2.852,0,0,1-2.849,2.849H15.88l-.141.389a4.568,4.568,0,0,1-8.588,0l-.14-.389H1.13a1.129,1.129,0,1,1,0-2.258H7.011l.14-.389a4.568,4.568,0,0,1,8.588,0l.141.389h6.47v-2.9H20.041a1.13,1.13,0,0,1,0-2.259H22.3l-.675-.935a3.99,3.99,0,0,0-2.009-1.468,9.444,9.444,0,0,0-3.008-.495H1.13a1.13,1.13,0,0,1,0-2.259H11.136l-4.145-2.8A10.883,10.883,0,0,0,1.139,9.136H1.13a1.129,1.129,0,0,1-.005-2.258A13.147,13.147,0,0,1,8.2,9.1l7.025,4.655h1.387a10.5,10.5,0,0,1,2.013.2l.509.1.387-1.162A6.281,6.281,0,0,1,25.474,8.6h2.034V4.569A4.574,4.574,0,0,1,32.077,0H42.391A4.574,4.574,0,0,1,46.96,4.569v22.35A2.852,2.852,0,0,1,44.111,29.768ZM32.077,2.259a2.312,2.312,0,0,0-2.309,2.309V27.509H44.7V4.569a2.312,2.312,0,0,0-2.309-2.309ZM11.445,22.891A2.309,2.309,0,1,0,13.755,25.2,2.312,2.312,0,0,0,11.445,22.891Z"
          transform="translate(2.5 7.5)"
          fill="#fff"
        />
        <path
          id="Subtraction_2"
          data-name="Subtraction 2"
          d="M2.825,9.249h0a1,1,0,0,1-.476-.12l-.011-.005a1.135,1.135,0,0,1-.495-1.518l.881-1.8h-1.6a1.129,1.129,0,0,1-1-1.639L1.842.732,1.859.694A1.124,1.124,0,0,1,2.811,0Q2.856,0,2.9,0a1.129,1.129,0,0,1,.968,1.711l-.9,1.839h1.6a1.13,1.13,0,0,1,1,1.639L3.847,8.627a1.126,1.126,0,0,1-1.01.621Z"
          transform="translate(36.89 21.142)"
          fill="#fff"
        />
        <path
          id="Subtraction_3"
          data-name="Subtraction 3"
          d="M4.568,2.259H1.13A1.13,1.13,0,0,1,1.13,0H4.568a1.13,1.13,0,1,1,0,2.259Z"
          transform="translate(36.885 14.377)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default BusyIcon;
