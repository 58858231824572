import React from 'react';
import HomeIcon from '../icons/homeIcon';
import ChargersIcon from '../icons/chargersIcon';
import EsevIcon from '../icons/esevIcon';
import SitehostsIcon from '../icons/sitehostsIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import DeviceManagementIcon from '../icons/deviceManagementIcon';
import SettingsIcon from '../icons/settingsIcon';

const AdminSidebarItems = [
  {
    name: "Home",
    icon: <HomeIcon />,
    route: "/home",
  },
  {
    name: "CPO",
    icon: <SitehostsIcon />,
    route: "/cpo",
    
  },
  {
    name: "EVSE Stations",
    icon: <EsevIcon />,
    route: "/evse-stations",
  },
  {
    name: "Chargers",
    icon: <ChargersIcon />,
    route: "/chargers",
  },
  {
    name: "Device Mgmt",
    icon: <DeviceManagementIcon />,
    route: "/device-management",
    children: [
      {
        name: "Monitor",
        route: "/device-management/monitor",
      },
      {
        name: "OTA Updates",
        route: "/device-management/ota-updates",
      },
    ],
  },
  {
    name: "Analytics",
    icon: <AnalyticsIcon />,
    route: "/analytics",
  },
  {
    name: "Settings",
    icon: <SettingsIcon />,
    route: "/settings",
  },
  {
    name: "Help and Support",
    icon: <SettingsIcon />,
    route: "/support",
  },
  {
    name: "Dev Tools",
    icon: <SettingsIcon />,
    route: "/dev-tools",
  },
];

export default AdminSidebarItems;
