import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Select from "react-select";
import UploadIcon from "../icons/uploadIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import styles from "./mongodbChartExample.module.scss";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../../utils/constant";
import Box from "@mui/material/Box";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { LinePlot } from "@mui/x-charts/LineChart";
import { BarPlot } from "@mui/x-charts/BarChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

function AnalyticsRvsCoChart() {
  return (
    <Grid
      xs={12}
      sm={12}
      md={5.9}
      sx={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 20px #0000000D",
        borderRadius: "20px",
        opacity: "1",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        minHeight: "400px",
        marginTop: "20px",
      }}
    >
      <Typography
        component={"div"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        fontSize={"14px"}
        marginBottom={"0.5rem"}
      >
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={SITE_HOST_OPTIONS}
            placeholder="Site Host"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          Time Period
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={TIME_PERIOD_OPTIONS}
            placeholder="Time Period"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          <UploadIcon />
        </Typography>
      </Typography>
      <Typography
        component={"h1"}
        fontWeight={"500"}
        fontSize={"1.375rem"}
        marginBottom={"1rem"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        Revenue vs Consumption
      </Typography>
      <Typography>
        <div className={styles.chart_container}>
          <Box sx={{ width: "100%", maxWidth: 600 }}>
            <ResponsiveChartContainer
              xAxis={[
                {
                  scaleType: "band",
                  data: ["Q1", "Q2", "Q3", "Q4"],
                  id: "quarters",
                  label: "Quarters",
                },
              ]}
              yAxis={[{ id: "money" }, { id: "quantities" }]}
              series={[
                {
                  type: "line",
                  id: "revenue",
                  yAxisKey: "money",
                  data: [5645, 7542, 9135, 12221],
                },
                {
                  type: "bar",
                  id: "cookies",
                  yAxisKey: "quantities",
                  data: [3205, 2542, 3135, 8374],
                },
                {
                  type: "bar",
                  id: "icecream",
                  yAxisKey: "quantities",
                  data: [1645, 5542, 5146, 3735],
                },
              ]}
              height={400}
              margin={{ left: 70, right: 70 }}
              sx={{
                [`.${axisClasses.left} .${axisClasses.label}`]: {
                  transform: "translate(-25px, 0)",
                },
                [`.${axisClasses.right} .${axisClasses.label}`]: {
                  transform: "translate(30px, 0)",
                },
              }}
              colors={["#D6CACA", "#002F69", "#23CB10"]}
            >
              <BarPlot />
              <LinePlot />
              <ChartsXAxis
                axisId="quarters"
                label="2021 quarters"
                labelFontSize={18}
              />
              <ChartsYAxis axisId="quantities" label="# units sold" />
              {/* <ChartsYAxis axisId="money" position="right" label="revenue" /> */}
            </ResponsiveChartContainer>
          </Box>
        </div>
      </Typography>
    </Grid>
  );
}

export default AnalyticsRvsCoChart;
