import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import { Card } from "../../components/atoms/card";
import {
  Avatar,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  Button,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  CircularProgress
} from "@mui/material";
import CardStyles from "../../components/atoms/card/index.module.scss";
import ButtonComponent from "../../components/atoms/button/button";
import PlanShowModal from "../../components/settingsComponents/planShowModal";
import UserManagementContainer from "../../components/settingsComponents/cpoUserManagementContainer";
import axios from "axios";
import ModalComponent from "../../components/atoms/ModalComponent";
import { message } from "antd";
import {fetchUserPaymentAccounts, AddUserPaymentAccount, updateUserPaymentAccount, deleteUserPaymentAccount} from "../../redux/apis/user";
import CpoAccountSettings from "../../components/settingsComponents/cpoAccountSettings";
import MyProfile from "../../components/settingsComponents/cpomyProfile";
import Roles from "../../components/settingsComponents/cporoles";
import CpoBaseRate from "../../components/settingsComponents/cpoBaseRate";
import SuperAdminSettings from "../../components/settingsComponents/eMSPSettings";
import SuperAdminUserManagement from "../../components/settingsComponents/eMSPuserManagement";
import SuperAdminMyProfile from "../../components/settingsComponents/eMSPmyProfile";
import SuperAdminRoles from "../../components/settingsComponents/eMSPRoles";


function Settings() {
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("");
  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails);
  const userId = localStorage.getItem('currentUser') || sessionStorage.getItem('currentUser');
  const category = userDetails && userDetails.category;

  useEffect(() => {
    if (category == "Super Admin") {
      setSelectedActiveSettingTab("super_admin_settings");
    } else if(category === "CPO Admin") {
      setSelectedActiveSettingTab("cpo_account_settings");
    }else{
      setSelectedActiveSettingTab("my_profile");
    }
  }, [category, setSelectedActiveSettingTab]); 

  return (
    <MainLayout setActiveTab={setSelectedActiveSettingTab}>

      {
        (userDetails.category === "CPO Admin") && ( 
        <>
            {activeSettingTab === "user_management" && <UserManagementContainer />}
            {activeSettingTab === "my_profile" && <MyProfile />}
            {activeSettingTab === "cpo_account_settings" && <CpoAccountSettings />}
            {activeSettingTab === "roles" && <Roles />}
            {activeSettingTab === "base_rate" && <CpoBaseRate />}
        </>
        ) 
      }
      {(userDetails.category === "Super Admin") && (
          <>
            {activeSettingTab === "user_management_super_admin" && <SuperAdminUserManagement />}
            {activeSettingTab === "my_profile_super_admin" && <SuperAdminMyProfile />}
            {activeSettingTab === "super_admin_settings" && <SuperAdminSettings />}
            {activeSettingTab === "roles_super_admin" && <SuperAdminRoles />}
          </>
      )}

      {
        (userDetails.category === "factory_manager") && (
          <>
            {activeSettingTab === "my_profile" && <MyProfile />}
          </>
        )
      }
         
    </MainLayout>
  );
}

export default Settings;
