import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/mainLayout";
import { Card } from "../../components/atoms/card";
import {
  Avatar,
  Grid,
  LinearProgress,
  Typography,
  TextField,
  Button,
  TableCell,
  TableRow,
  TableHead,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  CircularProgress
} from "@mui/material";
import CardStyles from "../../components/atoms/card/index.module.scss";
import ButtonComponent from "../../components/atoms/button/button";
import PlanShowModal from "../../components/settingsComponents/planShowModal";
import UserManagementContainer from "../../components/settingsComponents/cpoUserManagementContainer";
import axios from "axios";
import ModalComponent from "../../components/atoms/ModalComponent";
import { message } from "antd";
;


function Customers() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    // Sample data
    const rows = [
        // Add more rows as needed
    ];

  return (
    <MainLayout >

      
        <TableContainer
        sx={{
            maxHeight: "55vh",
            overflowX: "hidden",
            overflowY: "auto",
            borderRadius: "8px 8px 0 0",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e0e0e0",
            backgroundColor: "#ffffff",
          }}
          >
                <Table>
                <TableHead sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}>
                        <TableRow>
                            <TableCell align="center">Customer ID</TableCell>
                            <TableCell align="center">Customer Name</TableCell>
                            <TableCell align="center">Email Address</TableCell>
                            <TableCell align="center">Phone Number</TableCell>
                            <TableCell align="center">Account Status</TableCell>
                            <TableCell align="center">Registered Vehicle Detail</TableCell>
                            <TableCell align="center">Total Charging Sessions</TableCell>
                            <TableCell align="center">Total Energy Consumed (kWh)</TableCell>
                            <TableCell align="center">Preferred Payment Method</TableCell>
                            <TableCell align="center">Last Charging Session</TableCell>
                            <TableCell align="center">Total Amount Spent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody
                    sx={{
                        "& td": {
                          border: "1px solid #e0e0e0",
                          color: "#555",
                        },
                        "& tr:nth-of-type(odd)": {
                          backgroundColor: "#fafafa",
                        },
                        "& tr:hover": {
                          backgroundColor: "#f1f1f1",
                        },
                      }}
                      >
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{row.customerId}</TableCell>
                                    <TableCell align="center">{row.customerName}</TableCell>
                                    <TableCell align="center">{row.email}</TableCell>
                                    <TableCell align="center">{row.phone}</TableCell>
                                    <TableCell align="center">{row.accountStatus}</TableCell>
                                    <TableCell align="center">{row.vehicleDetail}</TableCell>
                                    <TableCell align="center">{row.chargingSessions}</TableCell>
                                    <TableCell align="center">{row.energyConsumed} kWh</TableCell>
                                    <TableCell align="center">{row.paymentMethod}</TableCell>
                                    <TableCell align="center">{row.lastSession}</TableCell>
                                    <TableCell align="center">{row.totalSpent}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
             sx={{
                position: "sticky",
                bottom: 0,
                overflowX : "hidden", 
                padding: "0 0.5rem",
                background: "#f5f3f3",
                borderTop: "1px solid #e0e0e0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "0 0 8px 8px",
              }}
                component="div"
                count={rows.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            />
    </MainLayout>
  );
}

export default Customers;
