import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Select from "react-select";
import UploadIcon from "../icons/uploadIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import styles from "./mongodbChartExample.module.scss";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../../utils/constant";
import { ScatterChart } from "@mui/x-charts";

function AnalyticsRvsEvsCoChart() {
  return (
    <Grid
      xs={12}
      sm={12}
      md={5.9}
      sx={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 20px #0000000D",
        borderRadius: "20px",
        opacity: "1",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginTop:"20px"
      }}
    >
      <Typography
        component={"div"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        fontSize={"14px"}
        marginBottom={"0.5rem"}
      >
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={SITE_HOST_OPTIONS}
            placeholder="Site Host"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          Time Period
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={TIME_PERIOD_OPTIONS}
            placeholder="Time Period"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          <UploadIcon />
        </Typography>
      </Typography>
      <Typography
        component={"h1"}
        fontWeight={"500"}
        fontSize={"1.375rem"}
        marginBottom={"1rem"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        Consumption vs Revenue vs Transaction
      </Typography>
      <Typography>
        <div className={styles.chart_container}>
          <ScatterChart
          height={400}
          
            series={[
              {
                label: "Series A",
                data: data.map((v) => ({ x: v.x1, y: v.y1, id: v.id })),
              },
              {
                label: "Series B",
                data: data.map((v) => ({ x: v.x1, y: v.y2, id: v.id })),
              },
            ]}
            skipAnimation
          />
        </div>
      </Typography>
    </Grid>
  );
}

export default AnalyticsRvsEvsCoChart;

const data = [
  {
    id: "data-0",
    x1: 329.39,
    x2: 391.29,
    y1: 443.28,
    y2: 153.9,
  },
  {
    id: "data-1",
    x1: 96.94,
    x2: 139.6,
    y1: 110.5,
    y2: 217.8,
  },
  {
    id: "data-2",
    x1: 336.35,
    x2: 282.34,
    y1: 175.23,
    y2: 286.32,
  },
  {
    id: "data-3",
    x1: 159.44,
    x2: 384.85,
    y1: 195.97,
    y2: 325.12,
  },
  {
    id: "data-4",
    x1: 188.86,
    x2: 182.27,
    y1: 351.77,
    y2: 144.58,
  },
  {
    id: "data-5",
    x1: 143.86,
    x2: 360.22,
    y1: 43.253,
    y2: 146.51,
  },
  {
    id: "data-6",
    x1: 202.02,
    x2: 209.5,
    y1: 376.34,
    y2: 309.69,
  },
  {
    id: "data-7",
    x1: 384.41,
    x2: 258.93,
    y1: 31.514,
    y2: 236.38,
  },
  {
    id: "data-8",
    x1: 256.76,
    x2: 70.571,
    y1: 231.31,
    y2: 440.72,
  },
  {
    id: "data-9",
    x1: 143.79,
    x2: 419.02,
    y1: 108.04,
    y2: 20.29,
  },
  {
    id: "data-10",
    x1: 103.48,
    x2: 15.886,
    y1: 321.77,
    y2: 484.17,
  },
  {
    id: "data-11",
    x1: 272.39,
    x2: 189.03,
    y1: 120.18,
    y2: 54.962,
  },
  {
    id: "data-12",
    x1: 23.57,
    x2: 456.4,
    y1: 366.2,
    y2: 418.5,
  },
  {
    id: "data-13",
    x1: 219.73,
    x2: 235.96,
    y1: 451.45,
    y2: 181.32,
  },
  {
    id: "data-14",
    x1: 54.99,
    x2: 434.5,
    y1: 294.8,
    y2: 440.9,
  },
];
