import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  endpoint: "", // Initialize userRole state
  cpoProfilePicture: false, // Initialize profile picture as null (no file)
  myProfilePicture: false,  // Initialize my profile picture as null (no file)
};

const cpoSlice = createSlice({
  name: "cpo",
  initialState,
  reducers: {
    updateCpoEndpoint: (state, action) => {
      state.endpoint = action.payload;
    },
    updateCpoProfilePicture: (state, action) => {
      state.cpoProfilePicture = action.payload; // Update with the new image file
    },
    updateMyProfilePicture: (state, action) => {
      state.myProfilePicture = action.payload; // Update with the new image file
    },
  },
});

export const { updateCpoEndpoint, updateCpoProfilePicture, updateMyProfilePicture } = cpoSlice.actions;

export default cpoSlice.reducer;
