import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  MenuItem,
  IconButton,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Add, Edit, Search, Delete } from "@mui/icons-material";
import ModalComponent from "../atoms/ModalComponent";
import ButtonComponent from "../atoms/button/button";
import { message } from "antd";
import {
  fetchCPOUsers,
  deleteCPOUser,
  updateCPOUser,
  addCPOUser,
  fetchRoles,
} from "../../redux/apis/cpo";

function UserManagementContainer() {
  const storedDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const userDetails = storedDetails && JSON.parse(storedDetails);

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    cpouserRoleId: "",
  });
  const [errors, setErrors] = useState({}); // State to track form errors
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for delete dialog, ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);


  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await fetchCPOUsers();
      setUsers(response.list);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error("Failed to fetch users.");
    } finally {
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      const response = await fetchRoles();
      setRoles(response.list);
    } catch (error) {
      console.error("Error fetching roles:", error);
      message.error("Failed to fetch roles.");
    }
  };

  useEffect(() => {
    getUsers();
    getRoles();
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openEditUserModal = (user) => {
    setSelectedUser(user);
    setUserData({
      name: user.firstName + " " + user.lastName,
      email: user.email,
      phone: user.phoneNumber,
      cpouserRoleId: user.cpouserRoleId,
    });
    setErrors({});
    setEditUserModalOpen(true);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!userData.name) newErrors.name = "Name is required.";
    if (!userData.email) newErrors.email = "Email is required.";

    if (!userData.cpouserRoleId) newErrors.cpouserRoleId = "Role is required.";

    if (!userData.phone) newErrors.phone = "Phone number is required.";
    else if (isNaN(userData.phone)) {
      newErrors.phone = "Phone number must be a numeric value";
    }
    else if (userData.phone.length !== 10) {
      newErrors.phone = "Phone number must be 10 digits";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddUser = async () => {
    if (!validateForm()) return;

    const [firstName, ...lastNameParts] = userData.name.split(" ");
    const newUserData = {
      firstName,
      lastName: lastNameParts.join(" "),
      email: userData.email,
      phoneNumber: userData.phone,
      cpouserRoleId: userData.cpouserRoleId,
    };
    try {
      await addCPOUser(newUserData);
      message.success("User added successfully.");
      getUsers();
      setAddUserModalOpen(false);
      resetUserData();
      setErrors({});
    } catch (error) {
      console.error("Error adding user:", error);
      const errorMessage = error?.response?.data?.message || "An error occurred while adding the user.";
      message.error(errorMessage);
    }
  };

  const handleUpdateUser = async () => {
    if (!validateForm()) return;

    const [firstName, ...lastNameParts] = userData.name.split(" ");
    const updatedUserData = {
      firstName,
      lastName: lastNameParts.join(" "),
      email: userData.email,
      phoneNumber: userData.phone,
      cpouserRoleId: userData.cpouserRoleId,
    };
    try {
      await updateCPOUser(selectedUser.id, updatedUserData);
      message.success("User updated successfully.");
      getUsers();
      setEditUserModalOpen(false);
      resetUserData();
      setErrors({});
    } catch (error) {
      console.error("Error updating user:", error);
      message.error(error?.response?.data?.message || "An error occurred while adding the user.");
    }
  };

  const handleDeleteUser = async () => {
    setLoading(true);

    try {
      await deleteCPOUser(selectedUserId);
      message.success("User deleted successfully.");

    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("Failed to delete user.");
    }
    finally {
      getUsers();
      setOpenDeleteDialog(false);
      setSelectedUserId(null);
      setLoading(false);
    }
  };

  const handleOpenDeleteDialog = (userId, roleId) => {
    setOpenDeleteDialog(true);
    setSelectedUserId(userId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  }

  const handleAddOpenModal = () => {
    setAddUserModalOpen(true);
    setUserData({ name: "", email: "", phone: "", cpouserRoleId: "" });
    setErrors({});
  };

  const resetUserData = () => {
    setUserData({ name: "", email: "", phone: "", cpouserRoleId: "" });
    setSelectedUser(null);
    setErrors({});
  };

  return (
    <Typography component={"div"} overflow={0}>
      <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"} sx={{ marginBottom: "10px" }}>
        User Management
      </Typography>
      <Typography display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            id="s"
            variant="outlined"
            hiddenLabel
            placeholder="search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
          <Typography component="span" sx={{ margin: "10px 0" }}>
            <Typography component="b">Portal Owner</Typography>: {userDetails.firstName + " " + userDetails.lastName}
          </Typography>
        </Typography>
        <Typography>
          <ButtonComponent
            label={
              <>
                <Add /> <Typography component="span">ADD User</Typography>
              </>
            }
            width="200px"
            height="40px"
            onClick={handleAddOpenModal}
          />
        </Typography>
      </Typography>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="55vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer
            sx={{
              maxHeight: "55vh",
              overflow: "auto",
              borderRadius: "8px 8px 0 0",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              border: "1px solid #e0e0e0",
              backgroundColor: "#ffffff",
            }}
          >
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 99,
                  "& th": {
                    border: "1px solid #e0e0e0",
                    background: "#F5F3F3",
                    fontWeight: "500",
                    color: "#333",
                  },
                  boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <TableRow>
                  <TableCell align="center">User Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Mobile No.</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td": {
                    border: "1px solid #e0e0e0",
                    color: "#555",
                  },
                  "& tr:nth-of-type(odd)": {
                    backgroundColor: "#fafafa",
                  },
                  "& tr:hover": {
                    backgroundColor: "#f1f1f1",
                  },
                }}
              >
                {users
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow key={user.id}>
                      <TableCell align="center">
                        {user.firstName} {user.lastName} {user.isOwner ? "(Portal Owner)" : ""}
                      </TableCell>
                      <TableCell align="center">{user.email}</TableCell>
                      <TableCell align="center">{user.phoneNumber}</TableCell>
                      <TableCell align="center">{user.roleName}</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => openEditUserModal(user)}>
                          <Edit />
                        </IconButton>
                        {user.roleName !== "CPO Admin" && (
                          <IconButton
                            onClick={() => handleOpenDeleteDialog(user.id, user.roleName)}
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={users.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {/* Add User Modal */}
      <ModalComponent
        open={addUserModalOpen}
        headerText="Add User"
        handleClose={() => setAddUserModalOpen(false)}
        handleSubmit={handleAddUser}
        submitButtonLabel={"Add"}
      >
        <TextField
          label="Name"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Phone"
          value={userData.phone}
          onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <TextField
          select
          label="Role"
          value={userData.cpouserRoleId}
          onChange={(e) => setUserData({ ...userData, cpouserRoleId: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.cpouserRoleId}
          helperText={errors.cpouserRoleId}

        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </ModalComponent>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteUser} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Modal */}
      <ModalComponent
        open={editUserModalOpen}
        headerText="Edit User"
        handleClose={() => setEditUserModalOpen(false)}
        submitButtonLabel="Update"
        handleSubmit={handleUpdateUser}
      >
        <TextField
          label="Name"
          value={userData.name}
          onChange={(e) => setUserData({ ...userData, name: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email"
          value={userData.email}
          onChange={(e) => setUserData({ ...userData, email: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Phone"
          value={userData.phone}
          onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.phone}
          helperText={errors.phone}
        />
        <TextField
          select
          label="Role"
          value={userData.cpouserRoleId}
          onChange={(e) => setUserData({ ...userData, cpouserRoleId: e.target.value })}
          fullWidth
          margin="normal"
          error={!!errors.cpouserRoleId}
          helperText={errors.cpouserRoleId}
        >
          {roles.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </TextField>
      </ModalComponent>
    </ Typography>
  );
}

export default UserManagementContainer;
