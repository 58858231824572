import React from "react";

function TotalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 35 36"
    >
      <g id="Group_545" data-name="Group 545" transform="translate(-776 -464)">
        <g
          id="Path_4793"
          data-name="Path 4793"
          transform="translate(776 464)"
          fill="none"
        >
          <path
            d="M3,0H7a3,3,0,0,1,3,3V14a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 14 C 2 14.55140018463135 2.448599815368652 15 3 15 L 7 15 C 7.551400184631348 15 8 14.55140018463135 8 14 L 8 3 C 8 2.448599815368652 7.551400184631348 2 7 2 L 3 2 M 3 0 L 7 0 C 8.65684986114502 0 10 1.34315013885498 10 3 L 10 14 C 10 15.65684986114502 8.65684986114502 17 7 17 L 3 17 C 1.34315013885498 17 0 15.65684986114502 0 14 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_4788"
          data-name="Path 4788"
          transform="translate(776 484)"
          fill="#fff"
        >
          <path
            d="M 7 15 L 3 15 C 1.897199988365173 15 1 14.1028003692627 1 13 L 1 3 C 1 1.897199988365173 1.897199988365173 1 3 1 L 7 1 C 8.102800369262695 1 9 1.897199988365173 9 3 L 9 13 C 9 14.1028003692627 8.102800369262695 15 7 15 Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 13 C 2 13.55140018463135 2.448599815368652 14 3 14 L 7 14 C 7.551400184631348 14 8 13.55140018463135 8 13 L 8 3 C 8 2.448599815368652 7.551400184631348 2 7 2 L 3 2 M 3 0 L 7 0 C 8.65684986114502 0 10 1.34315013885498 10 3 L 10 13 C 10 14.65684986114502 8.65684986114502 16 7 16 L 3 16 C 1.34315013885498 16 0 14.65684986114502 0 13 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_4792"
          data-name="Path 4792"
          transform="translate(789 464)"
          fill="#fff"
        >
          <path
            d="M 6 16 L 3 16 C 1.897199988365173 16 1 15.1028003692627 1 14 L 1 3 C 1 1.897199988365173 1.897199988365173 1 3 1 L 6 1 C 7.102799892425537 1 8 1.897199988365173 8 3 L 8 14 C 8 15.1028003692627 7.102799892425537 16 6 16 Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 14 C 2 14.55140018463135 2.448599815368652 15 3 15 L 6 15 C 6.551400184631348 15 7 14.55140018463135 7 14 L 7 3 C 7 2.448599815368652 6.551400184631348 2 6 2 L 3 2 M 3 0 L 6 0 C 7.65684986114502 0 9 1.34315013885498 9 3 L 9 14 C 9 15.65684986114502 7.65684986114502 17 6 17 L 3 17 C 1.34315013885498 17 0 15.65684986114502 0 14 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_4789"
          data-name="Path 4789"
          transform="translate(789 484)"
          fill="none"
        >
          <path
            d="M3,0H6A3,3,0,0,1,9,3V13a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 13 C 2 13.55140018463135 2.448599815368652 14 3 14 L 6 14 C 6.551400184631348 14 7 13.55140018463135 7 13 L 7 3 C 7 2.448599815368652 6.551400184631348 2 6 2 L 3 2 M 3 0 L 6 0 C 7.65684986114502 0 9 1.34315013885498 9 3 L 9 13 C 9 14.65684986114502 7.65684986114502 16 6 16 L 3 16 C 1.34315013885498 16 0 14.65684986114502 0 13 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_4791"
          data-name="Path 4791"
          transform="translate(801 464)"
          fill="none"
        >
          <path
            d="M3,0H7a3,3,0,0,1,3,3V14a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 14 C 2 14.55140018463135 2.448599815368652 15 3 15 L 7 15 C 7.551400184631348 15 8 14.55140018463135 8 14 L 8 3 C 8 2.448599815368652 7.551400184631348 2 7 2 L 3 2 M 3 0 L 7 0 C 8.65684986114502 0 10 1.34315013885498 10 3 L 10 14 C 10 15.65684986114502 8.65684986114502 17 7 17 L 3 17 C 1.34315013885498 17 0 15.65684986114502 0 14 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_4790"
          data-name="Path 4790"
          transform="translate(801 484)"
          fill="#fff"
        >
          <path
            d="M 7 15 L 3 15 C 1.897199988365173 15 1 14.1028003692627 1 13 L 1 3 C 1 1.897199988365173 1.897199988365173 1 3 1 L 7 1 C 8.102800369262695 1 9 1.897199988365173 9 3 L 9 13 C 9 14.1028003692627 8.102800369262695 15 7 15 Z"
            stroke="none"
          />
          <path
            d="M 3 2 C 2.448599815368652 2 2 2.448599815368652 2 3 L 2 13 C 2 13.55140018463135 2.448599815368652 14 3 14 L 7 14 C 7.551400184631348 14 8 13.55140018463135 8 13 L 8 3 C 8 2.448599815368652 7.551400184631348 2 7 2 L 3 2 M 3 0 L 7 0 C 8.65684986114502 0 10 1.34315013885498 10 3 L 10 13 C 10 14.65684986114502 8.65684986114502 16 7 16 L 3 16 C 1.34315013885498 16 0 14.65684986114502 0 13 L 0 3 C 0 1.34315013885498 1.34315013885498 0 3 0 Z"
            stroke="none"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default TotalIcon;
