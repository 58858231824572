import React from "react";
import ModalComponent from "../atoms/ModalComponent";
import { Typography } from "@mui/material";

function TermsModal({ termModalOpen, setTermModalOpen }) {
  return (
    <ModalComponent
      open={termModalOpen}
      handleClose={() => setTermModalOpen(false)}
      showSubmitButton={false}
      headerText={"Terms & Conditions"}
    >
      <Typography variant="h5" gutterBottom>
        Terms and Conditions - General Site Usage
      </Typography>

      <Typography paragraph>
        Last Revised: December 16, 2013
      </Typography>

      <Typography paragraph>
        Welcome to www.lorem-ipsum.info. This site is provided as a service to our visitors and may be used for informational purposes only. Because the Terms and Conditions contain legal obligations, please read them carefully.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. YOUR AGREEMENT
      </Typography>

      <Typography paragraph>
        By using this Site, you agree to be bound by, and to comply with, these Terms and Conditions. If you do not agree to these Terms and Conditions, please do not use this site.
      </Typography>

      <Typography paragraph>
        PLEASE NOTE: We reserve the right, at our sole discretion, to change, modify or otherwise alter these Terms and Conditions at any time. Unless otherwise indicated, amendments will become effective immediately. Please review these Terms and Conditions periodically. Your continued use of the Site following the posting of changes and/or modifications will constitute your acceptance of the revised Terms and Conditions and the reasonableness of these standards for notice of changes. For your information, this page was last updated as of the date at the top of these terms and conditions.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. PRIVACY
      </Typography>

      <Typography paragraph>
        Please review our Privacy Policy, which also governs your visit to this Site, to understand our practices.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. LINKED SITES
      </Typography>

      <Typography paragraph>
        This Site may contain links to other independent third-party websites ("Linked Sites”). These Linked Sites are provided solely as a convenience to our visitors. Such Linked Sites are not under our control, and we are not responsible for and do not endorse the content of such Linked Sites, including any information or materials contained on such Linked Sites. You will need to make your own independent judgment regarding your interaction with these Linked Sites.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. FORWARD LOOKING STATEMENTS
      </Typography>

      <Typography paragraph>
        All materials reproduced on this site speak as of the original date of publication or filing. The fact that a document is available on this site does not mean that the information contained in such document has not been modified or superseded by events or by a subsequent document or filing. We have no duty or policy to update any information or statements contained on this site and, therefore, such information or statements should not be relied upon as being current as of the date you access this site.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY
      </Typography>

      <Typography paragraph>
        A. THIS SITE MAY CONTAIN INACCURACIES AND TYPOGRAPHICAL ERRORS. WE DO NOT WARRANT THE ACCURACY OR COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE, OPINION, STATEMENT, OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
      </Typography>

      <ul>
        <li>Your use of the Site, including any reliance on any opinion, advice, statement, memorandum, or information contained herein, shall be at your sole risk;</li>
        <li>The Site is provided on an "as is" and "as available" basis;</li>
        <li>We disclaim all warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, workmanlike effort, title, and non-infringement;</li>
        <li>We make no warranty with respect to the results that may be obtained from this Site, the products or services advertised or offered, or merchants involved;</li>
        <li>Any material downloaded or otherwise obtained through the use of the Site is done at your own discretion and risk; and</li>
        <li>You will be solely responsible for any damage to your computer system or for any loss of data that results from the download of any such material.</li>
      </ul>

      <Typography paragraph>
        B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. EXCLUSIONS AND LIMITATIONS
      </Typography>

      <Typography paragraph>
        Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, our liability in such jurisdictions shall be limited to the maximum extent permitted by law.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. OUR PROPRIETARY RIGHTS
      </Typography>

      <Typography paragraph>
        This Site and all its Contents are intended solely for personal, non-commercial use. Except as expressly provided, nothing within the Site shall be construed as conferring any license under our or any third party's intellectual property rights, whether by estoppel, implication, waiver, or otherwise. Without limiting the generality of the foregoing, you acknowledge and agree that all content available through and used to operate the Site and its services is protected by copyright, trademark, patent, or other proprietary rights.
      </Typography>

      <ul>
        <li>Do not modify, alter, or deface any of our trademarks or other intellectual property.</li>
        <li>Do not hold yourself out as affiliated with or endorsed by us unless expressly permitted.</li>
        <li>Do not use our content for purposes other than those authorized.</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        8. INDEMNITY
      </Typography>

      <Typography paragraph>
        By using the Site, you agree to indemnify us and hold us harmless from any and all claims and expenses, including attorney's fees, arising from your use of the Site, the Products and Services, or your submission of ideas or materials.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. COPYRIGHT AND TRADEMARK NOTICE
      </Typography>

      <Typography paragraph>
        Except for the dummy copy we generate, which is free to use, all other text is copyrighted. Generator.lorem-ipsum.info © 2013, all rights reserved.
      </Typography>

      <Typography variant="h6" gutterBottom>
        10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS
      </Typography>

      <Typography paragraph>
        We will respond to intellectual property infringement claims expeditiously. Notices of claimed infringement should be directed to: generator.lorem-ipsum.info, 126 Electricov St., Kiev, Ukraine, contact@lorem-ipsum.info.
      </Typography>

      <Typography variant="h6" gutterBottom>
        11. PLACE OF PERFORMANCE
      </Typography>

      <Typography paragraph>
        This Site is controlled and operated from Kiev, Ukraine. We make no representation that the materials on this Site are appropriate for use in other locations. Accessing this Site from locations where its content is illegal is prohibited.
      </Typography>

      <Typography variant="h6" gutterBottom>
        12. GENERAL
      </Typography>

      <Typography paragraph>
        A. If any provision of these Terms and Conditions is held invalid, the remaining provisions shall remain enforceable. Our failure to act with respect to a breach does not waive our right to act with respect to subsequent breaches.
      </Typography>

      <Typography paragraph>
        B. No joint venture, partnership, or employment relationship is created by these Terms. Our compliance with legal processes is not derogated by these terms.
      </Typography>

    </ModalComponent>
  );
}

export default TermsModal;
