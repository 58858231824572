import React, { useState } from 'react';
import { Button, CircularProgress } from '@mui/material';

function ButtonComponent({ label, width, height, onClick }) {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await onClick(); // Call the action passed as a prop
    } finally {
      setLoading(false); // Ensure loading state resets after action completes
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      sx={{
        borderRadius: "14px 12px 14px 14px",
        color: "white",
        textTransform: "capitalize",
        height: height ?? "38px",
        width: width ?? "118px",
        fontSize: "15px",
        marginLeft: "20px",
        position: 'relative', // Ensure proper positioning for the loading spinner
      }}
    >
      {loading ? (
        <CircularProgress size={24} sx={{ color: 'white' }} /> // Show loading spinner
      ) : (
        label // Show label when not loading
      )}
    </Button>
  );
}

export default ButtonComponent;
