import React from "react";
import styles from "./index.module.scss";
import { Grid, Typography } from "@mui/material";

export const Card = ({ title, children, ...props }) => {
  return (
    <Grid className={styles.card} xs={12} md={5.9} sm={12} {...props}>
      <div>{children}</div>
    </Grid>
  );
};
