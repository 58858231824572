import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
    Typography,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
  } from "@mui/material";

import { LineChart } from "@mui/x-charts/LineChart";

const lineChartsParams = {
  series: [
    {
      id: "series-1",
      data: [3, 4, 1, 6, 5],
      label: "A",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
    {
      id: "series-2",
      data: [4, 3, 1, 5, 8],
      label: "B",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
    {
      id: "series-3",
      data: [4, 2, 5, 4, 1],
      label: "C",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
  ],
  xAxis: [{ data: [0, 3, 6, 9, 12], scaleType: "linear", id: "axis1" }],
  height: 400,
};

export default function LineClickNoSnap() {
  const [itemData, setItemData] = React.useState();
  const [axisData, setAxisData] = React.useState();
  const [siteHosts, setSiteHosts] = React.useState([
    {
      sr_no: 1,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    // Add more initial data as needed
  ]);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: "100%" }}
      marginTop={"1rem"}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontSize={"22px"} component={"h1"}>
          Real Time Load
        </Typography>
        <LineChart
          {...lineChartsParams}
          onAreaClick={(event, d) => setItemData(d)}
          onMarkClick={(event, d) => setItemData(d)}
          onLineClick={(event, d) => setItemData(d)}
          onAxisClick={(event, d) => setAxisData(d)}
          colors={["#E1ECF8AB", "#98C0E9", "#8AADD1"]}
        />

        <Typography component={"div"} sx={{ mt: "1rem" }}>
          <Table
            sx={{ minWidth: 650, textTransform: "capitalize" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                "& td, & th": { border: "1px solid #4C4E641F !important" },
                background: "#F5F3F3 0% 0% no-repeat padding-box",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr. No</TableCell>
                <TableCell align="left">Network</TableCell>
                <TableCell align="left">Protocol</TableCell>
                <TableCell align="left">Source</TableCell>
                <TableCell align="left">Destination</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {siteHosts.map((row) => (
                <TableRow
                  key={row.sr_no}
                  sx={{
                    "& td, & th": {
                      border: "1px solid #4C4E641F !important",
                    },
                  }}
                >
                  <TableCell align="center">{row.sr_no}</TableCell>
                  <TableCell align="left">{row.site_host}</TableCell>
                  <TableCell align="left">{row.no_of_chargers}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Typography>
      </Box>
    </Stack>
  );
}
