import React from "react";

function AvailableIcon({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.483"
      height="23.168"
      viewBox="0 0 42.483 33.168"
    >
      <g id="Layer_3" data-name="Layer 3" transform="translate(-2.5 -5.498)">
        <path
          id="Subtraction_5"
          data-name="Subtraction 5"
          d="M-523.782-315.759a1.831,1.831,0,0,1-1.829-1.83,1.831,1.831,0,0,1,1.829-1.83,1.832,1.832,0,0,1,1.83,1.83A1.832,1.832,0,0,1-523.782-315.759Z"
          transform="translate(554.511 345.883)"
          fill={color ?? "#fff"}
        />
        <path
          id="Subtraction_6"
          data-name="Subtraction 6"
          d="M-518.346-317.312h-6.212a1.054,1.054,0,0,1-1.053-1.053,1.054,1.054,0,0,1,1.053-1.053h6.212a1.054,1.054,0,0,1,1.053,1.053A1.054,1.054,0,0,1-518.346-317.312Z"
          transform="translate(562.276 346.66)"
          fill={color ?? "#fff"}
        />
        <path
          id="Subtraction_4"
          data-name="Subtraction 4"
          d="M-516.794-292.465h-4.658a2.609,2.609,0,0,1-2.606-2.605v-2.317l-.125-.141a5.711,5.711,0,0,1-1.427-3.754v-5.959a5.717,5.717,0,0,1,3.9-5.371l1.047-.362,1.644-3.288a5.683,5.683,0,0,1,5.1-3.156h9.55a1.054,1.054,0,0,1,1.053,1.053,1.054,1.054,0,0,1-1.053,1.053h-9.553a3.587,3.587,0,0,0-3.208,2l-1.482,2.946,2.248,2.266h12a1.054,1.054,0,0,1,1.053,1.053,1.054,1.054,0,0,1-1.053,1.053h-11.785a2.591,2.591,0,0,1-1.839-.77l-2.2-2.2-.851.308a3.6,3.6,0,0,0-2.463,3.407v5.967a3.605,3.605,0,0,0,1.205,2.666,1.054,1.054,0,0,1,.347.778v3.263h5.659v-2.053a1.054,1.054,0,0,1,1.053-1.053h10.87a1.054,1.054,0,0,1,1.053,1.053,1.054,1.054,0,0,1-1.053,1.053h-9.817v.5A2.609,2.609,0,0,1-516.794-292.465Z"
          transform="translate(548.3 331.13)"
          fill={color ?? "#fff"}
        />
        <path
          id="Subtraction_7"
          data-name="Subtraction 7"
          d="M-521.577-308.114h-.011a.956.956,0,0,1-.551-.174l-.023-.015a1.06,1.06,0,0,1-.471-.656,1.063,1.063,0,0,1,.128-.788l2.087-3.025h-4.231a1.053,1.053,0,0,1-.808-.577l-.015-.028a1.061,1.061,0,0,1-.007-1.04l3.083-4.623a1.052,1.052,0,0,1,.808-.377,1.049,1.049,0,0,1,.581.176,1.053,1.053,0,0,1,.358,1.354l-2.076,3.01h4.256a1.052,1.052,0,0,1,.926.582,1.054,1.054,0,0,1-.051,1.049l-3.105,4.659A1.055,1.055,0,0,1-521.577-308.114Z"
          transform="translate(535.995 332.803)"
          fill={color ?? "#fff"}
        />
        <path
          id="Subtraction_8"
          data-name="Subtraction 8"
          d="M-513.688-286.25a1.049,1.049,0,0,1-.682-.251c-1.151-1-11.241-10.061-11.241-20.991a11.928,11.928,0,0,1,9.733-11.722,12.024,12.024,0,0,1,2.2-.2,12,12,0,0,1,11.105,7.6,1.061,1.061,0,0,1-.632,1.273,1.062,1.062,0,0,1-.38.071,1.061,1.061,0,0,1-.944-.586,9.767,9.767,0,0,0-3.627-4.569,9.777,9.777,0,0,0-5.523-1.706,9.89,9.89,0,0,0-1.811.168,9.815,9.815,0,0,0-8.012,9.675,21.6,21.6,0,0,0,3.763,11.394,39.985,39.985,0,0,0,5.705,7.025l.35.341.349-.342a38.6,38.6,0,0,0,2.643-2.864,1.055,1.055,0,0,1,.813-.381,1.041,1.041,0,0,1,.673.243,1.051,1.051,0,0,1,.376.715,1.05,1.05,0,0,1-.241.773,36.521,36.521,0,0,1-3.933,4.087A1.054,1.054,0,0,1-513.688-286.25Z"
          transform="translate(528.111 324.916)"
          fill={color ?? "#fff"}
        />
      </g>
    </svg>
  );
}

export default AvailableIcon;
