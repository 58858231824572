import React, { useState, useEffect } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { message } from "antd";
import styles from "./uploadStyles.module.css";

const FileUpload = ({ callBack, value, setIsFileUploading }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile.name.includes("tar.gz")) {
      setIsFileUploading(true);
      setFile(selectedFile);
    } else {
      message.error("File Not Valid");
    }
  };

  // Update file state when the value prop changes
  useEffect(() => {
    if (value !== "" && value !== undefined) {
      setFile({ name: value });
    } else {
      setFile(null); // Clear file state if value is empty or undefined
    }
  }, [value]);

  // Call the callback function with the file state
  useEffect(() => {
    callBack(file);
  }, [file]);

  return (
    <form className={styles["file-upload-container"]}>
      {file === null ? (
        <label htmlFor="file-upload">
          <FileUploadOutlinedIcon />
        </label>
      ) : (
        <label title={file.name}>
          <p className={styles["file-name"]}>{file.name}</p>
        </label>
      )}
      <input
        id="file-upload"
        type="file"
        accept=".tar.gz"
        hidden
        onChange={(event) => {
          handleFileChange(event);
          event.target.value = null;
        }}
      />
      {file !== null && (
        <span>
          <IconButton
            className={styles["delete-button"]}
            onClick={() => setFile(null)}
            title="Clear"
          >
            <DeleteIcon />
          </IconButton>
        </span>
      )}
    </form>
  );
};

export default FileUpload;