import React from "react";

function SitehostsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 14.012 23.354"
    >
      <path
        id="distance_FILL1_wght400_GRAD0_opsz24"
        d="M247.006-856.646a11.3,11.3,0,0,1-5.05-.978Q240-858.6,240-860.149a2.323,2.323,0,0,1,.847-1.781,5.983,5.983,0,0,1,2.335-1.2l.671,2.219a4.741,4.741,0,0,0-.9.365,1.941,1.941,0,0,0-.555.394,3.456,3.456,0,0,0,1.752.817,11.524,11.524,0,0,0,2.861.35,11.7,11.7,0,0,0,2.876-.35,3.477,3.477,0,0,0,1.766-.817,1.941,1.941,0,0,0-.555-.394,4.742,4.742,0,0,0-.9-.365l.671-2.219a5.847,5.847,0,0,1,2.321,1.2,2.339,2.339,0,0,1,.832,1.781q0,1.547-1.956,2.525A11.3,11.3,0,0,1,247.006-856.646Zm0-3.5a.973.973,0,0,1-.584-.19,1.059,1.059,0,0,1-.38-.511,12.3,12.3,0,0,0-1.693-3.474q-1.022-1.4-1.985-2.686a18.84,18.84,0,0,1-1.62-2.657,7.525,7.525,0,0,1-.686-3.386,6.71,6.71,0,0,1,2.014-4.934A6.71,6.71,0,0,1,247.006-880a6.71,6.71,0,0,1,4.934,2.014,6.71,6.71,0,0,1,2.014,4.934,7.664,7.664,0,0,1-.671,3.386,16.553,16.553,0,0,1-1.635,2.657q-.934,1.284-1.97,2.686a12.051,12.051,0,0,0-1.708,3.474,1.058,1.058,0,0,1-.379.511A.973.973,0,0,1,247.006-860.149Zm0-10.422a2.389,2.389,0,0,0,1.752-.73,2.389,2.389,0,0,0,.73-1.752,2.389,2.389,0,0,0-.73-1.752,2.388,2.388,0,0,0-1.752-.73,2.388,2.388,0,0,0-1.752.73,2.389,2.389,0,0,0-.73,1.752,2.389,2.389,0,0,0,.73,1.752A2.389,2.389,0,0,0,247.006-870.571Z"
        transform="translate(-240 880)"
        fill="#fff"
      />
    </svg>
  );
}

export default SitehostsIcon;
