import React, { useState, useEffect, useRef } from 'react';
import { Stepper, Step, StepLabel, StepContent, Button, Typography, Box, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import { message } from 'antd'; // Ant Design for notifications
import { getData } from 'country-list';
import Barcode from 'react-barcode'; // Import barcode library
import { QRCodeSVG } from 'qrcode.react'; // Import QR code library
import { useReactToPrint } from 'react-to-print'; // Import print library
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // For copy icon
import {
  fetchConnectorTypeAPI,
  fetchUserListAPI,
  generateSerialNumberAPI,
  registerChargerAPI,
  assignChargerAPI,
  activateChargerAPI,
  fetchChargerList,
} from '../../redux/apis/chargers';
import { fetchEvseStationsAPI } from '../../redux/apis/evseStations';
import { useNavigate } from 'react-router';

const steps = [
  { label: 'Step 1: Register Charger', description: 'Enter the details to register a new charger.' },
  { label: 'Step 2: Assign EVSE', description: 'Assign an EVSE to the charger.' },
  { label: 'Step 3: Activate', description: 'Activate the charger.' },
  { label: 'Charger Summary', description: 'Here are the details of your registered charger.' }, // New step for summary
];

const QuickStartGuide = (props) => {
  const countryData = getData();
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [loading, setLoading] = useState(false); // Spinner state
  const [newChargerData, setNewChargerData] = useState({
    serialNumber: '',
    deviceName: '',
    connectorTypeId: '',
    evseOwnerId: '',
    chargerModel: '',
    country: '',
  });
  const navigate = useNavigate();
  const [connectorTypesData, setConnectorTypesData] = useState([]);
  const [evseOwnersData, setEvseOwnersData] = useState([]);
  const [siteHostAdmins, setSiteHostAdmins] = useState([]);
  const [evseStations, setEvseStations] = useState([]);
  const [selectedSiteHostAdminId, setSelectedSiteHostAdminId] = useState(null);
  const [selectedEvseStationId, setSelectedEvseStationId] = useState(null);
  const [chargerDetails, setChargerDetails] = useState(null);
  const barcodeRef = useRef(null);
  const qrCodeRef = useRef(null);
  const [activationCode, setActivationCode] = useState('');

  useEffect(() => {
    fetchConnectorTypes();
    fetchEvseOwners();
  }, []);

  useEffect(() => {
    if (activeStep === 2 && newChargerData.serialNumber) {
      fetchChargerDetails();
    }
  }, [activeStep, newChargerData.serialNumber]);

  const fetchConnectorTypes = async () => {
    try {
      const response = await fetchConnectorTypeAPI();
      setConnectorTypesData(response.list || []);
    } catch (error) {
      console.error('Error fetching connector types:', error);
    }
  };

  const fetchEvseOwners = async () => {
    try {
      const response = await fetchUserListAPI({
        limit: 500,
        filter: `{ "userRole": "evse_owner" }`,
      });
      setEvseOwnersData(response?.list);
    } catch (error) {
      console.error('Error fetching EVSE owners:', error);
    }
  };


  const fetchEvseStations = async (siteHostAdminId) => {
    try {
      const response = await fetchEvseStationsAPI({
        filter: { siteHostAdminId },
        limit: 500,
      });
      setEvseStations(response.list || []);
    } catch (error) {
      console.error('Error fetching EVSE stations:', error);
    }
  };

  const fetchChargerDetails = async () => {
    try {
      const response = await fetchChargerList({
        filter: { serialNumber: newChargerData.serialNumber.replace(/-/g, '') },
      });
      setChargerDetails(response.list[0] || null);
    } catch (error) {
      console.error('Error fetching charger details:', error);
    }
  };

  const generateSerialNumber = async () => {
    try {
      const response = await generateSerialNumberAPI();
      const serialNumber = response.serialNumber;
      const formattedSerialNumber = formatSerialNumber(serialNumber);
      setNewChargerData({ ...newChargerData, serialNumber: formattedSerialNumber });
    } catch (error) {
      console.error('Error generating serial number:', error);
    }
  };

  const formatSerialNumber = (serialNumber) => {
    return `${serialNumber.slice(0, 4)}-${serialNumber.slice(4, 8)}-${serialNumber.slice(8, 12)}-${serialNumber.slice(12, 16)}`;
  };

  const handleNewChargerInputChange = (event) => {
    const { name, value } = event.target;
    setNewChargerData({ ...newChargerData, [name]: value });
  };

  const handleAddCharger = async () => {
    setLoading(true);
    try {
      const cleanedData = {
        ...newChargerData,
        serialNumber: newChargerData.serialNumber.replace(/-/g, ''),
      };
      if (!cleanedData.evseOwnerId) {
        delete cleanedData.evseOwnerId;
      }
      await registerChargerAPI(cleanedData);
      message.success('Charger Registered Successfully');
      setCompletedSteps([...completedSteps, activeStep]);
      handleNext();
    } catch (error) {
      console.error('Error registering charger:', error);
      message.error(error?.message || 'Failed to register charger');
    } finally {
      setLoading(false);
    }
  };

  const handleAssignCharger = async () => {
    setLoading(true);
    try {
      await assignChargerAPI({
        serialNumber: newChargerData.serialNumber.replace(/-/g, ''),
        evseStationId: selectedEvseStationId,
      });
      message.success('Charger Assigned Successfully');
      setCompletedSteps([...completedSteps, activeStep]);
      handleNext();
    } catch (error) {
      console.error('Error assigning charger:', error);
      message.error(error?.message || 'Failed to assign charger');
    } finally {
      setLoading(false);
    }
  };

  const handleActivateCharger = async () => {
    setLoading(true);
    try {
      await activateChargerAPI({
        serialNumber: newChargerData.serialNumber.replace(/-/g, ''),
        activationCode,
        lat: chargerDetails?.evseStationLat,
        lng: chargerDetails?.evseStationLng,
      });
      message.success('Charger Activated Successfully');
      setCompletedSteps([...completedSteps, activeStep]);
      handleNext();
    } catch (error) {
      console.error('Error activating charger:', error);
      message.error(error?.message || 'Failed to activate charger');
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const handleFinish = () => {
    message.success('Charger Setup Completed!');
    if (props.onClose) {
      props.onClose(); // Call onClose to close the modal
    }
  };
  const isLastStep = activeStep === steps.length - 1;

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success('Copied to clipboard');
  };

  const handleStepAction = async () => {
    if (activeStep === 0) {
      await handleAddCharger();
    } else if (activeStep === 1) {
      await handleAssignCharger();
    } else if (activeStep === 2) {
      await handleActivateCharger();
    }
  };

  const handlePrintBarcode = useReactToPrint({
    content: () => barcodeRef.current,
  });

  const handlePrintQRCode = useReactToPrint({
    content: () => qrCodeRef.current,
  });

  const handleAddNextCharger = () => {
    // Close the current modal
    props.onClose();

    // Reset the steps to the beginning (assuming setActiveStep is used to control steps)
    setTimeout(() => {
      setActiveStep(0); // Reset to step 1

      // Reopen the modal using the function passed from the parent
      props.openQuickStartModal();
    }, 300); // Small delay for closing animation
  };


  return (
    <Box style={{ padding: '20px', display: 'flex', alignItems: 'flex-start' }}>
      {/* Vertical Stepper */}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} completed={completedSteps.includes(index)}>
            <StepLabel sx={{"& .MuiStepIcon-text": {fill: "#FFF"}}}>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {/* Form Content */}
      <Box style={{ marginLeft: '40px', flex: 1 }}>
        {loading ? (
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>


            {activeStep === 0 && (
              <Box>
                <TextField
                  id="serialNumber"
                  name="serialNumber"
                  label="Serial Number"
                  variant="outlined"
                  fullWidth
                  value={newChargerData.serialNumber}
                  onChange={handleNewChargerInputChange}
                  style={{ marginBottom: 10 }}
                  disabled
                />
                <Button
                  onClick={generateSerialNumber}
                  style={{ marginBottom: 10, color: '#FFF', backgroundColor: '#007F3F' }}
                >
                  Generate Serial Number
                </Button>
                <TextField
                  id="deviceName"
                  name="deviceName"
                  label="Device Name"
                  variant="outlined"
                  fullWidth
                  value={newChargerData.deviceName}
                  onChange={handleNewChargerInputChange}
                  style={{ marginBottom: 10 }}
                />
                <TextField
                  id="chargerModel"
                  name="chargerModel"
                  label="Charger Model"
                  variant="outlined"
                  fullWidth
                  value={newChargerData.chargerModel}
                  onChange={handleNewChargerInputChange}
                  style={{ marginBottom: 10 }}
                />
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="connector-type-label">Connector Type</InputLabel>
                  <Select
                    labelId="connector-type-label"
                    id="connectorTypeId"
                    name="connectorTypeId"
                    value={newChargerData.connectorTypeId}
                    onChange={handleNewChargerInputChange}
                    label="Connector Type"
                    style={{ backgroundColor: '#FFF' }}
                  >
                    {connectorTypesData?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.displayText}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="evse-owner-label">EVSE Owner (Optional)</InputLabel>
                  <Select
                    labelId="evse-owner-label"
                    id="evseOwnerId"
                    name="evseOwnerId"
                    value={newChargerData.evseOwnerId}
                    onChange={handleNewChargerInputChange}
                    label="EVSE Owner"
                    style={{ backgroundColor: '#FFF' }}
                  >
                    {evseOwnersData.map((owner) => (
                      <MenuItem key={owner.id} value={owner.id}>
                        {owner.firstName} {owner.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={newChargerData.country}
                    onChange={handleNewChargerInputChange}
                    label="Country"
                    style={{ backgroundColor: '#FFF' }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {countryData.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.name} ({country.code})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {activeStep === 1 && (
              <Box>
                <TextField
                  id="serialNumber"
                  name="serialNumber"
                  label="Serial No."
                  variant="outlined"
                  fullWidth
                  value={newChargerData.serialNumber}
                  onChange={handleNewChargerInputChange}
                  style={{ marginBottom: 10 }}
                  disabled
                />
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="site-host-admin-label">Site Host Admin</InputLabel>
                  <Select
                    labelId="site-host-admin-label"
                    id="siteHostAdminId"
                    name="siteHostAdminId"
                    value={selectedSiteHostAdminId}
                    onChange={(event) => setSelectedSiteHostAdminId(event.target.value)}
                    label="Site Host Admin"
                    style={{ backgroundColor: '#FFF' }}
                  >
                    {siteHostAdmins.map((admin) => (
                      <MenuItem key={admin.id} value={admin.id}>
                        {admin.firstName} {admin.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ marginBottom: 10 }}>
                  <InputLabel id="evse-station-label">EVSE Station</InputLabel>
                  <Select
                    labelId="evse-station-label"
                    id="evseStationId"
                    name="evseStationId"
                    value={selectedEvseStationId}
                    onChange={(event) => setSelectedEvseStationId(event.target.value)}
                    label="EVSE Station"
                    style={{ backgroundColor: '#FFF' }}
                  >
                    {evseStations.map((station) => (
                      <MenuItem key={station.id} value={station.id}>
                        {station.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Box>
            )}

            {activeStep === 2 && (
              <Box>
                {chargerDetails && (
                  <Box>
                    <Typography>Charger Details</Typography>
                    <Typography variant="body1">Serial Number: {formatSerialNumber(chargerDetails.serialNumber)}</Typography>
                    <Typography variant="body1">ChargeBox Id: {chargerDetails.chargeBoxId}</Typography>
                    <Typography variant="body1">Device Name: {chargerDetails.deviceName}</Typography>
                    <Typography variant="body1">EVSE Station: {chargerDetails.evseStationAddress}</Typography>
                    <Typography variant="body1">Activated: {(chargerDetails.status === "activated") ? "Yes" : "No"}</Typography>
                    <Typography variant="body1">Activation Code: {chargerDetails.activationCode}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <Box style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography>Barcode</Typography>
                        <div ref={barcodeRef}>
                          <Barcode value={newChargerData.serialNumber.replace(/-/g, '')} />
                        </div>
                        <Button
                          variant="outlined"
                          onClick={handlePrintBarcode}
                          style={{ color: '#FFF', backgroundColor: '#007F3F' }}
                        >
                          Print Barcode
                        </Button>
                      </Box>
                      <Box style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography>QR Code</Typography>
                        <div ref={qrCodeRef}>
                          <QRCodeSVG value={newChargerData.serialNumber.replace(/-/g, '')} />
                        </div>
                        <Button
                          variant="outlined"
                          onClick={handlePrintQRCode}
                          style={{ marginTop: 10, color: '#FFF', backgroundColor: '#007F3F' }}
                        >
                          Print QR Code
                        </Button>
                      </Box>
                    </div>
                  </Box>

                )}
                <Box style={{ marginTop: 20 }}>
                  <Typography variant="body1">Serial Number: {newChargerData.serialNumber}</Typography>
                  <TextField
                    id="activationCode"
                    name="activationCode"
                    label="Activation Code"
                    variant="outlined"
                    fullWidth
                    value={activationCode}
                    onChange={(e) => setActivationCode(e.target.value)}
                    style={{ marginTop: 10 }}
                  />
                </Box>

              </Box>
            )}
            {activeStep === 3 && (
              <Box>
                {/* Charger Summary */}
                <Typography>EVSE Station: {chargerDetails?.evseStationName}</Typography>
                <Typography sx={{mt: 2}}>Serial Number: <Box
                  component="span"
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    backgroundColor: '#f5f5f5',
                    textOverflow: "auto",
                    p: 1,
                    mx: 1, // Horizontal margin to space out from surrounding text
                  }}
                >
                  {formatSerialNumber(chargerDetails?.serialNumber)}</Box>
                  <IconButton onClick={() => handleCopyToClipboard(newChargerData.serialNumber)}><ContentCopyIcon /></IconButton></Typography>
                <Typography sx={{mt: 2}}>Chargebox ID:  <Box
                  component="span"
                  sx={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    textOverflow: "auto",
                    backgroundColor: '#f5f5f5',
                    p: 1,
                    mx: 1, // Horizontal margin to space out from surrounding text
                  }}
                >
                  {chargerDetails?.chargeBoxId}</Box>
                  <IconButton onClick={() => handleCopyToClipboard(chargerDetails?.chargeboxId)}><ContentCopyIcon /></IconButton></Typography>

              </Box>
            )}

            {!isLastStep ? (
              <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                {activeStep !== 0 && <Button onClick={handleBack} style={{ marginRight: '10px', color: "#fff", backgroundColor: '#007F3F' }}>Back</Button>}
                <Button variant="contained" color="primary" onClick={handleStepAction} sx={{ color: "#FFFFFF", backgroundColor: "#007F3F" }}>
                  Next
                </Button>
              </Box>
            ) : (
              <Box style={{ display: 'flex', position: "absolute", bottom: 40, right: 44, gap: '10px' }}>
              <Button variant="contained" color="primary" onClick={() => {
                props.onClose(); // Close the modal first
                setTimeout(() => {
                  navigate('/chargers'); // Navigate to the chargers page after the modal closes
                }, 300); // Add a small delay for closing animation
              }} sx={{ backgroundColor: "#007f3f", color: "#FFF" }}>
                View Chargers
              </Button>
              <Button variant="contained" onClick={handleAddNextCharger} sx={{ backgroundColor: "#007f3f", color: "#FFF" }}>Add Next Charger</Button>
              <Button variant="contained" onClick={props.onClose} sx={{ backgroundColor: "#007f3f", color: "#FFF" }}>Finish</Button>
            </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default QuickStartGuide;
