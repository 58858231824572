import React from "react";

function AnalyticsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 21.019 21.019"
    >
      <path
        id="monitoring_FILL1_wght400_GRAD0_opsz24"
        d="M120-818.981v-2.335l2.335-2.335v4.671Zm4.671,0v-7.006l2.335-2.335v9.342Zm4.671,0v-9.342l2.335,2.365v6.977Zm4.671,0v-6.977l2.335-2.335v9.312Zm4.671,0v-11.677l2.335-2.335v14.013ZM120-825.024v-3.3l8.174-8.174,4.671,4.671L141.019-840v3.3l-8.174,8.174-4.671-4.671Z"
        transform="translate(-120 840)"
        fill="#fff"
      />
    </svg>
  );
}

export default AnalyticsIcon;
