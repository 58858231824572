import React, { useEffect, useState } from 'react';
import HomeIcon from '../icons/homeIcon';
import EsevIcon from '../icons/esevIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import SettingsIcon from '../icons/settingsIcon';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import { WhoAmI } from '../../redux/apis/user';
import { useSelector } from 'react-redux';

const SiteHostAdminSidebarItems = () => {
  // State for the cpo endpoint
  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";


  // Sidebar items
  const sidebarItems = [
    {
      name: "Home",
      icon: <HomeIcon />,
      route: `/csms/${cpoEndpoint}/home`,
    },
    {
      name: "EVSE Stations",
      icon: <EsevIcon />,
      route: `/csms/${cpoEndpoint}/evse-stations`,
    },
    {
      name: "Chargers",
      icon: <ChargersIcon />,
      route: `/csms/${cpoEndpoint}/chargers`,
    },
    {
      name: "Customers",
      icon: <SupportAgentIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "18px", height: "18px" }} />,
      route: `/csms/${cpoEndpoint}/customers`,
    },
    {
      name: "Sessions",
      icon: <ViewStreamIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "15px", height: "15px" }} />,
      route: `/csms/${cpoEndpoint}/sessions`,
    },
    {
      name: "Analytics",
      icon: <AnalyticsIcon />,
      route: `/csms/${cpoEndpoint}/analytics`,
    },
    {
      name: "Integration",
      icon: <IntegrationInstructionsIcon sx={{ color: "#fff", padding: "0", margin: "0", maxWidth: "15px", height: "15px" }} />,
      route: `/csms/${cpoEndpoint}/integration`,
    },
    {
      name: "Settings",
      icon: <SettingsIcon />,
      route: `/csms/${cpoEndpoint}/settings`,
    },
    {
      name: "Help and Support",
      icon: <SettingsIcon />,
      route: `/csms/${cpoEndpoint}/support`,
    },
  ];

  return sidebarItems; // Return the sidebar items
};

export default SiteHostAdminSidebarItems;
