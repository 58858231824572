import { combineReducers } from "redux";

import counter from "./slices/counter";
import filter from "./slices/filter/filterSlice";
import cpo from './slices/user/cpoSlice';


const rootReducer = combineReducers({
  counter,
  filter,
  cpo
});

export default rootReducer;
