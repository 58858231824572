import React from "react";
import { Typography } from "@mui/material";

function RoundedIconChip({ children, bgColor }) {
  return (
    <Typography
      component={"span"}
      color={bgColor}
      sx={{
        background: bgColor,
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "10px",
      }}
    >
      {children}
    </Typography>
  );
}

export default RoundedIconChip;
