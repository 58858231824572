import React from "react";
import ModalComponent from "../atoms/ModalComponent";
import { Typography } from "@mui/material";

function PrivacyPolicyModal({ privacyModalOpen, setPrivacyModalOpen }) {
  return (
    <ModalComponent
      handleClose={() => setPrivacyModalOpen(false)}
      open={privacyModalOpen}
      showSubmitButton={false}
      headerText={"Privacy Policy"}
    >
      <Typography variant="h5" gutterBottom>
        GDPR & Privacy Policy
      </Typography>

      <Typography paragraph>
        lipsum.com is committed to protecting your privacy online. This Privacy Policy endeavors to describe to you our practices regarding the personal information we collect from users on our website, located at lipsum.com (the “Site”), and the services offered through the Site. If you have any questions about our Privacy Policy, our collection practices, the processing of user information, or if you would like to report a security violation to us directly, please contact us at help@lipsum.com.
      </Typography>

      <Typography variant="h6" gutterBottom>
        What Data We Collect
      </Typography>

      <Typography paragraph>
        <strong>General Data:</strong> The use of our services will automatically create information that will be collected. For example, when you use our Services, your geographic location, how you use the Services, information about the type of device you use, your Open Device Identification Number, date/time stamps for your visit, your unique device identifier, your browser type, operating system, Internet Protocol (IP) address, and domain name are all collected. This information is generally used to help us deliver the most relevant information to you and administer and improve the Site.
      </Typography>

      <Typography paragraph>
        <strong>Log Files:</strong> As is true of most websites, we gather certain information automatically and store it in log files. This information includes IP addresses, browser type, Internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information to maintain and improve the performance of the Services.
      </Typography>

      <Typography paragraph>
        <strong>Analytics:</strong> We use analytics services (including, but not limited to, Google Analytics) to help analyze how users use the Site. Analytics services use Cookies to collect information such as how often users visit the Site, and we use the information to improve our Site and Services.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Cookies
      </Typography>

      <Typography paragraph>
        "Cookies" are small pieces of information (text files) that a website sends to your computer’s hard drive while you are viewing the website. These text files can be used by websites to make the user's experience more efficient. The law states that we can store these cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission.
      </Typography>

      <Typography paragraph>
        This site uses different types of cookies. Some cookies are placed by third-party services that appear on our pages. We and some third parties may use both session Cookies (which expire once you close your web browser) and persistent Cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Services and to market the Services or other products.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Use of Your Personal Information
      </Typography>

      <Typography paragraph>
        In general, personal information you submit to us is used either to respond to requests that you make, aid us in serving you better, or market our Services. We use your personal information in the following ways:
      </Typography>

      <ul>
        <li>Operate, maintain, and improve our site(s), products, and services;</li>
        <li>Respond to comments and questions and provide customer service;</li>
        <li>Link or combine user information with other personal information we get from third parties;</li>
        <li>Develop, improve, and deliver marketing and advertising for the Services;</li>
        <li>Provide and deliver products and services you request;</li>
        <li>Identify you as a user in our system.</li>
      </ul>

      <Typography variant="h6" gutterBottom>
        Disclosure of Your Personal Information
      </Typography>

      <Typography paragraph>
        We disclose your personal information as described below and as described elsewhere in this Privacy Policy.
      </Typography>

      <ul>
        <li>Third Parties Designated by You: When you use the Services, the personal information you provide will be shared with the third parties that you authorize to receive such information.</li>
        <li>Third Party Service Providers: We may share your personal information with third-party service providers to: provide you with the Services; conduct quality assurance testing; provide technical support; market the Services.</li>
        <li>Information We Share: We may disclose aggregated information about our users and information that does not identify any individual without restriction.</li>
      </ul>

      <Typography paragraph>
        Further disclosures are made for compliance with legal investigations, protecting rights, and under other circumstances detailed in our policy.
      </Typography>

      <Typography variant="h6" gutterBottom>
        Your Rights
      </Typography>

      <Typography paragraph>
        As a user of our site, you have the following rights, any of which you may exercise by contacting us at help@lipsum.com:
      </Typography>

      <ul>
        <li>The right to ask what personal data we hold about you at any time.</li>
        <li>The right to ask us to update and correct any out-of-date or incorrect personal data free of charge.</li>
        <li>The right to opt out of marketing communications.</li>
      </ul>

      <Typography paragraph>
        For more information, please visit <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">ICO Individual Rights</a>.
      </Typography>


    </ModalComponent>
  );
}

export default PrivacyPolicyModal;
