import React from "react";
import styles from "./atom.module.scss";

function NumberChip({ number, textColor }) {
  return (
    <div
      className={styles.numberChip}
      style={{ color: textColor, justifyContent: "center" }}
    >
      {number}
    </div>
  );
}

export default NumberChip;
