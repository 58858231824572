import React from "react";
import MainLayout from "../../layout/mainLayout";
import { Card, CardContent, Typography, Button, Grid, Box } from "@mui/material";

function Integrations() {

    const HorizontalCard = ({ title, description }) => {
        return (
            <Card
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#fff',
                    boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.1)',
                    padding: 2,
                    borderRadius: 2,
                    width: '100%',
                    height: '100%', 
                    marginBottom: 2, 
                }}
            >
                <CardContent>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1, fontSize: 14 }}>
                        {description}
                    </Typography>
                </CardContent>
                <Box>
                    <Button variant="contained" color="primary" sx={{ marginRight: 2, color: 'white' }}>
                        Setup Now
                    </Button>
                </Box>
            </Card>
        );
    };

    return (
        <MainLayout>
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <HorizontalCard 
                        title="OCPI" 
                        description="OCPI (Open Charge Point Interface) enables data sharing communication with other systems for charging networks."
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HorizontalCard 
                        title="Mobile Integration" 
                        description="Mobile integration for seamless communication between mobile apps and charging stations."
                    />
                </Grid>
            </Grid>
        </MainLayout>
    );
}

export default Integrations;
