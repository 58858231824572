import React from "react";

const MailIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.825"
      height="17"
      viewBox="0 0 22.825 17"
    >
      <g id="jyft7609" transform="translate(-9 -20)">
        <path
          id="Path_24"
          data-name="Path 24"
          d="M11.7,37H29.121a2.706,2.706,0,0,0,2.7-2.7V22.7a2.706,2.706,0,0,0-2.7-2.7H11.7A2.724,2.724,0,0,0,9,22.7V34.269A2.73,2.73,0,0,0,11.7,37ZM10.115,22.7A1.588,1.588,0,0,1,11.7,21.115H29.121A1.588,1.588,0,0,1,30.71,22.7V34.269a1.588,1.588,0,0,1-1.589,1.589H11.7a1.588,1.588,0,0,1-1.589-1.589Z"
          fill={color}
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M22.477,36.953h0l-3.762,3.762a.539.539,0,0,0,0,.78.539.539,0,0,0,.78,0l3.762-3.762,2.09,2.174a2.125,2.125,0,0,0,1.561.641,2.24,2.24,0,0,0,1.561-.641l2.118-2.118h0l3.456,3.456a.539.539,0,0,0,.78,0,.539.539,0,0,0,0-.78L31.4,37.009h0l4.292-4.292a.552.552,0,0,0-.78-.78l-7.218,7.19a1.093,1.093,0,0,1-1.533,0l-7.218-7.469a.552.552,0,0,0-.808.752Z"
          transform="translate(-6.482 -8.286)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default MailIcon;