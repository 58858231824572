import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Select from "react-select";
import UploadIcon from "../icons/uploadIcon";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import styles from "./mongodbChartExample.module.scss";
import { SITE_HOST_OPTIONS, TIME_PERIOD_OPTIONS } from "../../utils/constant";
import { PieChart } from "@mui/x-charts";

function AnalyticsPiChart() {
  return (
    <Grid
      xs={12}
      sm={12}
      md={5.9}
      sx={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0px 20px #0000000D",
        borderRadius: "20px",
        opacity: "1",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        minHeight: "400px",
        marginTop: "20px",
      }}
    >
      <Typography
        component={"div"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        fontSize={"14px"}
        marginBottom={"0.5rem"}
      >
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={SITE_HOST_OPTIONS}
            placeholder="Site Host"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          Time Period
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          {" "}
          <Select
            className="select-component"
            classNamePrefix="select"
            isSearchable={true}
            name="color"
            options={TIME_PERIOD_OPTIONS}
            placeholder="Time Period"
          />
        </Typography>
        <Typography component={"span"} marginRight={"8px"}>
          <UploadIcon />
        </Typography>
      </Typography>
      <Typography
        component={"h1"}
        fontWeight={"500"}
        fontSize={"1.375rem"}
        marginBottom={"1rem"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        Top Chargers
      </Typography>
      <Typography>
        <div className={styles.chart_container}>
          <PieChart
            height={400}
            series={[
              {
                data: [
                  { id: 0, value: 10, label: "CV1" },
                  { id: 1, value: 15, label: "CV2" },
                  { id: 2, value: 20, label: "CV3" },
                  { id: 2, value: 20, label: "CV4" },
                  { id: 2, value: 20, label: "CV5" },
                ],
              },
            ]}
            skipAnimation
            colors={["#0894CE","#D6BE05","#D69505","#D90644","#085DBC"]}
          />
        </div>
      </Typography>
    </Grid>
  );
}

export default AnalyticsPiChart;
