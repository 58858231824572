import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

// API Calls

export const fetchAnalyticsData = async (queryParams) => {
    ensureAuthToken(); // Ensure token is set before the API call
    try {
      const response = await axiosInstance.get('/analytics/energy-consumed', { params: queryParams });
      return response.data;
    } catch (error) {
      console.error('Error fetching Analytics Data:', error);
      throw error;
    }
};

export const fetchMonthlyRevenue = async (queryParams) => {
  ensureAuthToken(); // Ensure token is set before the API call
  try {
    const response = await axiosInstance.get('/analytics/revenue-generated', { params: queryParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching Analytics Data:', error);
    throw error;
  }
};



export default axiosInstance;
