export const SITE_HOST_OPTIONS = [
  {
    label: "Site, L",
    value: "Site, L",
  },
  {
    label: "Site, Los Angeles",
    value: "Site, Los Angeles",
  },
  {
    label: "Site, Las Vegas",
    value: "Site, Las Vegas",
  },
];

export const NOTIFICATION_OPTIONS = {
  "charger-assigned-to-cpo": "Charger Assigned to CPO",
  "charger-assigned-to-evse": "Charger Assigned to EVSE Station",
  "charger-activated": "Charger Activated",
  "cpo-activated": "CPO has been activated",
  "evse-station-created": "EVSE Station Created",
  "charger-assigned-to-cpo": "Charger Assigned to CPO",
  "charger-assigned-to-evse": "Charger Assigned to EVSE Station"
}

export const TIME_PERIOD_OPTIONS = [
  {
    label: "Current Month",
    value: "1",
  },
  {
    label: "Last 2 Months",
    value: "2",
  },
  {
    label: "Last 4 Months",
    value: "4",
  },
  {
    label: "Last 6 Months",
    value: "6",
  },

];

export const LOCATION_OPTIONS = [
  // {
  //   label: "Registered",
  //   value: "Registered",
  // },
  // {
  //   label: "Activated",
  //   value: "Activated",
  // },
  {
    label: "Available",
    value: "Available",
  },
  {
    label: "Busy",
    value: "Busy",
  },
  {
    label: "Offline",
    value: "Offline",
  },
  {
    label: "All",
    value: "All",
  },
];
