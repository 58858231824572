import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
    Avatar,
    Grid,
    LinearProgress,
    Typography,
    TextField,
    Button,
    TableCell,
    TableRow,
    TableHead,
    TablePagination,
    Table,
    TableContainer,
    TableBody,
    CircularProgress,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Autocomplete,
    IconButton,
    InputAdornment
} from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CardStyles from "../../components/atoms/card/index.module.scss";
import { message } from "antd";
import PlanShowModal from "../../components/settingsComponents/planShowModal";
import ModalComponent from "../atoms/ModalComponent";
import { Add, Edit } from "@mui/icons-material";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import { updateCpoAdminAccountSettings, checkEndPointAvailability, setEndPoint } from "../../redux/apis/cpo";
import { WhoAmI } from "../../redux/apis/user";
import { PhotoCamera } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateCpoEndpoint } from "../../redux/slices/user/cpoSlice";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cards from "./cards";
import { getCardDetails } from "../../redux/apis/cpo";
import { getInvoices } from "../../redux/apis/subscription";
import { getCountries } from "../../redux/apis/chargers";
import { getCroppedImg } from "../../utils/getCroppedImg"; // Utility to crop image using canvas
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY); // Replace with your Stripe publishable key


const CpoAccountSettings = () => {
    const [showPlanModal, setShowPlanModal] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        taxation: "",
        billingAddress: "",
        country: "",
        currency: "",
        language: "",
        file: null
    });
    const [invoices, setInvoices] = useState([]);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(formData.country || "");
    const [endpoint, setEndpoint] = useState(""); // Current endpoint state```
    const [endpointModalOpen, setEndpointModalOpen] = useState(false); // Modal open/close state
    const [newEndpoint, setNewEndpoint] = useState(""); // State to track entered endpoint in modal
    const [validationError, setValidationError] = useState(""); // Validation error for display in the modal
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [currentSubscriptionLimit, setCurrentSubscriptionLimit] = useState(null);
    const [isProfileSet, setIsProfileSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [errors, setErrors] = useState({});
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [preview, setPreview] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [confirmedPreview, setConfirmedPreview] = useState(null); // Confirmed profile picture


    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Name is required";
        }

        if (!formData.taxation) {
            newErrors.taxation = "Taxation is required";
        }

        if (!formData.billingAddress) {
            newErrors.billingAddress = "Billing address is required";
        }

        if (!formData.country) {
            newErrors.country = "Country is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }
    const dispatch = useDispatch();

    // call if country is set
    useEffect(() => {
        fetchData();
    }, [countries]);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await WhoAmI();
            setCurrentSubscription(response.subscription);
            setCurrentSubscriptionLimit(response.settings.subscriptionUsage);
            const cpoData = response.settings.cpo;

            setFormData({
                name: cpoData.name || "",
                taxation: cpoData.taxation || "",
                billingAddress: cpoData.billingAddress || "",
                country: cpoData.country || "",
                currency: cpoData.currency || "",
                language: cpoData.language || "",
                file: cpoData.profilePicture || null,
            });

            setEndpoint(cpoData.endpoint);
            setNewEndpoint(cpoData.endpoint);

            if (cpoData.country && countries.length) {
                const selectedCountryDetails = countries.find(
                    (country) => country.isoCode === cpoData.country
                );

                if (selectedCountryDetails) {
                    const countryCurrency = selectedCountryDetails.currency ?
                        selectedCountryDetails.currency
                        : "N/A";
                    const countryLanguage = selectedCountryDetails.language
                        ? selectedCountryDetails.language
                        : "N/A";

                    // Update formData with currency and language
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        currency: countryCurrency,
                        language: countryLanguage,
                    }));
                } else {
                    console.log("No country details found for code:", cpoData.country);
                }
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        } finally {
            setLoading(false);
        }
    };



    const fetchSubscriptionDetails = async () => {
        try {
            const response = await WhoAmI();
            setCurrentSubscription(response.subscription);
            setCurrentSubscriptionLimit(response.settings.subscriptionUsage);
        } catch (error) {
            console.error("Failed to fetch subscription details:", error?.response?.data?.message);
        }
    }

    //Fetch Invoices
    const fetchInvoices = async () => {
        try {
            const response = await getInvoices();
            setInvoices(response.invoices);
        } catch (error) {
            console.error("Failed to fetch invoices:", error);
        }
    };

    useEffect(() => {
        fetchInvoices();
    }, []);


    // Fetch country data from the REST Countries API
    const fetchCountries = async () => {
        setLoadingCountries(true);
        try {
            const response = await getCountries({
                limit: 1000
            });
            setCountries(response.list);
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        } finally {
            setLoadingCountries(false);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);


    // Update formData with selected country details
    const handleCountryChange = (event, newCountry) => {
        setSelectedCountry(newCountry);  // Update the selected country
        setFormData((prevFormData) => ({
            ...prevFormData,
            country: newCountry?.isoCode,
            currency: newCountry?.currency, // Set currency for the selected country
            language: newCountry?.language // Set language for the selected country
        }));
    };

    // Set default country on component mount based on formData.country
    useEffect(() => {
        if (formData.country && countries.length) {
            const initialCountryDetails = countries.find(
                (country) => country.isoCode === formData.country
            );
            if (initialCountryDetails) {
                setSelectedCountry(initialCountryDetails);
            }
        }
    }, [formData.country, countries]);


    // Handle image upload
    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            message.error("No file selected");
            return;
        }

        setFormData({ ...formData, file });
        setPreview(URL.createObjectURL(file));
        setIsCropping(true);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        try {
            setLoading(true);

            const croppedImage = await getCroppedImg(preview, croppedAreaPixels, 69, 69); // Fixed 69x69
            const updatedFormData = new FormData();
            updatedFormData.append("file", croppedImage);

            // Upload cropped image to backend
            await updateCpoAdminAccountSettings(updatedFormData);
            fetchData();
            message.success("Profile picture updated successfully");

            setConfirmedPreview(URL.createObjectURL(croppedImage)); // Update confirmed image
        } catch (error) {
            message.error(error.response?.data?.message || "Error updating profile picture");
        } finally {
            setLoading(false);
            setIsCropping(false);
            setConfirmedPreview(null);
        }
    };

    const handleCloseCrop = () => {
        setIsCropping(false);
        setConfirmedPreview(null);
        fetchData();
    }

    // Handle form submit for updating account settings
    const handleUpdateAccount = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        try {
            const payload = {
                ...formData,
            };
            await updateCpoAdminAccountSettings(payload);
            message.success("Account settings updated successfully");
            handleCloseEditModal();
        } catch (error) {
            console.error("Failed to update account settings:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenEditModal = () => {
        setOpenEditModal(true)
        setErrors({});
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        fetchData();

    };
    const handleOpenTransferModal = () => setOpenTransferModal(true);
    const handleCloseTransferModal = () => setOpenTransferModal(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    const handleTransferCPO = () => {
        setOpenTransferModal(false);
    }

    const [openDeletePortalModal, setOpenDeletePortalModal] = useState(false);

    const handleOpenDeletePortalModal = () => {
        setOpenDeletePortalModal(true);
    }

    const handleCloseDeletePortalModal = () => {
        setOpenDeletePortalModal(false);
    }

    const handleDeleteConfirmation = () => {
        setOpenDeletePortalModal(false);
    }

    // Enpoint API calls

    // Open and close the modal
    const openEndpointModal = () => {
        setEndpointModalOpen(true);
        setNewEndpoint(endpoint);
    };
    const closeEndpointModal = () => {
        setEndpointModalOpen(false);
        setValidationError("");
    };

    // Validate endpoint (alphanumeric and hyphens)
    const isValidEndpoint = (endpoint) => /^[a-zA-Z0-9-]+$/.test(endpoint);

    // Check if the endpoint is available via your API after validation
    const checkEndpoint = async () => {
        // Step 1: Client-side validation
        if (!isValidEndpoint(newEndpoint)) {
            setValidationError("URL Endpoint can only contain alphanumeric characters or hyphens.");
            return;
        }

        // Step 2: API check if validation passes
        setLoading(true);

        try {
            // Call your own Check Endpoint Availability API
            const response = await checkEndPointAvailability({ endpoint: newEndpoint });
            console.log(response);

            // Assuming your API returns a boolean `isAvailable` field
            if (response.isAvailable) {
                message.success("URL Endpoint is available.");
                setValidationError("");
            } else {
                setValidationError("URL Endpoint is not available. Please try a different one.");
            }
        } catch (error) {
            console.error("Error checking endpoint:", error);
            setValidationError("Failed to check the endpoint. Please try again.");
        }

        setLoading(false);
    };

    // Set the endpoint via your API
    const setEndpointHandler = async () => {
        // Same validation applied during setting the endpoint
        if (!isValidEndpoint(newEndpoint)) {
            setValidationError("Endpoint can only contain alphanumeric characters and hyphens.");
            return;
        }

        setLoading(true);

        try {
            setLoading(true);
            // Call your Set Endpoint API
            const response = await setEndPoint({ endpoint: newEndpoint });
            console.log(response);
            // Assuming your API returns the updated URL in `updateUrl`

            setEndpoint(response.endpoint); // Update the current endpoint
            message.success("URL updated successfully");
            closeEndpointModal();
            dispatch(updateCpoEndpoint(response.endpoint));

            // Retrieve cpoDetails from local or session storage
            let cpoDetails = localStorage.getItem('cpoDetails')
                ? JSON.parse(localStorage.getItem('cpoDetails'))
                : sessionStorage.getItem('cpoDetails')
                    ? JSON.parse(sessionStorage.getItem('cpoDetails'))
                    : null;

            if (cpoDetails) {
                // Update the endpoint attribute
                cpoDetails.endpoint = response.endpoint;

                // Save the updated object back to local or session storage
                localStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
                // If you want to ensure you're using sessionStorage instead
                // sessionStorage.setItem('cpoDetails', JSON.stringify(cpoDetails));
            }

            // Delay the redirect to allow the spinner to show
            navigate(`/csms/${response.endpoint}/settings`);


        } catch (error) {
            console.error("Error setting endpoint:", error);
            message.error("Failed to set the endpoint. Please try again.");
        }
        finally {
            setLoading(false);
        }


    };

    //fetch cards
    const getCards = async () => {
        try {
            const response = await getCardDetails();
            setCards(response.list);
        }
        catch (error) {
            console.error("Error fetching cards:", error);
        }
    }

    useEffect(() => {
        getCards();
    }, []);

    return (

        <>
            {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="55vh">
                <CircularProgress />
            </Box>)
                : (<>
                    <Grid
                        className={CardStyles.card}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"left"}
                    >
                        <div style={{ position: "relative", display: "inline-block" }}>
                            <Avatar
                                src={confirmedPreview || `${formData?.file}?t=${new Date().getTime()}`}
                                className="ProfileImg"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "50%",
                                    border: "2px solid #E9E9E9",
                                    "& img": {
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                    },
                                }}
                            />
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="icon-button-file">
                                <IconButton
                                    component="span"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        background: "rgba(255, 255, 255, 0.7)",
                                        borderRadius: "50%",
                                        boxShadow: 2,
                                    }}
                                >
                                    <PhotoCamera />
                                </IconButton>
                            </label>

                            {/* Cropping Dialog */}
                            <Dialog open={isCropping} onClose={handleCloseCrop} maxWidth="xs" fullWidth>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "20px",
                                        backgroundColor: "#fafafa",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            maxWidth: "300px",
                                            height: "300px",
                                            marginBottom: "20px",
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <Cropper
                                            image={preview}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                            cropShape="round"
                                            showGrid={false}
                                        />
                                    </div>

                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                        style={{ width: "80%", marginBottom: "20px" }}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCropSave}
                                        disabled={loading}
                                        style={{
                                            backgroundColor: "#1976d2",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 10px rgba(25, 118, 210, 0.3)",
                                        }}
                                    >
                                        {loading ? "Uploading..." : "Save"}
                                    </Button>
                                </div>
                            </Dialog>
                        </div>
                        <Typography sx={{ marginLeft: "4rem" }}>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>CPO Name :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.name}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "500" }}>URL:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>
                                    {"https://core.chargenex.com/csms/" + (endpoint ? endpoint : "")}
                                </Typography>
                                <Button variant="outlined" sx={{ marginLeft: "1rem" }} onClick={openEndpointModal}>
                                    Update URL
                                </Button>
                            </Typography>

                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Taxation:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.taxation}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Billing Address:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.billingAddress}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Country:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{countries.find((c) => c.isoCode === formData.country)?.name}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Currency:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.currency}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Language:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.language}</Typography>
                            </Typography>
                        </Typography>
                        <Typography sx={{ marginLeft: "auto", display: "flex", gap: "1rem", flexDirection: "column" }} >
                            <Button onClick={handleOpenEditModal} variant="outlined">Update Account Details</Button>
                            <Button onClick={handleOpenTransferModal} variant="outlined">Transfer Ownership</Button>
                            <Button onClick={handleOpenDeletePortalModal} variant="outlined">Delete Portal</Button>
                        </Typography>
                    </Grid>
                </>)}
            <>
                <Grid xs={12} sx={{ marginTop: "1rem" }} gap={2}>
                    <Typography
                        component={"h1"}
                        fontWeight={"500"}
                        fontSize={"1.25rem"}
                        marginBottom={"0.5rem"}
                    >
                        Subscription Settings
                    </Typography>
                    <Grid display={"flex"} width={"100%"} gap={2} md={12}>
                        <Grid md={6} width={"100%"} className={CardStyles.card} >
                            <Typography
                                component={"h1"}
                                fontWeight={"500"}
                                fontSize={"14px"}
                                marginBottom={"0.75rem"}
                            >
                                Subscription Plan
                            </Typography>
                            <Typography>{"Current Plan:"}</Typography>
                            <Grid sx={{ display: "flex", gap: 2, mt: 2 }}>
                                <Typography
                                    sx={{
                                        display: "flex",
                                        minWidth: "150px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "#F1F1F1",
                                        padding: "5px 10px",
                                        borderRadius: "8px",
                                        color: "#000",
                                        cursor: "default", // Avoid pointer cursor
                                        border: "none", // Remove any border if it has one
                                        textDecoration: "none" // Remove any underline if it has one
                                    }}
                                >
                                    {+ currentSubscription?.planName || "Free"}
                                </Typography>

                                <Button
                                    sx={{ marginTop: "auto", color: 'white', height: '40px', maxWidth: "150px" }}
                                    variant="contained"
                                    onClick={() => setShowPlanModal(true)}
                                >Upgrade Plan</Button>
                            </Grid>
                        </Grid>

                        <Grid md={6} width={"100%"} className={CardStyles.card}>
                            <Typography
                                component={"h1"}
                                fontWeight={"500"}
                                fontSize={"14px"}
                                marginBottom={"0.75rem"}
                            >
                                Subscription Limit
                            </Typography>
                            <Grid xs={12} display={"flex"} gap={2} flexDirection={"row"} >
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        marginBottom={"0.5rem"}
                                    >
                                        Chargers
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscriptionLimit?.chargers?.used === "undefined" ? <CircularProgress /> : currentSubscriptionLimit?.chargers?.used + "/" + currentSubscriptionLimit?.chargers?.limit}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        marginBottom={"0.5rem"}
                                    >
                                        Users
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                fontWeight: "500",
                                                padding: "10px",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscriptionLimit?.users?.used === "undefined" ? <CircularProgress /> : currentSubscriptionLimit?.users?.used + "/" + currentSubscriptionLimit?.users?.limit}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} display={"flex"} gap={2} flexDirection={"row"} >
                                <Grid md={6} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        my={"0.5rem"}
                                    >
                                        Evse Stations
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscriptionLimit?.stations?.used === "undefined" ? <CircularProgress /> : currentSubscriptionLimit?.stations?.used + "/" + currentSubscriptionLimit?.stations?.limit}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid md={5} width={"100%"}>
                                    <Typography
                                        component={"h1"}
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        my={"0.5rem"}
                                    >
                                        Roles
                                    </Typography>
                                    <Grid sx={{ display: "flex" }}>
                                        <Grid
                                            sx={{
                                                background: "#F1F1F1 0% 0% no-repeat padding-box",
                                                borderRadius: "14px",
                                                opacity: "1",
                                                alignItems: "center",
                                                display: "flex",
                                                justifyContent: "center",
                                                padding: "10px",
                                                fontWeight: "500",
                                                width: "100%",
                                            }}
                                            xs={12}
                                        >
                                            {currentSubscriptionLimit?.roles?.used === "undefined" ? <CircularProgress /> : currentSubscriptionLimit?.roles?.used + "/" + currentSubscriptionLimit?.roles?.limit}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

                <Grid xs={12} sx={{ display: "flex", marginTop: "1rem" }} gap={2}>
                    <Elements stripe={stripePromise}>
                        <Cards />
                    </Elements>
                    <Grid className={CardStyles.card} md={6} >
                        <Typography
                            component={"h1"}
                            fontWeight={"500"}
                            fontSize={"1.25rem"}
                            marginBottom={"0.75rem"}
                        >
                            Invoices
                        </Typography>
                        <TableContainer component={Box} sx={{ height: "200px", overflow: 'auto', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#fff' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Sr. No.</TableCell>
                                        <TableCell align="center">Invoice Number</TableCell>
                                        <TableCell align="center">Download</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices.map((invoice, index) => (
                                        <TableRow key={invoice.id}>
                                            <TableCell align="center">{index + 1}</TableCell>
                                            <TableCell align="center">{invoice.invoiceNumber}</TableCell>
                                            <TableCell align="center">
                                                <Typography
                                                    component="a"
                                                    href={invoice.pdfUrl}
                                                    download
                                                    target="_blank"
                                                    sx={{
                                                        textDecoration: 'none',
                                                        color: 'primary.main',
                                                        cursor: 'pointer',
                                                        padding: '4px 8px',
                                                        border: '1px solid #3f51b5',
                                                        borderRadius: '4px',
                                                        '&:hover': { backgroundColor: '#f0f0f0' }
                                                    }}
                                                >
                                                    <PictureAsPdfIcon sx={{ fontSize: '20px', verticalAlign: 'middle', marginRight: 0.5 }} />
                                                    Download
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Grid>
                </Grid>

            </>
            {showPlanModal && (
                <PlanShowModal
                    showPlanModal={showPlanModal}
                    setShowPlanModal={setShowPlanModal}
                    fetchInvoices={fetchInvoices}
                    fetchSubscriptionDetails={fetchSubscriptionDetails}
                />
            )}



            {/* Modal for setting endpoint using your custom ModalComponent */}
            <ModalComponent
                open={endpointModalOpen}
                handleClose={closeEndpointModal}
                showSubmitButton={true}
                headerText="Update URL"
                submitButtonLabel="Update URL"
                handleSubmit={setEndpointHandler} // Use setEndpointHandler to submit when the modal's submit button is clicked
            >
                <Box>
                    <TextField
                        label="Set URL"
                        value={newEndpoint}
                        onChange={(e) => setNewEndpoint(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" sx={{ marginRight: 0, opacity: 0.6 }} >
                                    {"https://core.chargenex.com/csms/"}
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                        sx={{ marginTop: "1rem" }}
                    />
                    <Button
                        variant="outlined"
                        onClick={checkEndpoint}
                        sx={{ marginTop: "1rem" }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : "Check Availability"}
                    </Button>
                    {validationError && (
                        <Typography color="error" sx={{ marginTop: "1rem" }}>
                            {validationError}
                        </Typography>
                    )}
                </Box>
            </ModalComponent>

            <ModalComponent
                open={openEditModal}
                handleClose={handleCloseEditModal}
                headerText={"Edit CPO Details"}
                handleSubmit={handleUpdateAccount}
                submitButtonLabel={"Update"}
            >
                <form>
                    <TextField
                        name="name"
                        label="CPO Name"
                        value={formData?.name}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        name="taxation"
                        label="Taxation"
                        value={formData?.taxation}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.taxation}
                        helperText={errors.taxation}
                    />
                    <TextField
                        name="billingAddress"
                        label="Billing Address"
                        value={formData?.billingAddress}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.billingAddress}
                        helperText={errors.billingAddress}
                    />

                    {/* Autocomplete Country Dropdown */}
                    <Autocomplete
                        options={countries}
                        getOptionLabel={(option) => `${option?.name} (${option?.currency})`}  // Show country name and currency code
                        value={selectedCountry} // Bind the selected country
                        onChange={handleCountryChange} // Handle changes
                        isOptionEqualToValue={(option, value) => option?.isoCode === value?.isoCode} // Ensure proper comparison
                        margin="normal"
                        renderInput={(params) => (
                            <TextField {...params} label="Country" variant="outlined" />
                        )}
                    />


                    {/* Conditionally render Currency and Language fields */}
                    {selectedCountry && (
                        <>
                            <TextField
                                name="currency"
                                label="Currency"
                                margin="normal"
                                value={formData.currency || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                name="language"
                                label="Language"
                                value={formData.language || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>
                    )}
                </form>
            </ModalComponent>

            <Dialog open={openDeletePortalModal} onClose={handleCloseDeletePortalModal}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Portal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeletePortalModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CpoAccountSettings;