import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 18.853 21.21"
    >
      <path
        id="home_FILL1_wght400_GRAD0_opsz24"
        d="M160-818.79v-14.14l9.427-7.07,9.427,7.07v14.14h-7.07v-8.248H167.07v8.248Z"
        transform="translate(-160 840)"
        fill="#fff"
      />
    </svg>
  );
}

export default HomeIcon;
