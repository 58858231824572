// MainLayout.js
import React, { useEffect, useState } from "react";
import Sidebar from "../components/sidebar/sidebar";
import MainHeader from "../components/mainHeader/mainHeader";
import SecondaryHeader from "../components/secondaryHeader/secondaryHeader";
import { Grid, Typography } from "@mui/material";
import Footer from "../components/footer/footer";
import { WhoAmI } from "../redux/apis/user";
import { useSelector } from "react-redux";


function MainLayout({ children, ...props }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const cpoEndpoint = useSelector((state) => state.cpo.endpoint) || "cpo";

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let pageName = window.location.pathname;
  let scrollRequired = false;
  switch (pageName) {
    case "/device-management/ota-updates":
      scrollRequired = true;
      break;
    case "/chargers":
      scrollRequired = true;
      break;
    case "/sites":
      scrollRequired = true;
      break;
    case "/cpo":
      scrollRequired = true;
      break;
    case "/site-host-operator":
      scrollRequired = true;
      break;  
    case "/evse-owners":
      scrollRequired = true;
      break;
    case "/evse-stations":
      scrollRequired = true;
      case "/device-management/ota-updates":
      scrollRequired = true;
      break;
    case `/csms/${cpoEndpoint}/evse-stations`:
      scrollRequired = true;
      break;
    case `/csms/${cpoEndpoint}/chargers`:
      scrollRequired = true;
      break;
    case `/csms/${cpoEndpoint}/customers`:
      scrollRequired = true;
      break; 
    case `/csms/${cpoEndpoint}/sessions`:
      scrollRequired = true;
      break;
    case `/csms/${cpoEndpoint}/integration`:
      scrollRequired = true;
      break;
    default:
      scrollRequired = false;
      break;
  }

  return (
      <div style={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar */}
      <div
        style={{
          display: { sm: "none", xs: "none", md: "block" },
          position: isMobile ? "absolute" : "relative",
          overflow: 'hidden',
        }}
      >
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
        />
      </div>
    
      {/* Main Content */}
      <div
        style={{
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
          width: 'calc(100% - 200px)', // Adjust width to account for the sidebar
        }}
      >
        <MainHeader toggleSidebar={toggleSidebar} isMobile={isMobile} />
        <SecondaryHeader
          style={{ position: 'absolute', top: '80px' }}
          setActiveTab={props.setActiveTab}
        />
        <Typography
          style={{
            padding: '20px',
            minHeight: 0,
            height: pageName === "/home" || pageName === `/csms/${cpoEndpoint}/home` ? "calc(100vh - 150px)" : "calc(100vh - 250px)",
            overflowY: scrollRequired ? "0" : "auto",
          }}
          className="layout_scroll"
        >
          {children}
        </Typography>
        <Footer />
      </div>
    </div>
  
  );
}

export default MainLayout;
