import React from "react";
import { Typography,Grid } from "@mui/material";
import RoundedIconChip from "../atoms/roundedIconChip/roundedIconChip";
import cardStyles from "../atoms/card/index.module.scss";

function Analyticscard({ icon, title, content,width }) {
  return (
    <Grid
      className={cardStyles.card}
      display={"flex"}
      alignItems={"center"}
      flexDirection={"row"}
      justifyContent={"center"}
      px={"2rem"}
      xs="12"
      sm="12"
      md="auto"
      width={width ?? "23% !important"}
    >
      <RoundedIconChip bgColor={"#F3F3F3"}>{icon}</RoundedIconChip>
      <Typography textAlign={"left"}>
        <Typography component={"p"} sx={{ fontSize: "14px", opacity: "0.7" }}>
          {title}
        </Typography>
        <Typography sx={{ fontWeight: "600", fontSize: "24px" }}>
          {content}
        </Typography>
      </Typography>
    </Grid>
  );
}

export default Analyticscard;
