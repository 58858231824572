import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
function TabComponent({ setActiveTab }) {
  const [value, setValue] = React.useState("cpo_account_settings");

  const userDetails = localStorage.getItem('userDetails') || sessionStorage.getItem('userDetails');
  const category = userDetails && JSON.parse(userDetails).category;

  useEffect(() => {
    if (category == "Super Admin") {
      setValue("super_admin_settings");
    } else if(category === "CPO Admin") {
      setValue("cpo_account_settings");
    }else{
      setValue("my_profile");
    }
  }, [category, setValue]); 
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Typography>
      
          {category === "CPO Admin" && (

            <>
            <Box
              sx={{ background: "white", width: "100%" }}
              borderRadius={"14px"}
              marginLeft={"1rem"}
              my={"1rem"}
            >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
            <Tab
            value="cpo_account_settings"
            label="CPO Account Settings"
            onClick={() => setActiveTab("cpo_account_settings")}
          />
          <Tab
            value="my_profile"
            label="My Profile"
            onClick={() => setActiveTab("my_profile")}
          />
          <Tab
            value="roles"
            label="Roles"
            onClick={() => setActiveTab("roles")}
          />
           <Tab
            value="user_management"
            label="Portal User Management"
            onClick={() => setActiveTab("user_management")}
          />
          <Tab
            value="base_rate"
            label="Base Rate Management"
            onClick={() => setActiveTab("base_rate")}
          />
           </Tabs>
           </Box>
           
          </>
          ) }
          {category === "Super Admin" && (

            <>
            <Box
              sx={{ background: "white", width: "100%" }}
              borderRadius={"14px"}
              marginLeft={"1rem"}
              my={"1rem"}
            >
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="white"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
          <Tab
            value="super_admin_settings"
            label="eMSP SETTINGS"
            sx={{textTransform: "None"}}
            onClick={() => setActiveTab("super_admin_settings")}  
          />
          <Tab
            value="my_profile_super_admin"
            label="My Profile"
            onClick={() => setActiveTab("my_profile_super_admin")}  
          />
          <Tab
            value="roles_super_admin"
            label="Roles"
            onClick={() => setActiveTab("roles_super_admin")}  
          />
          <Tab
            value="user_management_super_admin"
            label="User Management"
            onClick={() => setActiveTab("user_management_super_admin")}  
          />
          
          </Tabs>
          </Box>
           
          </>
          )}

          {category === "factory_manager" && (
             <Box
             sx={{ background: "white", width: "100%" }}
             borderRadius={"14px"}
             marginLeft={"1rem"}
             my={"1rem"}
           >
           <Tabs
             value={value}
             onChange={handleChange}
             textColor="white"
             indicatorColor="secondary"
             aria-label="secondary tabs example"
           >
            <Tab
            value="my_profile"
            label="My Profile"
            onClick={() => setActiveTab("my_profile")}  
          />
          </Tabs>
          </Box>
          )
          }
       
    </Typography>
  );
}

export default TabComponent;
