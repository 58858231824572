import React, { useState } from "react";
import MainLayout from "../../layout/mainLayout";
import Revenue from "../../components/analyticsComponents/revenue";
import Footfall from "../../components/analyticsComponents/footfall";

function Analytics() {
  const [activeSettingTab, setSelectedActiveSettingTab] = useState("revenue");
  return (
    <MainLayout  setActiveTab={setSelectedActiveSettingTab}>
      {activeSettingTab === "revenue" && <Revenue />}
      {activeSettingTab === "footfall" && <Footfall />}
    </MainLayout>
  );
}

export default Analytics;
