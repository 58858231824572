import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    selectedFilter: null,
    evse: null,
    charger: null,
  },
  reducers: {
    setSelectedFilter(state, action) {
      state.selectedFilter = action.payload;
    },
    setEvse(state, action) {
      state.evse = action.payload;
    },
    setCharger(state, action) {
      state.charger = action.payload;
    },
  },
});

export const { setSelectedFilter, setEvse, setCharger } = filterSlice.actions;
export default filterSlice.reducer;
