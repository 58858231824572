import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
    Avatar,
    Grid,
    LinearProgress,
    Typography,
    TextField,
    Button,
    TableCell,
    TableRow,
    TableHead,
    TablePagination,
    Table,
    TableContainer,
    TableBody,
    CircularProgress,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Autocomplete,
    IconButton,
    InputAdornment
} from "@mui/material";
import CardStyles from "../../components/atoms/card/index.module.scss";
import { message } from "antd";
import ModalComponent from "../atoms/ModalComponent";
import { Add, Edit } from "@mui/icons-material";
import { WhoAmI } from "../../redux/apis/user";
import { PhotoCamera } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { getCountries } from "../../redux/apis/chargers";
import { updateEMSPAdminAccountSettings } from "../../redux/apis/emsp";
import { getCroppedImg } from "../../utils/getCroppedImg"; // Utility to crop image using canvas
import Cropper from "react-easy-crop";
import Slider from "@mui/material/Slider";



const EMSPAccountSettings = () => {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        taxation: "",
        billingAddress: "",
        country: "",
        currency: "",
        language: "",
        file: null
    });
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [countries, setCountries] = useState([]);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(formData.country || "");
    const [isProfileSet, setIsProfileSet] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [preview, setPreview] = useState(null);
    const [zoom, setZoom] = useState(1);
    const [confirmedPreview, setConfirmedPreview] = useState(null); // Confirmed profile picture

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) {
            newErrors.name = "Name is required";
        }

        if (!formData.taxation) {
            newErrors.taxation = "Taxation is required";
        }

        if (!formData.billingAddress) {
            newErrors.billingAddress = "Billing address is required";
        }

        if (!formData.country) {
            newErrors.country = "Country is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;

    }
    const dispatch = useDispatch();
    // Fetch user details using WhoAmI API

    useEffect(() => {
        fetchData();
    }, []);

    // call if country is set
    useEffect(() => {
        fetchData();
    }, [countries]);
    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await WhoAmI();
            const EMSPData = response?.settings?.eMsp;

            setFormData({
                name: EMSPData.name || "",
                taxation: EMSPData.taxation || "",
                billingAddress: EMSPData.billingAddress || "",
                country: EMSPData.country || "",
                currency: EMSPData.currency || "",
                language: EMSPData.language || "",
                file: EMSPData.profilePicture || null,
            });


            if (EMSPData.country && countries.length) {
                const selectedCountryDetails = countries.find(
                    (country) => country.isoCode === EMSPData.country
                );

                if (selectedCountryDetails) {
                    const countryCurrency = selectedCountryDetails.currency ?
                        selectedCountryDetails.currency
                        : "N/A";
                    const countryLanguage = selectedCountryDetails.language
                        ? selectedCountryDetails.language
                        : "N/A";

                    // Update formData with currency and language
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        currency: countryCurrency,
                        language: countryLanguage,
                    }));
                } else {
                    console.log("No country details found for code:", EMSPData.country);
                }
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch country data from the REST Countries API
    const fetchCountries = async () => {
        setLoadingCountries(true);
        try {
            const response = await getCountries({
                limit: 1000
            });
            setCountries(response.list);
        } catch (error) {
            console.error("Failed to fetch countries:", error);
        } finally {
            setLoadingCountries(false);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);


    // Update formData with selected country details
    const handleCountryChange = (event, newCountry) => {
        setSelectedCountry(newCountry);  // Update the selected country
        setFormData((prevFormData) => ({
            ...prevFormData,
            country: newCountry?.isoCode,
            currency: newCountry?.currency, // Set currency for the selected country
            language: newCountry?.language // Set language for the selected country
        }));
    };

    // Set default country on component mount based on formData.country
    useEffect(() => {
        if (formData.country && countries.length) {
            const initialCountryDetails = countries.find(
                (country) => country.isoCode === formData.country
            );
            if (initialCountryDetails) {
                setSelectedCountry(initialCountryDetails);
            }
        }
    }, [formData.country, countries]);


    // Handle image upload
    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            message.error("No file selected");
            return;
        }

        setFormData({ ...formData, file });
        setPreview(URL.createObjectURL(file));
        setIsCropping(true);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        try {
            setLoading(true);

            const croppedImage = await getCroppedImg(preview, croppedAreaPixels, 69, 69); // Fixed 69x69
            const updatedFormData = new FormData();
            updatedFormData.append("file", croppedImage);

            // Upload cropped image to backend
            await updateEMSPAdminAccountSettings(updatedFormData);
            fetchData();
            message.success("Profile picture updated successfully");

            setConfirmedPreview(URL.createObjectURL(croppedImage)); // Update confirmed image
        } catch (error) {
            message.error(error.response?.data?.message || "Error updating profile picture");
        } finally {
            setLoading(false);
            setIsCropping(false);
            setConfirmedPreview(null);
        }
    };

    const handleCloseCrop = () => {
        setIsCropping(false);
        setConfirmedPreview(null);
        fetchData();
    }

    // Handle form submit for updating account settings
    const handleUpdateAccount = async () => {
        if (!validateForm()) {
            return;
        }

        setLoading(true);
        try {
            const payload = {
                ...formData,
            };
            await updateEMSPAdminAccountSettings(payload);
            message.success("Account settings updated successfully");
            handleCloseEditModal();
        } catch (error) {
            console.error("Failed to update account settings:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenEditModal = () => {
        setOpenEditModal(true)
        setErrors({});
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        fetchData();

    };
    const handleOpenTransferModal = () => setOpenTransferModal(true);
    const handleCloseTransferModal = () => setOpenTransferModal(false);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };


    const handleTransferEMSP = () => {
        setOpenTransferModal(false);
    }

    const [openDeletePortalModal, setOpenDeletePortalModal] = useState(false);

    const handleOpenDeletePortalModal = () => {
        setOpenDeletePortalModal(true);
    }

    const handleCloseDeletePortalModal = () => {
        setOpenDeletePortalModal(false);
    }

    const handleDeleteConfirmation = () => {
        setOpenDeletePortalModal(false);
    }



    return (

        <>
            {loading ? (<Box display="flex" justifyContent="center" alignItems="center" height="55vh">
                <CircularProgress />
            </Box>)
                : (<>
                    <Grid
                        className={CardStyles.card}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"left"}
                    >
                        <div style={{ position: "relative", display: "inline-block" }}>
                            <Avatar
                                src={confirmedPreview || `${formData?.file}?t=${new Date().getTime()}`}
                                className="ProfileImg"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "90px",
                                    height: "90px",
                                    borderRadius: "50%",
                                    border: "2px solid #E9E9E9",
                                    "& img": {
                                        objectFit: "cover",
                                        width: "100%",
                                        height: "100%",
                                    },
                                }}
                            />
                            <input
                                accept="image/*"
                                style={{ display: "none" }}
                                id="icon-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="icon-button-file">
                                <IconButton
                                    component="span"
                                    sx={{
                                        position: "absolute",
                                        bottom: 0,
                                        right: 0,
                                        background: "rgba(255, 255, 255, 0.7)",
                                        borderRadius: "50%",
                                        boxShadow: 2,
                                    }}
                                >
                                    <PhotoCamera />
                                </IconButton>
                            </label>

                            {/* Cropping Dialog */}
                            <Dialog open={isCropping} onClose={handleCloseCrop} maxWidth="xs" fullWidth>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        padding: "20px",
                                        backgroundColor: "#fafafa",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            maxWidth: "300px",
                                            height: "300px",
                                            marginBottom: "20px",
                                            borderRadius: "50%",
                                            overflow: "hidden",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <Cropper
                                            image={preview}
                                            crop={crop}
                                            zoom={zoom}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onZoomChange={setZoom}
                                            onCropComplete={onCropComplete}
                                            cropShape="round"
                                            showGrid={false}
                                        />
                                    </div>

                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                        style={{ width: "80%", marginBottom: "20px" }}
                                    />

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCropSave}
                                        disabled={loading}
                                        style={{
                                            backgroundColor: "#1976d2",
                                            color: "#fff",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            padding: "10px 20px",
                                            borderRadius: "8px",
                                            boxShadow: "0px 4px 10px rgba(25, 118, 210, 0.3)",
                                        }}
                                    >
                                        {loading ? "Uploading..." : "Save"}
                                    </Button>
                                </div>
                            </Dialog>
                        </div>

                        <Typography sx={{ marginLeft: "4rem" }}>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>EMSP Name :</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.name}</Typography>
                            </Typography>

                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Taxation:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.taxation}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Billing Address:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.billingAddress}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Country:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{countries.find((c) => c.isoCode === formData.country)?.name}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Currency:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.currency}</Typography>
                            </Typography>
                            <Typography sx={{ display: "flex", fontSize: "1rem" }}>
                                <Typography sx={{ fontWeight: "500" }}>Language:</Typography>
                                <Typography sx={{ opacity: "0.6", marginLeft: "1rem" }}>{formData.language}</Typography>
                            </Typography>
                        </Typography>
                        <Typography sx={{ marginLeft: "auto", display: "flex", gap: "1rem", flexDirection: "column" }} >
                            <Button onClick={handleOpenEditModal} variant="outlined">Update Account Details</Button>
                            <Button onClick={handleOpenTransferModal} variant="outlined">Transfer Ownership</Button>
                            <Button onClick={handleOpenDeletePortalModal} variant="outlined">Delete Portal</Button>
                        </Typography>
                    </Grid>
                </>)}
            <ModalComponent
                open={openEditModal}
                handleClose={handleCloseEditModal}
                headerText={"Edit EMSP Details"}
                handleSubmit={handleUpdateAccount}
                submitButtonLabel={"Update"}
            >
                <form>
                    <TextField
                        name="name"
                        label="EMSP Name"
                        value={formData?.name}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.name}
                        helperText={errors.name}
                    />
                    <TextField
                        name="taxation"
                        label="Taxation"
                        value={formData?.taxation}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.taxation}
                        helperText={errors.taxation}
                    />
                    <TextField
                        name="billingAddress"
                        label="Billing Address"
                        value={formData?.billingAddress}
                        onChange={handleInputChange}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                        error={errors.billingAddress}
                        helperText={errors.billingAddress}
                    />

                    {/* Autocomplete Country Dropdown */}
                    <Autocomplete
                        options={countries}
                        getOptionLabel={(option) => `${option?.name} (${option?.currency})`}  // Show country name and currency code
                        value={selectedCountry} // Bind the selected country
                        onChange={handleCountryChange} // Handle changes
                        isOptionEqualToValue={(option, value) => option?.isoCode === value?.isoCode} // Ensure proper comparison
                        margin="normal"
                        renderInput={(params) => (
                            <TextField {...params} label="Country" variant="outlined" />
                        )}
                    />


                    {/* Conditionally render Currency and Language fields */}
                    {selectedCountry && (
                        <>
                            <TextField
                                name="currency"
                                label="Currency"
                                margin="normal"
                                value={formData.currency || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                            <TextField
                                name="language"
                                label="Language"
                                value={formData.language || "N/A"}
                                fullWidth
                                sx={{ marginBottom: 2 }}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </>
                    )}
                </form>
            </ModalComponent>

            <Dialog open={openDeletePortalModal} onClose={handleCloseDeletePortalModal}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this Portal?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeletePortalModal} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmation} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EMSPAccountSettings;