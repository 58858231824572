import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

export const getNotifications = async () => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get('/notification');
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const markAsRead = async (notificationId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/notification/${notificationId}/read`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const markAllAsRead = async () => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/notification/read-all`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const clearAll = async () => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/notification/clear-all`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

export const clearById = async (notificationId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/notification/${notificationId}/clear`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}



export default axiosInstance;