import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from "@mui/material/Button";

function FaqSection(props) {

  return (
    <div>
    {props.faqs.map((faq, index) => (
      <Accordion key={index}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`faq-content-${index}`}
          id={`faq-header-${index}`}
        >
          <Typography variant="h6">{faq.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{faq.description}</Typography>
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
  );
}

export default FaqSection;
