import React from "react";

const ChargnexBackgroundLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="314.998"
      height="299.042"
      viewBox="0 0 314.998 299.042"
    >
      <g id="chargnex-logo" transform="translate(-268.1 0.1)" opacity="0.04">
        <path
          id="Path_4661"
          data-name="Path 4661"
          d="M298.637,67.309c8.528,0,12.93-3.026,12.93-8.8V54.1c0-5.777-4.127-8.8-12.93-8.8H281.305c-8.528,0-12.93,2.751-12.93,8.8H268.1V67.859c2.751-.275,5.777-.55,8.8-.55Z"
          transform="translate(0 79.499)"
          fill="none"
        />
        <path
          id="Path_4663"
          data-name="Path 4663"
          d="M350.454,75.577h42.091l35.764,44.017L516.894,5.7,428.309,89.333,404.375,60.446H310.839l73.179,85.283L286.9,268.152h-11l14.581,20.633L305.612,269.8h.55L408.227,145.454Z"
          transform="translate(13.658 10.156)"
          fill="#fff"
        />
        <path
          id="Path_4664"
          data-name="Path 4664"
          d="M533.367,20.808,518.787-.1,501.455,21.633h.825l-97.113,118.3,57.772,69.877H420.573L384.809,165.79,296.5,279.684l88.585-83.633,23.934,28.886h93.536l-73.178-85.283L523.739,20.808Z"
          transform="translate(49.73 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default ChargnexBackgroundLogo;
