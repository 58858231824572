import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

// API Calls

export const fetchRoles = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get('/cpo/role');
    return response.data;
  }catch(error){
    throw error;
  }

};

export const AddRole = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post('/cpo/role', data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const updateRole = async (roleId, data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.patch(`/cpo/role/${roleId}`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const deleteRole = async (roleId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.delete(`/cpo/role/${roleId}`);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const fetchBaseRates = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get('/cpo/base-rate');
    return response.data;
  }catch(error){
    throw error;
  }
};

// Base Rate APIs
export const AddBaseRate = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post('/cpo/base-rate', data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const updateBaseRate = async (baseRateId, data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.patch(`/cpo/base-rate/${baseRateId}`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const deleteBaseRate = async (baseRateId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.delete(`/cpo/base-rate/${baseRateId}`);
    return response.data;
  }catch(error){
    throw error;
  }
};

//CPO user APIs

export const fetchCPOUsers = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get('/cpo/user');
    return response.data;
  }catch(error){
    throw error;
  }
};

export const fetchCPOUserById = async (userId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/cpo/user/${userId}`);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const addCPOUser = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post('/cpo/user', data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const updateCPOUser = async (userId, data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.patch(`/cpo/user/${userId}`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const deleteCPOUser = async (userId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.delete(`/cpo/user/${userId}`);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const updatemyProfile = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/user/update-profile`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

//CPO super admin apis

export const fetchCpoAdmins = async (queryparams) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get('/cpo-admin', {params: queryparams});
    return response.data;
  }catch(error){
    throw error;
  }
}
export const addCpoAdmin = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post('/cpo-admin', data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const updateCpoAdmin = async (adminId, data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.patch(`/cpo-admin/${adminId}`, data);
    return response.data;
  }catch(error){
    throw error;
  }
};

export const deleteCpoAdmin = async (adminId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.delete(`/cpo-admin/${adminId}`);
    return response.data;
  }catch(error){
    throw error;
  }
}

// Update CPO admin account settings
export const updateCpoAdminAccountSettings = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/user/update-account-settings`, data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const checkEndPointAvailability = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/user/check-endpoint-availability`, data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const setEndPoint = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/user/set-endpoint`, data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const getCardDetails = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/cpo/card-details`);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const addCardDetails = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/card-details`, data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const getcardDetailsById = async (id) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/cpo/card-details/${id}`);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const updateCardDetails = async (id, data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.patch(`/cpo/card-details/${id}`, data);
    return response.data;
  }catch(error){
    throw error;
  }
}

export const deleteCardDetails = async (id) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.delete(`/cpo/card-details/${id}`);
    return response.data;
  }catch(error){
    throw error;
  }
}

export default axiosInstance;

