import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/mainLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  TablePagination,
  Typography,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { Add, Edit, Delete, Search } from "@mui/icons-material";
import ButtonComponent from "../../components/atoms/button/button";
import ModalComponent from "../../components/atoms/ModalComponent";
import {
  addEvseOwnersAPI,
  fetchEvseOwnersAPI,
  editEvseOwnersAPI,
  deleteEvseOwnersAPI
} from "../../redux/apis/evseOwners";
import { message } from "antd";

function EvseOwners() {
  const [evseOwners, setEvseOwners] = useState([]);
  const [addEvseOwnerModalOpen, setAddEvseOwnerModalOpen] = useState(false);
  const [editEvseOwnerModalOpen, setEditEvseOwnerModalOpen] = useState(false);
  const [selectedEvseOwner, setSelectedEvseOwner] = useState(null);
  const [selectedEvseOwnerId, setSelectedEvseOwnerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [addEvseOwnerFormData, setAddEvseOwnerFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    acno: "",
    bank: "",
    description: "",
    actype: "",
  });
  const [editEvseOwnerFormData, setEditEvseOwnerFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: ""
  });

  const handleAddEvseOwner = async () => {
    try {
      const payload = {
        ...addEvseOwnerFormData,
        userType: "evse_owner",
        userRole: "evse_owner",
      }
        await addEvseOwnersAPI(payload);
        fetchEvseOwner();
        message.success("EVSE owner added successfully");
        setAddEvseOwnerModalOpen(false);
    } catch (error) {
      console.error("Error adding EVSE owner:", error);
      message.error("Error adding EVSE owner");
    }
  };

  

  const fetchEvseOwner = async () => {
    setLoading(true);
    try {
      const response = await fetchEvseOwnersAPI({
        search: searchQuery,
        page: page + 1,
        limit: rowsPerPage,
      }); 
        setEvseOwners(response.list || []);
        setTotalCount(response.totalCount || 0); // assuming total count is part of the response
    } catch (error) {
      console.error("Error fetching EVSE owners:", error);
      message.error("Error fetching EVSE owners");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvseOwner();
  
  }, [rowsPerPage, page, searchQuery]);

  const handleEditEvseOwner = async () => {
    try {
        await editEvseOwnersAPI(selectedEvseOwner.id, editEvseOwnerFormData);
        message.success("EVSE owner updated successfully");
        fetchEvseOwner();
        setEditEvseOwnerModalOpen(false);
        setSelectedEvseOwner(null);

    } catch (error) {
      console.error("Error updating EVSE owner:", error);
      message.error("Error updating EVSE owner");
    }
  };

  const handleOpenEditDialog = (evseOwner) => {
    setEditEvseOwnerFormData({
      firstName: evseOwner.firstName,
      lastName: evseOwner.lastName,
      mobile: evseOwner.phoneNumber
    })
    console.log(editEvseOwnerFormData);

    setSelectedEvseOwner(evseOwner);
    setEditEvseOwnerModalOpen(true);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDeleteEvseOwner = async (selectedEvseOwnerId) => {
     try{
       await deleteEvseOwnersAPI(selectedEvseOwnerId);
       message.success("EVSE owner deleted successfully");
       fetchEvseOwner();
       setOpenDeleteDialog(false);
     }catch(error){
        console.error("Error deleting EVSE owner:", error);
        message.error("Error deleting EVSE owner");
        setOpenDeleteDialog(false);
     }
  };

  const handleOpenDeleteDialog = (ownerId) => {
    setSelectedEvseOwnerId(ownerId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setAddEvseOwnerFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      accountNo: "",
      accountType: "",
      bankName: "", 
      bankCode:"",
      description: ""
    })
  };

  const handleCloseAddModal = () => { 
    setAddEvseOwnerModalOpen(false);
    setAddEvseOwnerFormData({
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      accountNo: "",
      accountType: "",
      bankName: "", 
      bankCode:"",
      description: ""
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 


  return (
    <MainLayout>
      <Typography
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0rem 0rem 1rem 0rem "}
      >
        <Typography>
          <TextField
            id="search"
            variant="outlined"
            hiddenLabel
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
              sx: { borderRadius: "14px", fontSize: "14px", height: "40px" },
            }}
            InputLabelProps={{ sx: { fontSize: "14px" } }}
          />
        </Typography>
        <Typography>
          <ButtonComponent
            label={
              <>
                <Add />{" "}
                <Typography component={"span"}>Add EVSE Owner</Typography>
              </>
            }
            width={"200px"}
            height={"40px"}
            onClick={() => setAddEvseOwnerModalOpen(true)}
          />
        </Typography>
      </Typography>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
        <TableContainer
              sx={{
                maxHeight: "65vh",
                borderRadius: "8px 8px 0 0",
                overflow: "auto",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                border: "1px solid #e0e0e0",
                backgroundColor: "#ffffff",
              }}
            >
          <Table
            sx={{ width: "100%" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 99,
                "& th": {
                  border: "1px solid #e0e0e0",
                  background: "#F5F3F3",
                  fontWeight: "500",
                  color: "#333",
                },
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr No.</TableCell>
                <TableCell align="center">First Name</TableCell>
                <TableCell align="center">Last Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Mobile</TableCell>
                <TableCell align="center">No. Of Chargers</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
            sx={{
              "& td": {
                border: "1px solid #e0e0e0",
                color: "#555",
              },
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#fafafa",
              },
              "& tr:hover": {
                backgroundColor: "#f1f1f1",
              },
            }}>
              {evseOwners.map((evseOwner, index) => (
                  <TableRow key={evseOwner.id}>
                    <TableCell align="center">{index + 1 + page * rowsPerPage}</TableCell>
                    <TableCell align="center">{evseOwner?.firstName}</TableCell>
                    <TableCell align="center">{evseOwner?.lastName}</TableCell>
                    <TableCell align="center">{evseOwner?.email}</TableCell>
                    <TableCell align="center">{evseOwner?.phoneNumber}</TableCell>
                    <TableCell align="center">{evseOwner?.chargerCount}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          handleOpenEditDialog(evseOwner);
                        }}
                      >
                        <Edit sx={{color:"#5882E6"}} />
                      </IconButton>
                      {/* <IconButton
                         onClick={() => {
                          handleOpenDeleteDialog(evseOwner.id);
                         }
                        }
                      >
                        <Delete />
                      </IconButton> */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
            component="div"
            sx={{
              position: "sticky",
              bottom: 0,
              overflowX : "hidden", 
              padding: "0 0.5rem",
              background: "#f5f3f3",
              borderTop: "1px solid #e0e0e0",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "0 0 8px 8px",
            }}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {addEvseOwnerModalOpen && 
       <ModalComponent
       open={addEvseOwnerModalOpen}
       handleClose={handleCloseAddModal}
       headerText="Add EVSE Owner"
       handleSubmit={handleAddEvseOwner}
       submitButtonLabel="Submit"
     >
       <Grid container spacing={2}>
         <Grid item xs={6}>
           <TextField
             id="firstName"
             name="firstName"
             label="First Name"
             value={addEvseOwnerFormData.firstName}
             onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, firstName: e.target.value })
             }
             fullWidth
           />
         </Grid>
         <Grid item xs={6}>
           <TextField
             id="lastName"
             name="lastName"
             label="Last Name"
             value={addEvseOwnerFormData.lastName}
             onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, lastName: e.target.value })}
             fullWidth
           />
         </Grid>
         <Grid item xs={6}>
           <TextField
             id="email"
             name="email"
             label="Email"
             value={addEvseOwnerFormData.email}
             onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, email: e.target.value })}
             fullWidth
           />
         </Grid>
         <Grid item xs={6}>
           <TextField
             id="mobile"
             name="mobile"
             label="Mobile No."
             value={addEvseOwnerFormData.phoneNumber}
             onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, mobile: e.target.value })}
             fullWidth
           />
         </Grid>
         <Grid item xs={6}>
                  <TextField
                    id="accountNo"
                    name="accountNo"
                    label="Account Number"
                    value={addEvseOwnerFormData.accountNo}
                    onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, accountNo: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="bankName"
                    name="bankName"
                    label="Bank Name"
                    value={addEvseOwnerFormData.bankName}
                    onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, bankName: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="bankCode"
                    name="bankCode"
                    label="Bank Code"
                    value={addEvseOwnerFormData.bankCode}
                    onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, bankCode: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    name="description"
                    label="Description"
                    value={addEvseOwnerFormData.description}
                    onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, description: e.target.value })}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="actype-label">Account Type</InputLabel>
                    <Select
                      labelId="actype-label"
                      id="accountType"
                      name="accountType"
                      value={addEvseOwnerFormData.accountType}
                      onChange={(e) =>
               setAddEvseOwnerFormData({ ...addEvseOwnerFormData, accountType: e.target.value })}
                      label="Account Type"
                    >
                      <MenuItem value="savings">Savings</MenuItem>
                      <MenuItem value="current">Current</MenuItem>
                      <MenuItem value="">None</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
       </Grid>
     </ModalComponent>}
      {editEvseOwnerModalOpen && 
      <ModalComponent
      open={editEvseOwnerModalOpen}
      handleClose={() => setEditEvseOwnerModalOpen(false)}
      headerText="Edit EVSE Owner"
      handleSubmit={handleEditEvseOwner}
      submitButtonLabel="Save"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="firstName"
            name="firstName"
            label="First Name"
            value={editEvseOwnerFormData.firstName}
            onChange={(e) =>
              setEditEvseOwnerFormData({ ...editEvseOwnerFormData, firstName: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="lastName"
            name="lastName"
            label="Last Name"
            value={editEvseOwnerFormData.lastName}
            onChange={(e) =>
              setEditEvseOwnerFormData({ ...editEvseOwnerFormData, lastName: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="mobile"
            name="mobile"
            label="Mobile No."
            value={editEvseOwnerFormData.mobile}
            onChange={(e) =>
              setEditEvseOwnerFormData({ ...editEvseOwnerFormData, mobile: e.target.value })}
            fullWidth
          />
        </Grid>
      </Grid>
    </ModalComponent>}
     {/* Delete Confirmation Dialog */}
     <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this site Host Operator?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteEvseOwner(selectedEvseOwnerId)}
            color="secondary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}

export default EvseOwners;
