import axios from "axios";


const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};


export const getRapidLogin = async (data) => {
    try{
        const response = await axios.post("https://api.rapidup.io/v1/worker-service/auth/login", data);
        return response; 
    }
    catch(error){
        console.error(error);
    }
}

  export const fetchRollouts = async () => {
    ensureAuthToken(); // Ensure token is set before the API call
    try {
      const response = await axiosInstance.get(`/rapid-up/rollout`);
      return response.data;
      } catch (error) {
        console.error('Error Fetching Rollout:', error);
      }
  }

export const uploadFile = async (data) => {
    try {
      const response = await axios.post("https://api.rapidup.io/v1/device-service/agents/upload-artifact", data, {
        headers: { 
          'Authorization': `Bearer ${localStorage.getItem('RapidToken') || sessionStorage.getItem('RapidToken')}` }
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
};


export const createRollout = async (queryParams) => {
  ensureAuthToken(); // Ensure token is set before the API call
  try {
    const response = await axiosInstance.post('/rapid-up/rollout', queryParams);
    return response.data;
  } catch (error) {
    console.error('Error Creating rollout list:', error);
    throw error;
  }
};

export const copyRollout = async (id) => {
  ensureAuthToken(); // Ensure token is set before the API call
  try {
    const response = await axiosInstance.post(`/rapid-up/rollout/clone/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error copying rollout', error);
    throw error;
  }
};

export const deleteRollout = async (id) => {
  ensureAuthToken(); // Ensure token is set before the API call
  try {
    const response = await axiosInstance.delete(`/rapid-up/rollout/${id}`);
    return response.data;
    } catch (error) {
      console.error('Error Deleting Rollout:', error);
    }
}


export const editRollout = async (id, data) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.put(`/rapid-up/rollout/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error Editing Rollout:', error);
    throw error;
  }

};


export const playRapidRollout = async (id) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.post(`/rapid-up/rollout/play/${id}`);
      return response.data;
    } catch (error) {
      console.error('Error Playing Rollout:', error);
      throw error;
    }
  
};

export const getRolloutStats = async (id) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`/rapid-up/rollout/stats/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error Fetching Rollout Stats:', error);
    throw error;
  }
};

export const getsystemStatus = async (cgxboxId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`${baseURL}/rapid-up/device-overview/${cgxboxId}`);
    return response.data;
  } catch (error) {
    console.error('Error Fetching System Status:', error);
    throw error;
  }
};

export const getRolloutDetails = async (rolloutId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`${baseURL}/rapid-up/rollout/details/${rolloutId}`);
    return response.data;
  } catch (error) {
    console.error('Error Fetching System Status:', error);
    throw error;
  }
};

export const getRolloutLogs = async (rolloutId, deviceId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`${baseURL}/rapid-up/rollout/logs/${rolloutId}/${deviceId}`);
    return response.data;
  } catch (error) {
    console.error('Error Fetching Rollout Logs:', error);
    throw error;
  } 
}

export const getRolloutDetailStats = async (rolloutId) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.get(`${baseURL}/rapid-up/rollout/stats/${rolloutId}`);
    return response.data;
  } catch (error) {
    console.error('Error Fetching Rollout Stats:', error);
    throw error;
  }
}

export const invokeAPI = async (queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/rapid-up/invoke-command`, queryParams);
    return response.data;
  } catch (error) {
    console.error(`Error in Invoking`, error);
    throw error;
  }
};

export const revokeAPI = async (deviceId, queryParams) => {
  ensureAuthToken();
  try {
    const response = await axiosInstance.post(`/rapid-up/revoke-command/${deviceId}`, queryParams);
    return response.data;
  } catch (error) {
    console.error(`Error in revoking`, error);
    throw error;
  }
}

// export const invokeGraphApi = async (queryParams) => {
//   ensureAuthToken();
//   try {
//     const response = await axiosInstance.post(`/rapid-up/invoke-graph`, queryParams);
//     return response.data;
//   } catch (error) {
//     console.error(`Error in Invoking`, error);
//     throw error;
//   }
// };