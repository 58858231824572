import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, useLoadScript, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import Geolocation from '@react-native-community/geolocation';
import { fetchChargerLocationAPI, getCountries } from '../../redux/apis/chargers';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import bluepin from './Pins/Bluepin.svg';
import blackpin from './Pins/Blackpin.svg';
import { MenuItem, Typography } from '@mui/material';
import { FormControl, InputLabel, Select } from "@mui/material";
import { TextField } from '@mui/material';

const mapContainerStyle = {
  width: '100%',
  height: '450px',
};

function ChargerMap() {
  const [chargers, setChargers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedCharger, setSelectedCharger] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyDDiyJAizQKuM93iXy4_2tmpSF_avRL0Cg', // Replace with your actual API key
  });

  useEffect(() => {
    Geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => console.error('Geolocation error:', error),
      { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
    );

    async function loadCountries() {
      const response = await getCountries({ limit: 1000 });
      setCountries(response.list);
    }

    loadCountries();
  }, []);

  useEffect(() => {
    async function loadChargers() {
      const params = { country: selectedCountry || null };
      const response = await fetchChargerLocationAPI(params);
      let allChargers = [...response.all];
      if (selectedStatus && selectedStatus !== 'all') {
        allChargers = allChargers.filter(charger => charger.status === selectedStatus);
      }
      setChargers(allChargers);
    }

    loadChargers();
  }, [selectedCountry, selectedStatus]);

  if (!isLoaded || !currentLocation) return <div>Loading...</div>;

  return (
    <>
      <Grid container spacing={1} >
        <Grid item xs={12} md={6}>
          <Typography component={"h1"} fontWeight={"500"} fontSize={"1.25rem"} >
            Locations
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} display={"flex"} gap={2} marginBottom={"1rem"}>
          <Autocomplete
            options={countries}
            getOptionLabel={(option) => option.name}
            onChange={(e, newValue) => setSelectedCountry(newValue ? newValue.isoCode : '')}
            renderInput={(params) => (
              <TextField {...params} label="Country" variant="outlined" fullWidth />
            )}
            sx={{
              padding: 0, minWidth: "150px",
              "& .MuiOutlinedInput-root": {
                padding: "0px", // Adjust padding to a minimal value
              },
              "& .MuiInputLabel-root": {
                top: "-25%", // Adjust label position as needed
              },
            }}
          />
          <FormControl fullWidth variant="outlined" sx={{ padding: 0 }}>
            <InputLabel sx={{
              top: "-20%",
             
            }}>Status</InputLabel>
            <Select
              label="Status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              sx={
                {
                  "& .MuiSelect-select": {
                    padding: "7px", // Minimal padding for Select
                  },
                  "& .MuiInputLabel-root": {
                    top: "-25%", // Adjust label position as needed
                  },
                }
              }
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="available">Available</MenuItem>
              <MenuItem value="busy">Busy</MenuItem>
              <MenuItem value="offline">Offline</MenuItem>
            </Select>
          </FormControl>

        </Grid>
      </Grid>

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={currentLocation}
        zoom={12}
      >
        <MarkerClusterer>
          {(clusterer) =>
            chargers.map((charger) => {
              let icon = bluepin;
              if (charger.status === 'busy') icon = blackpin;
              return (
                <Marker
                  key={charger.serialNumber}
                  position={{ lat: parseFloat(charger.lat), lng: parseFloat(charger.lng) }}
                  clusterer={clusterer}
                  icon={{
                    url: icon,
                    scaledSize: new window.google.maps.Size(35, 35), // Reduced marker size
                  }}
                  onClick={() => setSelectedCharger(charger)}
                />
              );
            })
          }
        </MarkerClusterer>

        {selectedCharger && (
          <InfoWindow
            position={{ lat: parseFloat(selectedCharger.lat), lng: parseFloat(selectedCharger.lng) }}
            onCloseClick={() => setSelectedCharger(null)}
            options={{ pixelOffset: new window.google.maps.Size(0, -30) }} // Moves InfoWindow above marker

          >
            <>
              <h3>{selectedCharger.name}</h3>
              <p>Status: {selectedCharger.status}</p>
              <p>Serial Number: {selectedCharger.serialNumber}</p>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${selectedCharger.lat},${selectedCharger.lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Open in Google Maps
              </a>
            </>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
}

export default ChargerMap;
