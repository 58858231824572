import React, { useState } from "react";
import Analyticscard from "./analyticscard";
import AvailableIcon from "../icons/availableIcon";
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import AnalyticsPiChart from "../Charts/AnalyticsPiChart";
import { Card } from "../atoms/card";
import BasicLineChart from "../Charts/analyticsLineChart";

function Footfall() {
  const [siteHosts, setSiteHosts] = useState([
    {
      sr_no: 1,
      site_host: "site host,LA",
      location: "LA",
      email: "abc@gmail.com",
      mo_no: "0987654321",
      no_of_chargers: 2,
      status: "active",
      date: "22-02-2024",
      created_by: "Test",
    },
    // Add more initial data as needed
  ]);
  return (
    <>
      <Grid
        gap={1}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"left"}
        width={"100%"}
      >
        <Analyticscard
          icon={<AvailableIcon color={"#23CB10"} />}
          title={"Total Visitors"}
          content={"100"}
        />
        <Analyticscard
          icon={<AvailableIcon color={"#002F69"} />}
          title={"Busy Hour"}
          content={"6:00 pm"}
        />
        <Analyticscard
          icon={<AvailableIcon color={"#D6BE05"} />}
          title={"Unique Visitors"}
          content={"20"}
        />
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1 }}
        justifyContent={"space-evenly"}
        alignItems={"flex-start"}
        marginTop={"0.5rem"}
      >
        <BasicLineChart title={"Hourly Visit"}/>
        <BasicLineChart title={"Monthly Visit"}/>
      </Grid>

      <Card xs={12} md={12} mt="1rem">
        <Typography component={"h1"} fontSize={"22px"} fontWeight={"500"} mb={"1rem"}>
          Sessions History
        </Typography>
        <Typography>
          <Table
            sx={{ minWidth: 650, textTransform: "capitalize" }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                "& td, & th": { border: "1px solid #4C4E641F !important" },
                background: "#F5F3F3 0% 0% no-repeat padding-box",
              }}
            >
              <TableRow>
                <TableCell align="center">Sr. No</TableCell>
                <TableCell align="left">End User ID</TableCell>
                <TableCell align="left">IN Time</TableCell>
                <TableCell align="left">OUT Time</TableCell>
                <TableCell align="left">Total Energy Consumed</TableCell>
                <TableCell align="left">Total Cost</TableCell>
                <TableCell align="left">Mode Of Payment</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {siteHosts.map((row) => (
                <TableRow
                  key={row.sr_no}
                  sx={{
                    "& td, & th": {
                      border: "1px solid #4C4E641F !important",
                    },
                  }}
                >
                  <TableCell align="center">{row.sr_no}</TableCell>
                  <TableCell align="left">{row.site_host}</TableCell>
                  <TableCell align="left">{row.no_of_chargers}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Typography>
      </Card>
    </>
  );
}

export default Footfall;
