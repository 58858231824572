import React from "react";
import ModalComponent from "../atoms/ModalComponent";
import { Typography } from "@mui/material";

function Disclaimer({ disclaimerModalOpen, setDisclaimerModalOpen }) {
    return (
        <ModalComponent
            open={disclaimerModalOpen}
            handleClose={() => setDisclaimerModalOpen(false)}
            showSubmitButton={false}
            headerText={"Dislaimer"}
        >
            <Typography variant="h6" gutterBottom>
                Disclaimer
            </Typography>
            <Typography variant="body1" paragraph>
                The information provided on this website (the "Site") is for general
                informational purposes only, related to EV chargers and related services.
                While we strive to ensure the accuracy and reliability of all information, we
                make no guarantees, express or implied, about the completeness, accuracy,
                or reliability of the content on this Site.
            </Typography>
            <Typography variant="body1" paragraph>
                Any reliance you place on such information is strictly at your own risk.
                We are not responsible for any losses or damages that may arise, whether
                direct or indirect, as a result of using this Site or any external links
                included on the Site.
            </Typography>
            <Typography variant="body1" paragraph>
                The availability of EV charging stations, real-time data on charger status,
                and any services offered through third-party sites linked on this platform
                are not guaranteed, and we make no representations about their availability,
                functionality, or legal compliance in any specific region.
            </Typography>
            <Typography variant="body1" paragraph>
                Please be aware that the content on this Site is subject to change without
                notice and may not always reflect the most up-to-date information on EV
                chargers, service providers, or their related policies.
            </Typography>
            <Typography variant="body1" paragraph>
                We do not assume responsibility for any technical issues, temporary outages,
                or malfunctions that may affect access to this Site or the data provided
                herein.
            </Typography>
            <Typography variant="body1" paragraph>
                If you access external websites linked from this platform, please note that
                we do not endorse, control, or assume any responsibility for the content or
                services offered by these third-party websites.
            </Typography>

        </ModalComponent>
    );
}

export default Disclaimer;
