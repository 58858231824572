import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";

import { LineChart } from "@mui/x-charts/LineChart";


const lineChartsParams = {
  series: [
    {
      id: "series-1",
      data: [2, 6, 1, 3, 5],
      label: "A",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
    {
      id: "series-2",
      data: [0, 2, 3, 5, 8],
      label: "B",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
    {
      id: "series-3",
      data: [1, 5, 2, 5, 1],
      label: "C",
      area: true,
      stack: "total",
      highlightScope: {
        highlighted: "item",
      },
    },
  ],
  xAxis: [{ data: [0, 3, 6, 9, 12], scaleType: "linear", id: "axis1" }],
  height: 400,
};

export default function LineClickNoSnap() {
  const [itemData, setItemData] = React.useState();
  const [axisData, setAxisData] = React.useState();

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: "100%" }}
      marginTop={"1rem"}
    >
      <Box sx={{ flexGrow: 1 }}>
      <Typography fontSize={"22px"} component={"h1"}>Real Time Traffic</Typography>
        <LineChart
          {...lineChartsParams}
          onAreaClick={(event, d) => setItemData(d)}
          onMarkClick={(event, d) => setItemData(d)}
          onLineClick={(event, d) => setItemData(d)}
          onAxisClick={(event, d) => setAxisData(d)}
          colors={["#E1ECF8AB","#98C0E9","#8AADD1"]}
        />
      </Box>

    </Stack>
  );
}
