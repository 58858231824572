import React, { useEffect, useState } from "react";
import { stripe } from "../../_libs/stripe";
import { getCardDetails, addCardDetails, getcardDetailsById, updateCardDetails, deleteCardDetails } from '../../redux/apis/cpo';
import { message } from "antd";
import { Box, Grid, TextField, MenuItem, Typography, Autocomplete, CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import ModalComponent from "../atoms/ModalComponent";
import { Add } from "@mui/icons-material";
import CardStyles from "../../components/atoms/card/index.module.scss";


const Cards = () => {
    const [cards, setCards] = useState([]);
    const [openCardModal, setOpenCardModal] = useState(false);
    const handleCloseCardModal = () => {
        setOpenCardModal(false);
        setCardFormData({
            cardNumber: "",
            cvv: "",
            expiryDate: "",
            firstName: "",
            lastName: "",
            address: "",
            country: "",
            state: "",
            city: "",
            zipCode: "",
            tokenId: ""
        })
    }
    const handleOpenCardModal = () => {
        setCardFormData({
            cardNumber: "",
            cvv: "",
            expiryDate: "",
            firstName: "",
            lastName: "",
            address: "",
            country: "",
            state: "",
            city: "",
            zipCode: "",
            tokenId: ""
        })
        setOpenCardModal(true);
    }

    // State to hold card form data
    const [cardFormData, setCardFormData] = useState({
        cardNumber: "",
        cvv: "",
        expiryDate: "",
        firstName: "",
        lastName: "",
        address: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        tokenId: ""
    });

    // State for GeoDB fetched data
    const [countriesMap, setCountriesMap] = useState([]);
    const [statesMap, setStatesMap] = useState([]);
    const [citiesMap, setCitiesMap] = useState([]);
    const [loadingStates, setLoadingStates] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDeleteSubmit = async () => {

        setLoading(true);
        try {
            await deleteCardDetails(selectedCardId);
            message.success("Card Removed successfully");
            getCards();
        }
        catch (error) {
            console.error("Error Removing card:", error);
            message.error(error.response?.data?.message || "Failed to Remove card");
        }
        finally {
            setLoading(false);
            setOpenDeleteDialog(false);
        }

    }


    const [errors, setErrors] = useState({});

    const handleValidation = () => {
        const errors = {};
        if (!cardFormData.cardNumber) {
            errors.cardNumber = "Card Number is required";
        }
        if (!cardFormData.firstName) {
            errors.firstName = "First Name is required";
        }
        if (!cardFormData.lastName) {
            errors.lastName = "Last Name is required";
        }
        if (!cardFormData.cvv) {
            errors.cvv = "CVV is required";
        }
        if (!cardFormData.expiryDate) {
            errors.expiryDate = "Expiry Date is required";
        }
        if (!cardFormData.address) {
            errors.address = "Address is required";
        }
        if (!cardFormData.country) {
            errors.country = "Country is required";
        }
        if (!cardFormData.state) {
            errors.state = "State is required";
        }
        if (!cardFormData.city) {
            errors.city = "City is required";
        }
        if (!cardFormData.zipCode) {
            errors.zipCode = "Zip Code is required";
        }

        setErrors(errors);

        return Object.keys(errors).length === 0;
    }


    useEffect(() => {
        fetchCountriesData();
    }, []);

    const fetchCountriesData = async () => {
        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries');
            const data = await response.json();
            setCountriesMap(data.data);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchStates = async (countryName) => {
        if (!countryName) return; // Ensure country is selected
        setLoadingStates(true);
        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/states', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryName }),
            });
            const data = await response.json();
            setStatesMap(data.data.states || []);
            setCitiesMap([]); // Reset cities when a new state is selected
        } catch (error) {
            console.error('Error fetching states:', error);
        } finally {
            setLoadingStates(false);
        }
    };

    const fetchCities = async (countryName, stateName) => {
        if (!countryName || !stateName) return; // Ensure country and state are selected
        setLoadingCities(true);
        try {
            const response = await fetch('https://countriesnow.space/api/v0.1/countries/state/cities', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: countryName, state: stateName }),
            });
            const data = await response.json();

            // Log the entire response to see the correct structure


            // Check if 'data' has 'cities' key, adjust if the structure is different
            if (data.data && data.data.length) {
                setCitiesMap(data.data); // Assuming 'data.data' contains the cities array
            } else {
                setCitiesMap([]); // Reset cities if none found
            }

        } catch (error) {
            console.error('Error fetching cities:', error);
        } finally {
            setLoadingCities(false);
        }
    };


    const handleCardInputChange = (event) => {
        setCardFormData({
            ...cardFormData,
            [event.target.name]: event.target.value,
        });
    };

    const handleExpiryDateChange = (e) => {
        let value = e.target.value;
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');

        // Auto-format for MM/YY
        if (value.length >= 3) {
            value = `${value.slice(0, 2)}/${value.slice(2, 4)}`;
        }

        setCardFormData({
            ...cardFormData,
            expiryDate: value,
        });
    };

    // Handle Card Number change with auto-formatting for 4-digit groups
    const handleCardNumberChange = (e) => {
        let value = e.target.value;
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');

        // Group into blocks of 4 digits
        value = value.replace(/(\d{4})(?=\d)/g, '$1 ');

        setCardFormData({
            ...cardFormData,
            cardNumber: value,
        });
    };

    // Handle CVC input with numeric restriction
    const handleCvcChange = (e) => {
        let value = e.target.value;
        // Remove any non-numeric characters
        value = value.replace(/\D/g, '');

        // Limit to 3 or 4 digits (commonly used for card CVCs)
        if (value.length <= 4) {
            setCardFormData({
                ...cardFormData,
                cvv: value,
            });
        }
    };


    // Function to handle form submission for adding card details

    const handleSubmitCard = async () => {
        if (!handleValidation()) {
            return;
        }
        // Extract expiration month and year
        const expMonth = cardFormData.expiryDate.split("/")[0];
        const expYear = cardFormData.expiryDate.split("/")[1];
        let tokenResponse = {};

        setLoading(true);

        try {
            if (!stripe) {
                message.error("Stripe is not ready!");
                return;
            }

            try {
                // Manually provide card details for token creation
                tokenResponse = await stripe.tokens.create({
                    card: {
                        number: cardFormData.cardNumber.replace(/\s/g, ""), // Remove spaces
                        exp_month: expMonth,
                        exp_year: expYear,
                        cvc: cardFormData.cvv,
                        name: `${cardFormData.firstName} ${cardFormData.lastName}`, // Optional: cardholder name
                        address_line1: cardFormData.address,
                        address_city: cardFormData.city,
                        address_state: cardFormData.state,
                        address_zip: cardFormData.zipCode,
                        address_country: cardFormData.country,
                    }
                });
            } catch (error) {
                // Destructure the error response
                // Safely extract the error information
                const errorResponse = error?.raw || {};
                
                const { param, message } = errorResponse;

                if (param && message) {
                    // Map Stripe param to your form field
                    const fieldMapping = {
                        number: "cardNumber",
                        exp_month: "expiryDate",
                        exp_year: "expiryDate",
                        cvc: "cvv",
                        address_line1: "address",
                        address_city: "city",
                        address_state: "state",
                        address_zip: "zipCode",
                        address_country: "country",
                    };

                    // Create a new error object for the form
                    const field = fieldMapping[param];
                    if (field) {
                        // If the param matches one of our form fields, set the error
                        setErrors(prevErrors => ({
                            ...prevErrors,
                            [field]: message, // Add the new error for the specific field
                        }));
                    }
                } else {
                    // Fallback for non-Stripe specific errors
                    message.error("Something went wrong during token creation.");
                }

                return; // Exit after handling the error
            }
            // Prepare the final payload for the backend
            const finalPayload = {
                tokenId: tokenResponse.id,  // Use the token ID returned by Stripe
                cardNumber: cardFormData.cardNumber.slice(-4), // Only store the last 4 digits of the card number
                expiryDate: cardFormData.expiryDate,
                firstName: cardFormData.firstName,
                lastName: cardFormData.lastName,
                address: cardFormData.address,
                country: cardFormData.country,
                state: cardFormData.state,
                city: cardFormData.city,
                zipCode: cardFormData.zipCode,
            };

            // Call your API to add card details
            const response = await addCardDetails(finalPayload); // Pass the final payload to your backend function

            // Display success message
            message.success("Card details added successfully");

            // Refresh card list or perform any additional actions
            getCards();

            // Close the card modal after successful submission
            handleCloseCardModal();

            // Reset form data after submission
            setCardFormData({
                cardNumber: "",
                cvv: "",
                expiryDate: "",
                firstName: "",
                lastName: "",
                address: "",
                country: "",
                state: "",
                city: "",
                zipCode: "",
                tokenId: ""
            });

        } catch (error) {
            // Log the error to inspect its structure
            // Provide a more detailed error message
            if (error && error.message) {
                message.error(`Error: ${error?.response?.data?.message || error?.response?.error?.message}`);
            } else {
                message.error("Failed to add card details. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRemoveCard = async (cardId) => {
        // Handle card removal logic
        setSelectedCardId(cardId);
        setOpenDeleteDialog(true);
    }

    const getCards = async () => {
        try {
            const response = await getCardDetails();
            setCards(response.list);
        }
        catch (error) {
            console.error("Error fetching cards:", error);
        }
    }

    useEffect(() => {
        getCards();
    }, []);


    return (
        <>
            <Grid item className={CardStyles.card} md={6} sx={{ overflowY: "auto" }}>
                <Grid container xs={12} display={"flex"} justifyContent={"space-between"}>
                    <Typography
                        component={"h1"}
                        fontWeight={"500"}
                        fontSize={"1.25rem"}
                        marginBottom={"0.5rem"}
                    >
                        Payment Method
                    </Typography>
                    <Button onClick={handleOpenCardModal}>
                        <Add /> Add Card
                    </Button>
                </Grid>

                {loading ? (
                    <Grid xs={12} sx={{ display: "flex", justifyContent: "center", height: "300px", overflowY: "auto" }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    cards.map((card) => (
                        <Box
                            key={card.id}
                            sx={{
                                padding: 2,
                                maxWidth: 400,
                                textAlign: 'center',
                                border: '1px solid #ccc',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                backgroundColor: '#f5f5f5',
                                marginBottom: '1rem', // Adds spacing between cards
                            }}
                        >
                            {/* Card Details Compact Display */}
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="subtitle1" sx={{ color: 'gray' }}>
                                    Card Number: **** **** **** {card.cardNumber.slice(-4)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: 'gray' }}>
                                    Expiry Date: {card.expiryDate} | CVC: ***
                                </Typography>
                                <Typography variant="subtitle1" sx={{ color: 'gray' }}>
                                    {card.firstName} {card.lastName}
                                </Typography>
                            </Box>

                            {/* Action Buttons */}
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ fontSize: '0.8rem' }}
                                    onClick={() => handleRemoveCard(card.id)}
                                >
                                    Remove
                                </Button>
                            </Box>
                        </Box>
                    ))
                )
                }
            </Grid>

            <ModalComponent
                open={openCardModal}
                handleClose={handleCloseCardModal}
                showSubmitButton={true}
                headerText="Add Card Details"
                submitButtonLabel="Save"
                handleSubmit={handleSubmitCard}
            >
                <Box sx={{ padding: 2 }}>
                    {/* Card Details Section */}
                    <Typography variant="h6" component="h2" gutterBottom>
                        Card Details
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Card Number"
                                variant="outlined"
                                fullWidth
                                required
                                error={errors.cardNumber}
                                helperText={errors.cardNumber}
                                name="cardNumber"
                                value={cardFormData.cardNumber}
                                onChange={handleCardNumberChange}
                                placeholder="1234 5678 9012 3456"
                                InputProps={{
                                    style: { borderRadius: 8 }
                                }}
                                inputProps={{
                                    maxLength: 19,  // Maximum length for card number with spaces
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="CVC"
                                variant="outlined"
                                fullWidth
                                required
                                name="cvv"
                                error={errors.cvv}
                                helperText={errors.cvv}
                                value={cardFormData.cvv}
                                onChange={handleCvcChange}
                                placeholder="CVC"
                                InputProps={{
                                    style: { borderRadius: 8 }
                                }}
                                inputProps={{
                                    maxLength: 4,  // CVC usually has a maximum of 4 digits
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Exp. (MM/YY)"
                                variant="outlined"
                                fullWidth
                                required
                                error={errors.expiryDate}
                                helperText={errors.expiryDate}
                                name="expiryDate"
                                value={cardFormData.expiryDate}
                                onChange={handleExpiryDateChange}
                                placeholder="MM/YY"
                                InputProps={{
                                    style: { borderRadius: 8 }
                                }}
                                inputProps={{
                                    maxLength: 5, // MM/YY format length
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                error={errors.firstName}
                                helperText={errors.firstName}
                                variant="outlined"
                                fullWidth
                                required
                                name="firstName"
                                value={cardFormData.firstName}
                                onChange={handleCardInputChange}
                                InputProps={{ style: { borderRadius: 8 } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                variant="outlined"
                                error={errors.lastName}
                                helperText={errors.lastName}
                                fullWidth
                                required
                                name="lastName"
                                value={cardFormData.lastName}
                                onChange={handleCardInputChange}
                                InputProps={{ style: { borderRadius: 8 } }}
                            />
                        </Grid>
                    </Grid>

                    {/* Address Section */}
                    <Box mt={3}>
                        <Typography variant="h6" component="h2" gutterBottom>
                            Address
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Address"
                                    error={errors.address}
                                    helperText={errors.address}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    name="address"
                                    value={cardFormData.address}
                                    onChange={handleCardInputChange}
                                    InputProps={{ style: { borderRadius: 8 } }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Autocomplete
                                    options={countriesMap}
                                    getOptionLabel={(option) => option.country}
                                    isOptionEqualToValue={(option, value) => option.country === value.country} // Custom equality check
                                    onChange={(e, value) => {
                                        // Update the cardFormData explicitly with the selected country
                                        setCardFormData((prev) => ({
                                            ...prev,
                                            country: value ? value.country : "", // If no value, set empty string
                                        }));

                                        if (value) fetchStates(value.country); // Fetch states only if a country is selected
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Country"
                                            name="country"
                                            error={errors.country}
                                            helperText={errors.country}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            InputProps={{ ...params.InputProps, style: { borderRadius: 8 } }}
                                        />
                                    )}
                                />
                            </Grid>



                            <Grid item xs={12} sm={6} >
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    error={errors.state}
                                    helperText={errors.state}
                                    name="state"
                                    value={cardFormData.state}
                                    onChange={(e) => {
                                        handleCardInputChange(e);
                                        fetchCities(cardFormData.country, e.target.value); // Fetch cities when state is selected
                                    }}
                                    select
                                    InputProps={{ style: { borderRadius: 8 } }}
                                >
                                    {loadingStates ? (
                                        <MenuItem disabled>
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        statesMap.map((state) => (
                                            <MenuItem key={state.name} value={state.name}>
                                                {state.name}
                                            </MenuItem>
                                        ))
                                    )}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} sm={6} >
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    name="city"
                                    value={cardFormData.city}
                                    onChange={handleCardInputChange}
                                    select
                                    InputProps={{ style: { borderRadius: 8 } }}
                                >
                                    {loadingCities ? (
                                        <MenuItem disabled>
                                            <CircularProgress size={24} />
                                        </MenuItem>
                                    ) : (
                                        citiesMap.length > 0 ? (
                                            citiesMap.map((city, index) => (
                                                <MenuItem key={index} value={city}>
                                                    {city}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            <MenuItem disabled>No cities available</MenuItem>
                                        )
                                    )}
                                </TextField>
                            </Grid>


                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Zip Code"
                                    error={errors.zipCode}
                                    helperText={errors.zipCode}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    name="zipCode"
                                    value={cardFormData.zipCode}
                                    onChange={handleCardInputChange}
                                    InputProps={{ style: { borderRadius: 8 } }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ModalComponent>

            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirm Removal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this card?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteSubmit} color="secondary">
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Cards;