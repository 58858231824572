import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";

function AnalyticsTab({ setActiveTab }) {
  const [value, setValue] = React.useState("revenue");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Typography>
      <Box
        sx={{ background: "white", width: "100%" }}
        borderRadius={"14px"}
        marginLeft={"1rem"}
        my={"1rem"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="white"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="revenue"
            label="Revenue"
            onClick={() => setActiveTab("revenue")}
            sx={{ textTransform: "capitalize" }}
          />
          <Tab
            value="footfall"
            label="Footfall"
            onClick={() => setActiveTab("footfall")}
            sx={{ textTransform: "capitalize" }}
          />
        </Tabs>
      </Box>
    </Typography>
  );
}

export default AnalyticsTab;
