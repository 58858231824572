import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

export const getSubscriptionPlan = async (subscriptionId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/subscription-plan`);
    return response.data;
  }
  catch(error){
    throw error;
  }
};

export const getSubscriptionPlanById = async (subscriptionId) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/subscription-plan/${subscriptionId}`);
    return response.data;
  }
  catch(error){
    throw error;
  }
};

export const addSubsciptionPlan = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/subscription-plan/add-subscription-plan`, data);
    return response.data;
  }
  catch(error){
    throw error;
  }
};

export const initiateSubscriptionPurchaseRequest = async (data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.post(`/cpo/subscription-purchase-request/initiate-subscription-purchase`, data);
    return response.data;
  }
  catch(error){
    throw error;
  }
}

export const updateSubscriptionPurchaseRequest = async (subscriptionPurchaseRequestId , data) => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.put(`/cpo/subscription-purchase-request/update-subscription-purchase/${subscriptionPurchaseRequestId}`, data);
    return response.data;
  }
  catch(error){
    throw error;
  }
}

export const getSubscriptionPlanDetails = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/cpo/subscription-purchase-request`);
    return response.data;
  }
  catch(error){
    throw error;
  }
};

export const getInvoices = async () => {
  ensureAuthToken();
  try{
    const response = await axiosInstance.get(`/cpo/subscription-purchase-request/list-subscription-invoices`);
    return response.data;
  }
  catch(error){
    throw error;
  }
};


export default axiosInstance;
