import React from "react";

function ChargersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 21.019 21.019"
    >
      <path
        id="ev_charger_FILL1_wght400_GRAD0_opsz24"
        d="M162.335-831.826h7.006v-5.839h-7.006ZM160-818.981v-18.683a2.249,2.249,0,0,1,.686-1.649,2.249,2.249,0,0,1,1.649-.686h7.006a2.249,2.249,0,0,1,1.649.686,2.249,2.249,0,0,1,.686,1.649v8.174h1.46a1.969,1.969,0,0,1,1.445.6,1.969,1.969,0,0,1,.6,1.445v5.4a1.256,1.256,0,0,0,.409.9,1.256,1.256,0,0,0,.9.409,1.239,1.239,0,0,0,.92-.409,1.277,1.277,0,0,0,.394-.9v-10.947h-.292a1.13,1.13,0,0,1-.832-.336,1.13,1.13,0,0,1-.336-.832V-836.5h.584v-1.752H178.1v1.752h1.168v-1.752h1.168v1.752h.584v2.336a1.13,1.13,0,0,1-.336.832,1.13,1.13,0,0,1-.832.336h-.292v10.947a2.988,2.988,0,0,1-.89,2.146,2.912,2.912,0,0,1-2.175.919,2.926,2.926,0,0,1-2.16-.919,2.964,2.964,0,0,1-.9-2.146v-5.4a.3.3,0,0,0-.073-.219.3.3,0,0,0-.219-.073h-1.46v8.758Zm5.255-2.335,2.919-4.671h-1.752v-3.5L163.5-824.82h1.752Z"
        transform="translate(-160 840)"
        fill="#fff"
      />
    </svg>
  );
}

export default ChargersIcon;
