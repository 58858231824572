import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_API_URL}/api`;

const axiosInstance = axios.create({
  baseURL,
});

// Function to set bearer token
export const setAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axiosInstance.defaults.headers.common['Authorization'];
    }
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Ensure token is set before each API call
const ensureAuthToken = () => {
  const currentUserId = localStorage.getItem("currentUser") || sessionStorage.getItem("currentUser");
  if (currentUserId) {
    const tokenKey = `token_${currentUserId}`;
    const token = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
    setAuthToken(token);
  }
};

export const fetchSitesAPI = async (queryParams) => {
  ensureAuthToken();
    try {
      const response = await axiosInstance.get('/site', { params: queryParams });
      return response.data;
    } catch (error) {
      console.error('Error fetching sites:', error);
      throw error;
    }
  };
  
  export const addSiteAPI = async (data) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.post('/site/add-site', data);
      return response.data;
    } catch (error) {
      console.error('Error adding site:', error);
      throw error;
    }
  };
  
  export const editSiteAPI = async (selectedSiteId, data) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.patch(`/site/${selectedSiteId}`, data);
      return response.data;
    } catch (error) {
      console.error('Error editing site:', error);
      throw error;
    }
  };
  
  export const deleteSiteAPI = async (selectedSiteId) => {
    ensureAuthToken();
    try {
      const response = await axiosInstance.delete(`/site/${selectedSiteId}`);
      return response.data;
    } catch (error) {
      console.error('Error deleting site:', error);
      throw error;
    }
  };