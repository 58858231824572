import { Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";


function MonitorTab({ setActiveTab }) {
  const [value, setValue] = React.useState("system_status");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Typography display={"flex"} just>
      <Box
        sx={{ background: "white", width: "100%" }}
        borderRadius={"14px"}
        marginLeft={"1rem"}
        my={"1rem"}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="white"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            value="system_status"
            label="System Status"
            onClick={() => setActiveTab("system_status")}
            sx={{textTransform:"capitalize"}}
          />
          <Tab
            value="kernel_logs"
            label="Kernel Logs"
            onClick={() => setActiveTab("kernel_logs")}
            sx={{textTransform:"capitalize"}}
          />
          <Tab
            value="processes"
            label="Processes"
            onClick={() => setActiveTab("processes")}
            sx={{textTransform:"capitalize"}}
          />
          {/* <Tab
            value="real_time_graph"
            label="Real Time Graph"
            onClick={() => setActiveTab("real_time_graph")}
            sx={{textTransform:"capitalize"}}
          /> */}
        </Tabs>
      </Box>
    </Typography>
  );
}

export default MonitorTab;
