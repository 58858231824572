import React from 'react';
import HomeIcon from '../icons/homeIcon';
import ChargersIcon from '../icons/chargersIcon';
import AnalyticsIcon from '../icons/analyticsIcon';
import SettingsIcon from '../icons/settingsIcon';


const EVSEOwnerSidebarItems = [
  {
    name: "Home",
    icon: <HomeIcon />,
    route: "/home",
  },
  {
    name: "Chargers",
    icon: <ChargersIcon />,
    route: "/chargers",
  },

  {
    name: "Settings",
    icon: <SettingsIcon />,
    route: "/settings",
  },
];

export default EVSEOwnerSidebarItems;
